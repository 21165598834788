import { createSlice } from "@reduxjs/toolkit"

import { assignUserRole, removeUserRole } from "../api/api"


const initialState={
   loading:false,
   error:{}, 
   response: null

}

const removeUserRoleSlice= createSlice({
    name:'RemoveUser',
     initialState,

     reducers:{

     },

     extraReducers: (builder)=>{
        builder.addCase( removeUserRole.pending, (state)=>{
            state.loading = true
            state.error={}
            state.response =null

        })

        builder.addCase( removeUserRole.rejected, (state, action)=>{
            state.loading=false
            state.error= action.payload
            state.response=null
        }

        )
        builder.addCase( removeUserRole.fulfilled, (state, action)=>{
            state.loading=false
            state.error={}
            state.response= action.payload
        }
            )


     }

})

export default removeUserRoleSlice.reducer