
import { createSlice } from "@reduxjs/toolkit";

import { fetchLoanPaymentsToApprove } from "../api/api";


const initialState={
    loading:false,
    paymentsToApprove:null,
    pendingLoansPayment:null,
  
   error:{}
}

const getPaymentsToApproveSlice=createSlice({
    name:"getLoanPaymentsToApprove",
    initialState, 
    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(fetchLoanPaymentsToApprove.pending, (state)=>{
            state.loading=true
            state.error={}
            state.paymentsToApprove=null
            state.pendingLoansPayment=null

        })
        builder.addCase( fetchLoanPaymentsToApprove.rejected, (state, action)=>{
            state.loading=false
            state.error=action.payload
            state.paymentsToApprove=null
            state.pendingLoansPayment=null

        }

        )
        builder.addCase(
            fetchLoanPaymentsToApprove.fulfilled,(state, action)=>{
                state.loading=false
                state.error={}
                state.paymentsToApprove=action.payload?.pending_approvals
                state.pendingLoansPayment=action.payload?.pending_loans
            }
        )
    }

   
}
    
)
export default getPaymentsToApproveSlice.reducer