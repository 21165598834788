import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createLedgers, getDeclarations } from "../../Features/api/api";
import Spinner from "../../SharedFns/SpinnerElement";
import DatePicker from "react-datepicker";

const ChargeFineForm = ({userId, setISChargeFineModalOpen}) => {
    const user_id=userId
    const dispatch = useDispatch();
    const [ledgerdata, setData] = useState({});
    
    const declarations = useSelector(state => state.getDeclarations?.declarations?.declarations);
    const fixedDeposits = useSelector(state => state.getDeclarations?.declarations?.fixed_deposits);
    const isLoading=useSelector(state=>state.createLedger?.loading )

    const handleOnchange = (e) => {
        const { name, value } = e.target;
        setData({ ...ledgerdata, [name]: value, charged_user: user_id, type:'fine'  });
    };

    const handleDateChange = (date, name) => {
        setData({ ...ledgerdata, [name]: date });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(ledgerdata);
            await dispatch(createLedgers({ledgerdata 
               }));
           setISChargeFineModalOpen(false)
        } catch (error) {
            console.error("Error creating ledgers:", error);
        }
    };

    return (
        <Container>
            {isLoading && <Spinner/>}
            <h3>Charge the user below</h3>
            <PaymentForm onSubmit={handleSubmit}>
                <FormRow>
                    <label>Amount:</label>
                    <input
                        type="number"
                        name="amount"
                        value={ledgerdata.amount || ''}
                        onChange={handleOnchange}
                        required
                    />
                </FormRow>
                <FormRow>
                    <label>Description:</label>
                    <input
                        type="text"
                        name="description"
                        value={ledgerdata.description || ''}
                        placeholder="Provide details about the fine"
                        onChange={handleOnchange}
                        required
                    />
                </FormRow>
                <AuthButtons>
                    <SignUpBtn type="submit">Submit</SignUpBtn>
                </AuthButtons>
            </PaymentForm>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    width: 80%;
`;

const PaymentForm = styled.form`
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`;

const FormRow = styled.div`
    margin-bottom: 10px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`;

const AuthButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
`;

const SignUpBtn = styled.button`
    margin-left: 80px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #e2725b;
    color: #f9f9f9;
    border: none;
    width: 50%;
    border-color: #e2725b;
    @media (max-width: 768px) {
        font-size: 10px;
        padding: 5px 15px;
    }
`;

export default ChargeFineForm;
