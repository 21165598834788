import styled from "styled-components";
import { useDispatch } from "react-redux";
import { clearMember } from "../Features/api/api"; // Assuming you have an API function for this

const UserClearanceForm = ({ 
  setIsClearanceModalOpen, 
  selectedMember, 
  totalUserContributions, 
  balanceSheetData, 
  userCount 
}) => {
  const dispatch = useDispatch();

  const retainedEarnings = (-1 * balanceSheetData.equities?.retained_earnings.balance).toFixed(2);
  const overallSharedInterest = (retainedEarnings / userCount).toFixed(2);
  const actualSharedInterest = (overallSharedInterest * 0.63);


  const handleSubmitClearance = () => {
    const clearanceData = {
      member_id: selectedMember.id,
      total_contributions: totalUserContributions,
      overall_shared_interest: overallSharedInterest,
      actual_shared_interest: (actualSharedInterest).toFixed(2),
    };
    console.log('Processing clearance for:', clearanceData);
    dispatch(clearMember(clearanceData));
    setIsClearanceModalOpen(false);
  };

  
  return (
    <ClearanceFormL>
      <h3>Confirm Clearance</h3>
      <p>Please confirm the clearance of this member:</p>
      <p><strong>Name:</strong> {`${selectedMember.first_name} ${selectedMember.last_name}`}</p>
      <p><strong>Member ID:</strong> {selectedMember.id}</p>
      <p><strong>Total Contributions:</strong> {totalUserContributions}</p>
      <p><strong>Overall Shared Interest:</strong> {overallSharedInterest}</p>
      <p><strong>Actual Shared Interest:</strong> {actualSharedInterest}</p>
      <button onClick={handleSubmitClearance}>Confirm Clearance</button>
    </ClearanceFormL>
  );
};

const ClearanceFormL = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin: 5px 0;
  }
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export default UserClearanceForm;
