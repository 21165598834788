import { createSlice } from "@reduxjs/toolkit";
import { login } from "../api/api";

const initialState={
    loading:false,
    user:{},
    error:{},
    
} 

const signInSlice=createSlice({
   name:'signIn',
    initialState, 


    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase( login.pending, (state)=>{
            state.loading=true
            state.error={}
            state.user={}
           
        })
        builder.addCase( login.rejected, (state, action)=>{
            state.loading=false
            state.user={}
           
            state.error=action.error?.message
        })
        builder.addCase( login.fulfilled, (state, action)=>{
            state.loading=false
            state.user=action.payload
            state.error={}
            
        })

    }


    }


)
export default signInSlice.reducer