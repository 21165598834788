import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { numberWithCommas } from "../../SharedFns/Functions";

const PrintablePandLDocument = ({ title, date, currentYearData, previousYearData, accountCodeToName }) => 

{
  const contentRef = useRef(null);
  if (!currentYearData || !previousYearData) {
    return <div>Loading...</div>;
  }
  
  const currentStartYear = new Date(currentYearData.financial_year?.start_date).getFullYear();
  const currentEndYear = new Date(currentYearData.financial_year?.end_date).getFullYear();

  
  const previousStartYear = new Date(previousYearData.previous_financial_year?.start_date).getFullYear();
  const previousEndYear = new Date(previousYearData.previous_financial_year?.end_date).getFullYear();

const currentFinancialYearRepresentation = `${currentStartYear}/${currentEndYear}`;

const previousFinancialYearRepresentation = `${previousStartYear}/${previousEndYear}`;


 

  return (
    <Wrapper>
      <div ref={contentRef}> 

     
      <Header>
        <Logo src={process.env.PUBLIC_URL + '/media/logo.png'}alt="Logo" />
        <Title style={{borderBottom:'solid 1px' }}>{title}: {currentFinancialYearRepresentation} </Title>
      </Header>

      <Columns>
        <Column>
          <SubTitle>Current Financial Year: {currentFinancialYearRepresentation}</SubTitle>
          {/* Render currentYearData for the current financial year */}
          <Section>
         <TitleHolders>
         <Title>Revenues</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {(currentYearData.current_year_revenues) &&
          Object.values(currentYearData.current_year_revenues).map((revenue) => (
            <Row key={revenue.account}>
              <Label>{accountCodeToName[revenue.account]}</Label>
              <Value>{numberWithCommas(revenue.balance?.toFixed(2))}</Value>
            </Row>
          ))}
           <RowWithBorder>
           
              <Title style={{border:'none'}}>
                Total Revenues
                </Title>
          
            <Value> {numberWithCommas(currentYearData.current_year_total_revenue?.toFixed(2))}</Value>
            
          </RowWithBorder>
      </Section>

      <Section>
      <TitleHolders>
         <Title>Expenses</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {currentYearData.current_year_expenses &&
          Object.values(currentYearData.current_year_expenses).map((expense) => (
            <Row key={expense.account}>
              <Label>{accountCodeToName[expense.account]}</Label>
              <Value>{numberWithCommas((expense.balance)?.toFixed(2))}</Value>
            </Row>
          ))}
          <RowWithBorder>
           
           <Title style={{border:'none'}}>
             Total Expenses
             </Title>
       
         <Value> {numberWithCommas(currentYearData.current_year_total_expenses?.toFixed(2))}</Value>
         
       </RowWithBorder>
         
          <RowWithBorder>
            <Title style={{border:'none'}}>
            Net Profit/Loss
            </Title>
            <Value> {numberWithCommas((currentYearData.current_year_net_profit_or_loss)?.toFixed(2))}</Value>
          </RowWithBorder>
      </Section> 
           {/* <RendercurrentYearData currentYearData={currentYearcurrentYearData} accountCodeToName={accountCodeToName} yearType="current" /> */}
        </Column>

        <Column>
          <SubTitle>Previous Financial Year:{previousFinancialYearRepresentation}</SubTitle>
          {/* Render currentYearData for the previous financial year */}
          <Section>
         <TitleHolders>
         <Title>Revenues</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {(previousYearData.previous_year_revenues) &&
          Object.values(previousYearData.previous_year_revenues).map((revenue) => (
            <Row key={revenue.account}>
              <Label>{accountCodeToName[revenue.account]}</Label>
              <Value>{numberWithCommas(revenue.balance?.toFixed(2))}</Value>
            </Row>
          ))}
           <RowWithBorder>
           
              <Title style={{border:'none'}}>
                Total Revenues
                </Title>
          
            <Value> {numberWithCommas(previousYearData.previous_year_total_revenue?.toFixed(2))}</Value>
            
          </RowWithBorder>
      </Section>

      <Section>
      <TitleHolders>
         <Title>Expenses</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {previousYearData.previous_year_expenses &&
          Object.values(previousYearData.previous_year_expenses).map((expense) => (
            <Row key={expense.account}>
              <Label>{accountCodeToName[expense.account]}</Label>
              <Value>{numberWithCommas((expense.balance)?.toFixed(2))}</Value>
            </Row>
          ))}
          <RowWithBorder>
           
           <Title style={{border:'none'}}>
             Total Expenses
             </Title>
       
         <Value> {numberWithCommas(previousYearData.previous_year_total_expenses?.toFixed(2))}</Value>
         
       </RowWithBorder>
         
          <RowWithBorder>
            <Title style={{border:'none'}}>
            Net Profit/Loss
            </Title>
            <Value> {numberWithCommas((previousYearData.previous_year_net_profit_or_loss)?.toFixed(2))}</Value>
          </RowWithBorder>
      </Section> 
          {/* <RendercurrentYearData currentYearData={previousYearcurrentYearData} accountCodeToName={accountCodeToName} yearType="previous" /> */}
        </Column>
      </Columns>
      </div>
      <ReactToPrint
      trigger={() => <DownloadButton>Download PDF</DownloadButton>}
      content={() => contentRef.current}
    />
    </Wrapper>
  );
};

// const RendercurrentYearData = ({ currentYearData, accountCodeToName, yearType }) => {
//   const yearcurrentYearData = currentYearData[`${yearType}_year_revenues`, `${yearType}_year_revenues`] || currentYearData[`${yearType}_year_expenses`];

//   console.log(`Year currentYearData (${yearType}):`, yearcurrentYearData);

//  return(
//   <div>
//     {/* Render currentYearData for assets, liabilities, and equities */}
//     {/* Modify this based on your specific currentYearData structure */}
//     <Section>
//       <TitleHolders>
//         <Title> Revenues</Title>
//         <Title>Amount (KES)</Title>
//       </TitleHolders>

//       {yearcurrentYearData &&
//         Object.values(yearcurrentYearData).map((item) => (
//           <Row key={item.account}>
//             <Label>{accountCodeToName[item.account]}</Label>
//             <Value>{item.balance?.toFixed(2)}</Value>
//           </Row>
//         ))}

//       <RowWithBorder>
//         <Title style={{ border: 'none' }}>Total {yearType === 'current' ? 'Revenues' : 'Expenses'}</Title>
//         <Value>{currentYearData[`${yearType}_year_total_${yearType === 'current' ? 'revenue' : 'expenses'}`]?.toFixed(2)}</Value>
//       </RowWithBorder>

//       {yearType === 'current' && (
//         <RowWithBorder>
//           <Title style={{ border: 'none' }}>Net Profit/Loss</Title>
//           <Value>{currentYearData.current_year_net_profit_or_loss?.toFixed(2)}</Value>
//         </RowWithBorder>
//       )}
//     </Section>
    {/* <Section>
         <TitleHolders>
         <Title>Revenues</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {(currentYearData.current_year_revenues) &&
          Object.values(currentYearData.current_year_revenues).map((revenue) => (
            <Row key={revenue.account}>
              <Label>{accountCodeToName[revenue.account]}</Label>
              <Value>{revenue.balance?.toFixed(2)}</Value>
            </Row>
          ))}
           <RowWithBorder>
           
              <Title style={{border:'none'}}>
                Total Revenues
                </Title>
          
            <Value> {currentYearData.current_year_total_revenue?.toFixed(2)}</Value>
            
          </RowWithBorder>
      </Section>

      <Section>
      <TitleHolders>
         <Title>Expenses</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {currentYearData.current_year_expenses &&
          Object.values(currentYearData.current_year_expenses).map((expense) => (
            <Row key={expense.account}>
              <Label>{accountCodeToName[expense.account]}</Label>
              <Value>{(expense.balance)?.toFixed(2)}</Value>
            </Row>
          ))}
          <RowWithBorder>
           
           <Title style={{border:'none'}}>
             Total Expenses
             </Title>
       
         <Value> {currentYearData.current_year_total_expenses?.toFixed(2)}</Value>
         
       </RowWithBorder>
         
          <RowWithBorder>
            <Title style={{border:'none'}}>
            Net Profit/Loss
            </Title>
            <Value> {(currentYearData.current_year_net_profit_or_loss)?.toFixed(2)}</Value>
          </RowWithBorder>
      </Section> */}

     
//   </div>)
// };

const Wrapper = styled.div`
  /* Your global styles here */
  height: 50%;
  width: 80%;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const TitleHolders = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px;
  padding-bottom: 0px;
  margin-bottom: 3px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Logo = styled.img`
  width: 130px;
  border-radius: 50%;
  margin-left:50px;
  background-color:white;
  padding:5px 10px;
  
  object-fit: cover;
  margin-bottom:15px;
  @media (max-width:768px) {
    width:100px;
    margin-bottom:5px;
    margin-top:0px;
    padding-top:0;
    margin-left:30px
  }
`;

// const TitleHolders=styled.div`
// display: flex;
// justify-content: space-between;
// border-bottom:solid 1px;
// padding-bottom:0px;
// margin-bottom: 3px;
  

// `;

// const TitleHolders=styled.div`
// display: flex;
// justify-content: space-between;
// border-bottom:solid 1px;
// padding-bottom:0px;
// margin-bottom: 3px;
  

// `
// const Title = styled.div`
//   font-size: 18px;
//   font-weight: bold;
//   margin-bottom: 5px;
 
// `;

const Section = styled.div`
  margin: 10px;
  padding: 10px;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Label = styled.div`
  /* Your label styles here */
`;

const Value = styled.div`
  /* Your value styles here */
`;

const Author = styled.div`
  text-align: start;
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
  font-size: 14px;
`;

const Text = styled.div`
  /* Your text styles here */
 
  margin-bottom:2px;
  margin-left: 15px;
`;
const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
const RowWithBorder = styled(Title)`
  border-top: 1px solid black; 
  border-bottom: 1px solid black; 
  display:flex;
  justify-content: space-between;
  padding: 5px; 
  width:100%;
`;

const ValueWithBorder = styled(Value)`
  border-top: 1px solid black; // Adjust the color as needed
  border-bottom: 1px solid black; // Adjust the color as needed
  padding: 5px  // Add padding to improve spacing
 `;

const Column = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left:6px;
`;
const Columns = styled.div`
display: flex;
justify-content: space-between;
`


export default PrintablePandLDocument;
