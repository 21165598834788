import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { activateAccount, fetchBalanceSheet, fetchProfitAndLossReport, fetchLedgerBalances } from '../../Features/api/api';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';

const DateInputForm = ({setIsDateFormOpen, setDate}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  
   const dispatch=useDispatch()
  // useEffect(() => {
  //   const fetchData = async () => {
  //   //   if (!isDateFormOpen) {
  //       // Fetch balance sheet data when the component mounts
  //      dispatch(fetchBalanceSheet(selectedDate));
  //       // await dispatch(fetchProfitAndLossReport());
  //   //   }
  //   };

  //   fetchData();
  // }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission with the selectedDate
    console.log('Selected Date:', selectedDate);

  // const formattedDate = selectedDate.toISOString().split('T')[0];

     const formattedDate= `${selectedDate?.getFullYear()}-${selectedDate?.getMonth() + 1}-${selectedDate?.getDate()}`
      dispatch(fetchBalanceSheet(formattedDate));
      dispatch(fetchProfitAndLossReport({'start_date':formattedDate, 'end_date':formattedDate}));
      dispatch(fetchLedgerBalances(formattedDate))
      // Perform any additional logic or API calls here
      setIsDateFormOpen(false)
    
    }
    
  ;

  return (
    <Container>
      <PaymentForm onSubmit={handleSubmit}>
          <h3> Select End Date</h3> 
        <TransactionFilter>
        <div>

        <label>
        Select Date:
        </label>

        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
        
          showYearDropdown
          scrollableYearDropdown
        />  
            </div>    
        
        
      
      
      <Button type="submit">Submit</Button>
      </TransactionFilter>
    </PaymentForm>
    </Container>
  
    
  );
};
const Container =styled.div`
height:70px;

`
const PaymentForm = styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width: 30%;
height: 50%;

`
const TransactionFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:flex-start;
  
 


   .react-datepicker__time-list-item {
    width: 100%;
    margin-left: -40px;
    padding-left: 18px !important;
    @media (max-width: 768px) {
        width: 100%; 
        margin-right: 0;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 12px;
        margin-left: -40px;
        padding-left: 18px !important;
      }
} 
 
   .custom-datepicker {
    
    @media (max-width: 768px) {
      width: 100%; 
      margin-right: 0;
      margin-bottom: 10px;
      padding: 5px;
      font-size: 12px;
      margin-left: -40px;
      padding-left: 18px !important;
    }
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
` 
const Button = styled.button`
cursor:pointer;
align-items:center;
margin-left:30px;
margin-top:10px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    padding:5px 20px;
  }
`


export default DateInputForm;
