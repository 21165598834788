import { useDebugValue, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { payLoan } from "../Features/api/api"
import { useDispatch } from "react-redux"

const LoanPaymentForm=()=>{
    const{LoanId}=useParams()
   
    const dispatch=useDispatch()
    const[paymentData, setPaymentData] =useState({
      
       amount_paid:'', 
       payment_Reference_Code:'',
       
    })
    const handleOnchange=(e)=>{
        const{name, value}=e.target
        setPaymentData(
            {
                ...paymentData, [name]: value
            }
        )
    }
    
    const handleSubmit= async(e)=>{
                e.preventDefault()
                dispatch(payLoan({paymentData, loan_id:LoanId }))
    }
    return(
        <Container>
            <h2>Make Your Payment Below</h2>
            <p> Please note that all payments are subject to approval by the Sec-General and the Treasurer.<br/>
                 Any payment not approved by both of them is incomplete</p>
              <PaymentForm onSubmit={handleSubmit}>
              
            <FormOuterRow>

            {/* <Form_Row>
             <label htmlFor="installment_number">
                Installment Number:
                </label >
                <input type="number" onChange={handleOnchange} id='installment_number' name="installment_number" placeholder="Input a positive integer: e.g 1.0" required />
               
             </Form_Row>
              */}
             <Form_Row>
             <label htmlFor="amount_paid">
                Amount:
                </label>
                <input type="number" step ='any'onChange={handleOnchange} id='amount_paid' name="amount_paid" placeholder="Input the amount you want to pay: e.g 2000.00 " required />
                
             </Form_Row>
             <Form_Row>
             <label htmlFor=" payment_Reference_Code">
               Payment Reference Code:
                </label>
                <input type="text" onChange={handleOnchange} id='payment_Reference_Code' name="payment_Reference_Code" placeholder="Input MPesa or Bank Payment RefCode" required />
                
             </Form_Row>
             
                </FormOuterRow>    
             
                <AuthButtons>
       <SignUpBtn type="submit" > submit Payment</SignUpBtn>
       </AuthButtons>
        
              </PaymentForm>
        </Container>
    )
}

const Container=styled.div`
display:flex;
flex-direction: column;
justify-content:flex-start;
overflow:hidden;
width:80%;
.h2{
    display:flex;
    justify-content:flex-start;
    margin-left:0px;
}
.p{
    display:flex;
    width:100%;
    justify-content:flex-start;
   

}
`
const PaymentForm=styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width: 80%;

`
const FormOuterRow=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:10px;
width:80%;
`
const Form_Row = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;

input {
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:110%; 
  
}
select {
  margin-top: 10px;  
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
label{
    font-size:12px;
}
@media (max-width: 768px) {
    .label{
        font-size:8px;
    }
    
}
`
const StyledForm=styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;
padding-left:5px;
`
const AuthButtons = styled.div`
display: flex;
align-items:center;
flex-direction:row;

margin-top:20px;
width:100%;
padding-left: 0px;
margin-left:0px;
`
const SignUpBtn = styled.button`
margin-left:80px;
display:flex;
justify-content:center;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color: #E2725B;
/* background-color:red; */
color:#f9f9f9;
border:none;
width:50%;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default LoanPaymentForm