// Spinner.js

import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1000000;
`;

const SpinnerContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
`;

const SpinnerCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3; /* Light grey border */
  border-top: 3px solid #3498db; /* Blue border for spinner animation */
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite; /* Animation for spinning */
`;

const Spinner = () => {
  return (
    <SpinnerOverlay>
      <SpinnerContainer>
        <SpinnerCircle />
      </SpinnerContainer>
    </SpinnerOverlay>
  );
}

export default Spinner;
