import styled from "styled-components"
import { Link, Outlet, useNavigate } from "react-router-dom"
// import Modal from "../SharedFns/Modal"
// import LoanPaymentTransactions from "./LoanPaymentTransactions"
import { useState } from "react"
import { useSelector } from "react-redux"

const Reports=()=>{
    const[isModalOpen, setIsModalOpen]=useState(false)
    const navigate=useNavigate()
    const user_group=useSelector(state=>state.userData?.user?.user?.user_groups)??[]
    const isAuthorizedLedgerCreater = user_group.includes('Treasurer');
    const handleClick= (route)=>{
           navigate(route) 
    }
    return(
        <Container>
            <Header>
                <h2> Accounting Reports </h2>
            </Header>
            <LinksContainer>
            <Linkto  to ='trial-balance' onClick={()=>setIsModalOpen(true)}> Trial Balance</Linkto>
            <Linkto to='balance-sheet'> Balance Sheet </Linkto>
            <Linkto to='profit-loss'> Profit & Loss Statement </Linkto>
            {isAuthorizedLedgerCreater && <Linkto to ='post-ledger'> Post Ledger</Linkto>}
           
            </LinksContainer>
            <RenderTransactions>
            <Outlet/>
            </RenderTransactions>
          
            {/* <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
                {<LoanPaymentTransactions />}
            </Modal> */}
            
           
          
            </Container>
    )


}
const Container=styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
overflow-y:scroll;
overflow-x:scroll;

`
const Table=styled.table`
border-collapse:collapse;
width:100%;
@media (max-width: 768px) {
    width: 80%; // Adjust as needed
    height: auto; // Adjust as needed
    font-size: 12px;
    padding-right:10px;
  }

margin-bottom:100px;
`
const TableHeader=styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;

` 
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  
  @media (max-width: 768px) {
    width: auto; // Adjust as needed
    height: auto; // Adjust as needed
    font-size: 12px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  margin-left: 20px;
  border-bottom: solid 1px gray;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: stretch;
    margin-left: 0;
  }
`;

const Linkto = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px ;
  margin: 5px;
  width: 24%; /* Set width to create a 2 by 2 layout on small screens */
  box-sizing: border-box; /* Ensure padding and border don't affect width */

  border-radius: 10px;
  background-color: #e2725b;
  color: #f9f9f9;
  border: none;
  border-color: #e2725b;

  @media (max-width: 768px) {
    width: 100%; /* Adjust width for small screens */
    max-width: none; /* Remove max-width for small screens */
    height: 30px;
    font-size: 12px;
  }
`;
const RenderTransactions = styled.div`
  width: 100%;
  padding: 10px;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0; // Adjust as needed
  }
`;

const Header=styled.div`
width: 100%;
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex-direction:row;
height:70px;
margin-left:20px;
margin-bottom:20px;
border-bottom: solid 1px gray ;
margin-top:20px;
.h2{
    margin-left:20px;
    align-items:flex-start;
    display:flex;
    padding:10px;
}
@media (max-width: 768px) {
    height: 50px;
    .h2{
        font-size:10px;
    }
    
}
`


export default Reports