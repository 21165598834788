import { createSlice } from "@reduxjs/toolkit";

import { fetchBalanceSheet } from "../api/api";


const initialState={
    loading:false,
    error:{},
    balanceSheetData:[]
}

const getBalanceSheet=createSlice(
{  
    name:'getbalanceSheet',
    initialState,

    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(
            fetchBalanceSheet.pending, (state)=>{
                state.loading=true
                state.error={}
                state.balanceSheetData=[]
            }
        )
    builder.addCase(
        fetchBalanceSheet.rejected, (state, action)=>{
                state.loading=false
                state.error=action.payload
                state.balanceSheetData=[]
            }

        )
        builder.addCase(
            fetchBalanceSheet.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.balanceSheetData=action.payload
            }
        )
    }

}
    

)

export default getBalanceSheet.reducer

