import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfitAndLossReport } from "../../Features/api/api";
import styled from "styled-components";
import DateInputFormPandL from "./DateInputFormPandL";
import Modal from "../../SharedFns/Modal";
import PrintablePandLDocument from "../documents/PrintablePandLDocument";
import { numberWithCommas } from "../../SharedFns/Functions";
import Spinner from "../../SharedFns/SpinnerElement";



const ProfitAndLoss= ()=>{
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen]=useState(false)
  const formattedStartDate= `${StartDate?.getFullYear()}-${StartDate?.getMonth() + 1}-${StartDate?.getDate()}`

  const formattedEndDate= `${EndDate?.getFullYear()}-${EndDate?.getMonth() + 1}-${EndDate?.getDate()}`
      
      

   const dispatch=useDispatch()
   useEffect(
    ()=>{
      dispatch(fetchProfitAndLossReport({'start_date':formattedStartDate, 'end_date':formattedEndDate}));
    }, [dispatch]

   )
   const isLoading=useSelector(state=>state.getProfitAndLoss?.loading)
   const profitLossData= useSelector( state => state.getProfitAndLoss?.ProfitAndLossData?.profit_and_loss)
   const financial_year=useSelector(state=>state.getProfitAndLoss?.ProfitAndLossData?.financial_year) 
   const previous_financial_year=useSelector(state=>state.getProfitAndLoss?.ProfitAndLossData?.previous_financial_year)
   if (!profitLossData) {
    return <div><Spinner/></div>; //  fallback UI like spinner(I will implement spinner)
  }
  const profitandLossData ={...profitLossData, financial_year}
  const {current_year_expenses,  previous_year_expenses,current_year_revenues, previous_year_revenues,current_year_total_revenue,
    previous_year_total_revenue,current_year_total_expenses,previous_year_total_expenses,current_year_net_profit_or_loss,previous_year_net_profit_or_loss
  } = profitLossData || {};


const currentYearData={financial_year, current_year_expenses,current_year_revenues, current_year_total_revenue, current_year_total_expenses, current_year_net_profit_or_loss}
const previousYearData={previous_financial_year, previous_year_expenses, previous_year_net_profit_or_loss, previous_year_total_revenue, previous_year_revenues, previous_year_total_expenses
}
    const accountCodeToName = {
        'interest_income': 'Interest Received',
        'expense': 'Expenses',
        'fine' : 'Fine Income',
        'social_welfare':'Social Welfare',              
        'tax': 'Tax Expense', 
        "end_year_party": "End of Year Party",
        "server_expense": "Server Expense",
        'certificate_renewal': 'Eye20 Certificate Renewal',
        'dividend_expense':'Dividend Payment',
        'good_will':"Goodwill Received ",
        'other_statistic':'Other Statistic from Group Recon',
        'other_income':'Other Income',
        "other_expense": "Other Expenses",
        'transaction_cost':'Transaction Costs',
        'equity_fixed_deposit':'Equity Bank Fixed Deposit',
       'fixed_deposit_interest':'Fixed Deposit Interest',
       'income_from_exited_member':'Income From Exited Members'
       
       
    
      };
      
      
      // Function to calculate total income or expense
    //   const calculateTotal = (type) => {
    //     return journalEntries.reduce((total, entry) => {
    //       const amount = type === 'income' ? entry.credit_amount : entry.debit_amount;
    //       return entry.account === type ? total + parseFloat(amount) : total;
    //     }, 0);
    //   };
    const handlePrintPdf= ()=>{
      setIsModalOpen(true)
    }
  
      return (
       
        <Content>
            <div>
        {isLoading && <Spinner/>
      }
      </div>
            <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
       {/* <PrintableDocument title="Eye20 Group Balance Sheet as on" date={formattedDate} data={data } accountCodeToName={accountCodeToName}/> */}
       <PrintablePandLDocument  title="Eye20 Group Profit And Loss Statement for the Period" date={formattedStartDate} currentYearData={currentYearData} previousYearData={previousYearData } accountCodeToName={accountCodeToName}/>
    </Modal>
           <DateInputFormPandL />
          <h1>Profit and Loss</h1>
      <Table>
        <thead>
          <tr>
            <TableHeader>Category</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          {/* Revenues Section */}
          <SectionHeader colSpan="2">Revenues</SectionHeader>
          {current_year_revenues && Object.values(current_year_revenues).map(revenue => (
            <tr key={revenue.account}>
              <TableCell>{accountCodeToName[revenue.account]}</TableCell>
              <TableCell>{numberWithCommas((revenue.balance).toFixed(2))}</TableCell>
            </tr>
          ))}
          <tr>
            <TableCell>Total Revenue</TableCell>
            <TableCell>{numberWithCommas((current_year_total_revenue).toFixed(2))}</TableCell>
          </tr>

          {/* Expenses Section */}
          <tr>
            <SectionHeader colSpan="2">Expenses</SectionHeader>
          </tr>
          {current_year_expenses && Object.values(current_year_expenses).map(expense => (
            <tr key={expense.account}>
              <TableCell>{accountCodeToName[expense.account]}</TableCell>
              <TableCell>{numberWithCommas((expense.balance).toFixed(2))}</TableCell>
            </tr>
          ))}
          <tr>
            <TableCell>Total Expenses</TableCell>
            <TableCell>{numberWithCommas((current_year_total_expenses).toFixed(2))}</TableCell>
          </tr>

          {/* Net Profit Section */}
          <tr>
            <SectionHeader colSpan="2">Net Profit/Loss</SectionHeader>
          </tr>
          <tr>
            <TableCell>Net Profit/Loss</TableCell>
            <TableCell>{numberWithCommas((current_year_net_profit_or_loss).toFixed(2))}</TableCell>
          </tr>
        </tbody>
      </Table>
      <GetPdf>
         <Button onClick={handlePrintPdf}> Get PDF </Button>
      </GetPdf>

        </Content>
      );
    };
    const Content=styled.div`
    margin-top: 10px;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    @media (max-width: 768px) {
        margin-top:0px;
      }
    
    `
    const Table = styled.table`
      border-collapse: collapse;
      width: 80%;
      margin-bottom: 100px;
      
      @media (max-width: 768px) {
        margin-top:0px;
      }
    `;
    const GetPdf=styled.div`
    margin-bottom: 70px;
    margin-top:10px;
    
    `
    const TableHeader = styled.th`
      border: solid 1px #000;
      background-color: #f2f2f2;
      padding: 10px;
      font-weight:bold;
      width: 100%;
    `;
    const SectionHeader = styled.th`
    border: solid 1px #000;
  
    background-color: #f2f2f2;
    padding: 10px;
    font-weight: bold;
    width:100%;
  `;
    const TableCell = styled.td`
      border: 1px solid #000;
      padding: 10px;
      text-align: left;
      @media (max-width: 768px) {
        width: 12px;
        font-size: 10px;
        &.hide-on-small-screen {
          display: none; // hide this column on small screens
        }
      }
    `;
    
    const Button = styled.button`
    cursor:pointer;
    align-items:center;
    margin-left:20px;
    text-decoration:none;
    display:flex;
    justify-content:flex-start;
    cursor:pointer;
    padding: 10px 30px;
    border-radius:10px;
    background-color:#E2725B;
    color:#f9f9f9;
    border:none;
    border-color:#E2725B;
    `

   



export default ProfitAndLoss