import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Modal from "../SharedFns/Modal";
import SignIn from './SignIn';
import RegForm from './RegForm';
import { FaQuoteLeft, FaQuoteRight, FaCheck} from 'react-icons/fa'
import { faPiggyBank, faHandHoldingUsd, faChartLine, faCoins, faUsers, faShield,faMapMarkerAlt,faEnvelope,faPhoneAlt, faCommentDots } from '@fortawesome/free-solid-svg-icons';


const News = () => {
  const [index, setIndex] = useState(0);
  const [isModalOpen, setIsModalOpen]=useState(false)
  const [isSignUpModalOpen, setIsSignUpModalOpen]=useState(false)
  const testimonialsData = [
    {
      image: '/media/karis_linkedin_photo.jpeg',
      topic: 'Exceptional App',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing not only simplified my finances but also enabled me to invest more confidently."',
      name: '- Daniel Kariuki'
    },
    {
      image: '/media/welcomeRightUpper.jpg',
      topic: 'A Satisfying Platform',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing simplified my hassle for quick finances and also enabled me to invest more confidently."',
      name: '- Jesse Kimani'
    },
{
      image: '/media/mylinkedin_photo.jpeg',
      topic: 'A Satisfying Platform',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing simplified my hassle for quick finances and also enabled me to invest more confidently."',
      name: '- David Kibobo'
    },
    {
      image: '/media/maina_linkedin_Photo.jpeg',
      topic: 'A Satisfying Platform',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing simplified my hassle for quick finances and also enabled me to invest more confidently."',
      name: '- David Karanja'
    },
    {
      image: '/media/njambi_linkedin_photo.jpeg',
      topic: 'A Satisfying Platform',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing simplified my hassle for quick finances and also enabled me to invest more confidently."',
      name: '- Marya Njambi'
    },
    {
      image: '/media/wangai_linkedin_photo.jpeg',
      topic: 'A Satisfying Platform',
      quote: '"EYE20 GROUP\'s seamless process for saving and borrowing simplified my hassle for quick finances and also enabled me to invest more confidently."',
      name: '- Simon Wangai'
    },
    // More testimonials data here
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % testimonialsData.length);
    }, 8000); // Change testimonials every 5 seconds

    return () => clearInterval(intervalId);
  }, []);
  const handleSignInClick=()=>{
    setIsModalOpen(true)
  }
  const handleSignUpClick=()=>{
    setIsSignUpModalOpen(true)
  }
  return (
<div>
  News
</div>
  )
}
//     <OverallContainer>
//       <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
//                {<SignIn />}
//             </Modal>
//             <Modal isOpen={isSignUpModalOpen} onClose={()=>setIsSignUpModalOpen(false)}>
//                {<RegForm />}
//             </Modal>      
//       <NavbarContainer>
//         <Navbar>
//         <BrandNameHeader>
//         <NavLink id="nav-name-text" href="#top">EYE20 GROUP</NavLink>
//         </BrandNameHeader>
          
        
//         <NavLinks>
//           <NavLink href="#about-us">About Us</NavLink>
//           <NavLink href="#services">Services</NavLink>
//           <NavLink href="#portfolio">Portfolio</NavLink>
//           <NavLink href="#testimonials">Testimonials</NavLink>

//           <NavLink href="#contact-us">Contact Us</NavLink>
          
//         </NavLinks>
//         <ContactHeaderButton>
//           <CTAButtonHeader id="nav-cta-button">
//             <HeaderNavLink onClick={handleSignUpClick} >Sign Up </HeaderNavLink>
//           </CTAButtonHeader>
//           <CTAButtonHeader id="nav-cta-button">
//             <HeaderNavLink onClick={handleSignInClick} >Log In </HeaderNavLink>
//           </CTAButtonHeader>
//         </ContactHeaderButton>
//         <MobileMenuIcon>
//           <MenuButton aria-label="Navigation Dropdown Menu">
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//               <line x1="3" y1="12" x2="21" y2="12"></line>
//               <line x1="3" y1="6" x2="21" y2="6"></line>
//               <line x1="3" y1="18" x2="21" y2="18"></line>
//             </svg>
//           </MenuButton>
//         </MobileMenuIcon>
//         <MobileMenu>
//         <MobileNavLink href="#about-us">Pricing</MobileNavLink>
//           <MobileNavLink href="#services">Services</MobileNavLink>
//           <MobileNavLink href="#portfolio">Portfolio</MobileNavLink>
          
//           <div id="nav-mobile-cta-button">
//             <div id="nav-cta-button">
//               <NavLink href="#contact" className="primary-color-bg px-4 py-2 text-white bg-blue-700">Contact Us</NavLink>
//             </div>
//           </div>
//         </MobileMenu>
//       </Navbar>
//       </NavbarContainer>
//       <HeaderContainer id='top'>
        
//         <HeaderContent>
//           <HeaderText>
//             <Heading><Paragraph>Empower Saving</Paragraph></Heading>
//             <Heading><Paragraph style={{ color: '#007bff' }}>Simplify Borrowing</Paragraph></Heading>
//             <Heading><Paragraph>Invest Wisely</Paragraph></Heading>
//             <Subtext>Join the revolution in financial management with EYE20 GROUP, your dedicated app for savings, loans, and investment opportunities.</Subtext>
//             <CTAButton>
//               <a href="#about-us">Get Started </a>
//             </CTAButton>
//           </HeaderText>
//           <ImageContainer>
//             <Image src="/media/welcomeRightUpper.jpg" alt="Hero"/>
//           </ImageContainer>
//         </HeaderContent>
//       </HeaderContainer>
//       <Section>
//         <WelcomeContainer>
//           <CallToActionContent>
//             <Subheader>Ready to Thrive?</Subheader>
//             <Header>Join EYE20 GROUP Today</Header>
//             <CallToActionText>Become a member of our empowering community and start experiencing financial services tailored to your success.</CallToActionText>
//             <CallToAction href="/#contact"><span><SignUpBtn>Sign Up Now</SignUpBtn></span></CallToAction>
            
//           </CallToActionContent>
//           <InnerDiv></InnerDiv>
//           <WelcomeContainerRight>
//             <WelcomeCOntainerRightImage src="https://landingsite-static-web-images.s3.us-east-2.amazonaws.com/business_man2.png" alt="Business Owner"/>
//             <WelcomeContainerRightBackground></WelcomeContainerRightBackground>
//           </WelcomeContainerRight>
//         </WelcomeContainer>
//       </Section>

//       {/* Added part */}
//       {/* <StyledDiv></StyledDiv> */}
//       <CodeSectionContainer>
//         <Section2 id="services" >
//           <ServicesContainer>
//             <ServicesHeader>Comprehensive Financial Services</ServicesHeader>
//             <ServicesSubtext>At EYE20 GROUP, we offer a suite of powerful features designed specifically for our members. Discover how our app can transform your financial habits and help you achieve your economic goals.</ServicesSubtext>
//             <ServicesGrid>
//               <ServiceItemRows>
//                 <Image src='/media/hand_holding_phone.jpeg' alt='Member'/>
//               </ServiceItemRows>
//               <ServiceItem>
//                 <ServiceIcon>
//                 <Icon icon= {faPiggyBank} style={{ color:"#0039a6"}}/>

               

//                 </ServiceIcon>
//                 <ServiceHeader>Smart Savings</ServiceHeader>
//                 <ServiceText>Our intuitive platform encourages regular saving habits and offers competitive interest rates to help grow your wealth consistently and securely.</ServiceText>
//               </ServiceItem>
//               <ServiceItem>
//                 <ServiceIcon style={{backgroundColor:"#FBCEB1"}} >
//                   <Icon icon={faHandHoldingUsd} style={{color:"#FFA07A"}}/>
//                 </ServiceIcon>
//                 <ServiceHeader>Easy Loans</ServiceHeader>
//                 <ServiceText>Access quick loans with flexible repayment terms and low interest rates, available exclusively for EYE20 GROUP members to fulfill personal and professional needs.</ServiceText>
//               </ServiceItem>
//               <ServiceItem>
//                 <ServiceIcon style={{backgroundColor:"#E9967A"}}>
//                   <Icon icon={faChartLine}/>
//                    </ServiceIcon>
//                 <ServiceHeader>Investment Growth</ServiceHeader>
//                 <ServiceText>Take advantage of diverse investment opportunities that promise returns and help to diversify your portfolio while minimizing risks.</ServiceText>
//               </ServiceItem>
//               <ServiceItem>
//                 <ServiceIcon > 
//                   <Icon icon={faCoins} style={{ color:"#00BFFF"}}/>
//                 </ServiceIcon>
//                 <ServiceHeader>Annual Dividends</ServiceHeader>
//                 <ServiceText>Enjoy a share of our profits with annual dividends, rewarding you for your loyalty and trust in our financial community.</ServiceText>
//               </ServiceItem>
//               <ServiceItem>
//                 <ServiceIcon   style={{backgroundColor:"#f5f5f5"}}>
//                  <Icon icon={faUsers} style={{color: "#808080"}}/> 
                  
//                    </ServiceIcon>
//                 <ServiceHeader>Member Community</ServiceHeader>
//                 <ServiceText>Become part of an exclusive community that supports each other's financial aspirations, while benefiting from collective financial services.</ServiceText>
//               </ServiceItem>
//               <ServiceItem>
//                 <ServiceIcon style={{backgroundColor:"#e5e4e2"}}>
//                   < Icon icon={faShield} style={{color:"#B6B6B4"}}/>
//                 </ServiceIcon>
//                 <ServiceHeader>Secure Transactions</ServiceHeader>
//                 <ServiceText>Every transaction is safeguarded with state-of-the-art security measures, ensuring your finances are protected at every turn.</ServiceText>
//               </ServiceItem>
//             </ServicesGrid>
//           </ServicesContainer>
//         </Section2>
//       </CodeSectionContainer>
//       <PortfolioSection id='portfolio'>
       
//       <PortfolioContainer>
//         <PortfolioHeading>Our Services</PortfolioHeading>
//         <PortfolioHeader>Explore Benefits</PortfolioHeader>
//         <PortfolioSubtext>
//           Each feature of the EYE20 GROUP app is designed to enrich your financial well-being. Discover how our services work for you.
//         </PortfolioSubtext>
//         <PortfolioGrid>
//           <PortfolioItem>
//             <PortfolioImage src="https://images.unsplash.com/photo-1556742111-a301076d9d18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwzfHxGaW50ZWNofGVufDB8MHx8fDE3MTMyNTgyNzJ8MA&ixlib=rb-4.0.3&q=80&w=1920" alt="Savings Simplified" />
//             <PortfolioText>
//               <PortfolioHeader>Savings Simplified</PortfolioHeader>
//               <PortfolioSubtext>Maximize your earnings with our optimized saving solutions designed for effortless wealth accumulation.</PortfolioSubtext>
//             </PortfolioText>
//           </PortfolioItem>
//           <PortfolioItem>
//             <PortfolioImage src="https://images.unsplash.com/photo-1556740714-a8395b3bf30f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHw0fHxGaW50ZWNofGVufDB8MHx8fDE3MTMyNTgyNzJ8MA&ixlib=rb-4.0.3&q=80&w=1920" alt="Loans Made Easy" />
//             <PortfolioText>
//               <PortfolioHeader>Loans Made Easy</PortfolioHeader>
//               <PortfolioSubtext>Achieve your goals with accessible loans that come with transparent terms and no hidden fees.</PortfolioSubtext>
//             </PortfolioText>
//           </PortfolioItem>
//           <PortfolioItem>
//             <PortfolioImage src="https://images.unsplash.com/photo-1561525155-40a650192479?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHw1fHxGaW50ZWNofGVufDB8MHx8fDE3MTMyNTgyNzJ8MA&ixlib=rb-4.0.3&q=80&w=1920" alt="Investment Opportunities" />
//             <PortfolioText>
//               <PortfolioHeader>Investment Opportunities</PortfolioHeader>
//               <PortfolioSubtext>Diversify your assets with curated investment options that align with your long-term financial ambitions.</PortfolioSubtext>
//             </PortfolioText>
//           </PortfolioItem>
//         </PortfolioGrid>
//       </PortfolioContainer>
//       <PortfolioDecorations />
//     </PortfolioSection>
//     <TestimonialsSection id='testimonials'>
//       <TestimonialsContainer>
//         <TestimonialsHeader>Member Testimonials</TestimonialsHeader>
//         <TestimonialsSubtext>Hear firsthand how EYE20 GROUP has transformed the financial lives of our valued community members.</TestimonialsSubtext>
//         <TestimonialsContent>
//           <TestimonialsBackground />
//           <TestimonialsCard>
//             <QuoteLeftIcon />
//             <QuoteRightIcon />
//             <TestimonialsImage src={testimonialsData[index].image} alt="Hero" />
//       <TestimonialsContentWrapper>
//         <TestimonialsTopic>{testimonialsData[index].topic}</TestimonialsTopic>
//         <TestimonialsQuote>{testimonialsData[index].quote}</TestimonialsQuote>
//         <TestimonialsName>{testimonialsData[index].name}</TestimonialsName>
//       </TestimonialsContentWrapper>
//           </TestimonialsCard>
//         </TestimonialsContent>
//       </TestimonialsContainer>
//     </TestimonialsSection>
//     <AboutSection id='about-us' >
//       <AboutContainer>
//         <AboutContent>
//           <AboutTextContainer>
//             <AboutHeader>About EYE20 GROUP</AboutHeader>
//             <AboutParagraph>
//               Established to foster financial independence among our members, EYE20 GROUP provides an accessible platform for savings, loans, and investments. We bridge the gap between your current finances and future wealth.
//             </AboutParagraph>
//             <AboutCheckmarkList>
//               <li>
//                 <FaCheck />
//                 <span>EYE20 GROUP offers personalized savings programs that align with individual goals, ensuring each member has a clear path to financial success.</span>
//               </li>
//               <li>
//                 <FaCheck />
//                 <span>Our competitive loan facilities are designed with our members in mind, offering a hassle-free process and rates that empower rather than encumber.</span>
//               </li>
//               <li>
//                 <FaCheck />
//                 <span>An unwavering commitment to transparency in investments guarantees that our members have the information and support they need to make smart financial decisions.</span>
//               </li>
//             </AboutCheckmarkList>
//             <AboutSecondParagraph>
//               With a community-centered approach, EYE20 GROUP stands out as the definitive solution for members who seek more than just a financial service—they seek a financial partner. Additionally, EYE20 GROUP offers data management and consultancy services for other groups, including managing their loan books, facilitating group loans, and providing individual loans with group members serving as guarantors.
//             </AboutSecondParagraph>
//             <AboutCTAButton>
//               <a href="#contact-us">Learn More</a>
//             </AboutCTAButton>
//           </AboutTextContainer>
//           <AboutImageContainer>
//             <AboutBackground />
//             <AboutImage src="https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHw3fHxGaW50ZWNofGVufDB8MHx8fDE3MTMyNTgyNzJ8MA&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1920" alt="Hero" />
//           </AboutImageContainer>
//         </AboutContent>
//       </AboutContainer>
//     </AboutSection>
//     <ContactSection id="contact-us">
//       <ContactContainer>
//         <ContactCard>
//           <ContactInfo>
//             <ContactDetails>
//               <ContactInfoHeading>Contact Us</ContactInfoHeading>
//               <ContactInfoTitle> <ContactInfoIcon icon={faCommentDots}/> Let's talk</ContactInfoTitle>
//               <ContactInfoDetails>
//                 <ContactInfoItem>
//                   <ContactInfoIcon icon={faMapMarkerAlt}/>
//                   <ContactInfoText>Nairobi, Kenya</ContactInfoText>
//                 </ContactInfoItem>
//                 <ContactInfoItem>
//                 <ContactInfoIcon icon={faEnvelope}/>                
//                   <ContactInfoText>support@eye20group.com</ContactInfoText>
//                 </ContactInfoItem>
//                 <ContactInfoItem>
//                 <ContactInfoIcon icon={faPhoneAlt}/>    
//                      <ContactInfoText>+254 715 130 607</ContactInfoText>
//                 </ContactInfoItem>
//               </ContactInfoDetails>
//             </ContactDetails>
//           </ContactInfo>
//           <ContactFormWrapper>
//             <ContactForm id="contact-us-form">
//               <ContactFormRow>
//                 <ContactInputWrapper>
//                   <ContactLabel htmlFor="first-name">First Name</ContactLabel>
//                   <ContactInput id="first-name" name="first-name" type="text" />
//                 </ContactInputWrapper>
//                 <ContactInputWrapper>
//                   <ContactLabel htmlFor="last-name">Last Name</ContactLabel>
//                   <ContactInput id="last-name" name="last-name" type="text" />
//                 </ContactInputWrapper>
//               </ContactFormRow>
//               <ContactFormRow>
//                 <ContactInputWrapper>
//                   <ContactLabel htmlFor="email">Email Address</ContactLabel>
//                   <ContactInput id="email" type="email" name="email" />
//                 </ContactInputWrapper>
//                 <ContactInputWrapper>
//                   <ContactLabel htmlFor="phone">Phone</ContactLabel>
//                   <ContactInput id="phone" type="phone" name="phone" />
//                 </ContactInputWrapper>
//               </ContactFormRow>
//               <ContactFormRow>
//                 <ContactLabel htmlFor="message">Message</ContactLabel>
//                 <ContactTextarea id="message" name="message" rows="4"></ContactTextarea>
//               </ContactFormRow>
//               <ContactButton type="submit">Send Message</ContactButton>
//             </ContactForm>
//           </ContactFormWrapper>
//         </ContactCard>
//       </ContactContainer>
//     </ContactSection>
//     <FooterContainer id="poewr">
//       <BackgroundCircle />
//       <FooterContent>
//         <FooterBrandContainer style={{ marginBottom:"40px"}}>
//           <div style={{ marginBottom:"30px"}}>
//             <BrandName  href="/">EYE20 GROUP</BrandName>
//           </div>
          
//           <FooterText style={{fontSize:"16px", fontWeight:"normal"}}>
//             Taking your financial journey to the next level with secure savings, loans, and investment solutions.
//           </FooterText>
//         </FooterBrandContainer>
//         <FooterNavLinkContainer>
//           <FooterText>Navigation</FooterText>
//           <FooterNavLink href="#top">Home</FooterNavLink>
//           <FooterNavLink href="#services">Services</FooterNavLink>
//           <FooterNavLink href="#portfolio">Portfolio</FooterNavLink>
//           <FooterNavLink href="#pricing">Pricing</FooterNavLink>
//           <FooterNavLink href="#contact-us">Contact</FooterNavLink>
//         </FooterNavLinkContainer>
//         <FooterServiceContainer>
//           <FooterText>Services</FooterText>
//           <FooterService>Smart Savings</FooterService>
//           <FooterService>Easy Loans</FooterService>
//           <FooterService>Investment Growth</FooterService>
//           <FooterService>Annual Dividends</FooterService>
//           <FooterService>Member Community</FooterService>
//           <FooterService>Secure Transactions</FooterService>
//         </FooterServiceContainer>
//         <FooterContactContainer>
//           <FooterText>Contact Us</FooterText>
//           <FooterContactItem>
//             <FooterIcon icon={faPhoneAlt}/>
//             <div>+254 715 130 607</div>
//           </FooterContactItem>
//           <FooterContactItem>
//             <FooterIcon icon={faEnvelope}/>
//             <div>support@eye20group.com</div>
//           </FooterContactItem>
//           <FooterContactItem>
//             <FooterIcon icon={faMapMarkerAlt}/>
//             <div>Nairobi, Kenya</div>
//           </FooterContactItem>
//         </FooterContactContainer>
        
//       </FooterContent>
//       <FooterCopyrightContainer>
//           <FooterCopyright>© EYE20 GROUP, Inc 2024.</FooterCopyright>
//         </FooterCopyrightContainer>
//         <BackgroundCircle/>
//     </FooterContainer>
//     </OverallContainer>
//   );
// };
// const BrandNameHeader = styled.div`
//   font-size: 2.25rem; /* Equivalent to text-xl */
//   font-weight: bold;
//   color:#007bff;
// `;
// const CTAButtonHeader=styled.div`
// display: flex;
// align-items:center;
// margin-right:10px;

// `
// const HeaderNavLink = styled.button`
//   background-color: #2b6cb0; /* Replace with the hex color */
//   border-radius: 0.25rem; /* Add the appropriate border-radius */
//   padding: 0.5rem 1rem; /* Adjust padding as needed */
//   font-size: 0.75rem; /* Adjust font-size as needed */
//   text-transform: uppercase;
//   text-decoration:none;
//   color: #fff; /* Replace with the appropriate text color */
//   display: inline-block;
//   &:hover{
//     text-decoration:underline;
//   }
// `;
// const NavbarContainer = styled.header`
//  background-color:#F0F8FF;
//  width:100%;
//   /* background-color: var(--primary-color-[50]); */
//   font-family: 'Poppins';
  
//   /* border: solid 1px red; */
//   height:100px;
// `;
// const Navbar = styled.nav`
//   position: relative;
//   z-index: 10;
//   max-width: 100%;
//   width:90%;
//   margin: 0 auto;
//   padding: 10px 16px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   @media (min-width: 640px) {
//     padding: 10px 12px;
//   }

//   @media (min-width: 1280px) {
//     padding: 10px 32px;
//   }
// `;

// const NavLinks = styled.div`
//   display: none;

//   @media (min-width: 768px) {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// `;

// const NavLink = styled.a`
//   color: var(--nav-text-color);
//   text-decoration: none;
//   margin-right: 8px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const MobileMenuIcon = styled.div`
//   display: flex;
//   align-items: center;

//   @media (min-width: 700px) {
//     display: none;
//   }
// `;

// const MenuButton = styled.button`
//   color: var(--nav-text-color);
//   background: none;
//   border: none;
//   cursor: pointer;
// `;

// const MobileMenu = styled.div`
//   display: none;

//   @media (max-width: 767px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     background-color: #fff;
//     padding-bottom: 10px;
//     margin-top: 4px;
//     position: relative;
//     z-index: 10;
//   }
// `;

// const MobileNavLink = styled.a`
//   color: var(--nav-text-color);
//   text-decoration: none;
//   margin-bottom: 8px;
// `;

// const ContactHeaderButton = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const theme = {
//   colors: {
//     primaryColorBg: '#ffffff', // Example background color
//     primaryColorText: '#333333', // Example text color
//   },
// };

// const FooterContainer = styled.footer`
//  background-color:#F0F8FF;/* White background */
//   color: #333333; /* Dark gray text */
//   font-family: 'Poppins';
//   padding-top: 16px;
//   margin-top: -52px;
  
//   /* margin-bottom:70px; */
//   position: relative;
//   /* overflow: hidden; */
// `;
// const FooterCopyrightContainer = styled.div`
//   margin-top: 24px;
//   text-align: center;
// `;

// const FooterCopyright = styled.p`
//   margin-top: 24px;
//   text-align: center;
//   font-size: 1.125rem;
//   color: var(--primary-color-text);
// `;

// const FooterContent = styled.div`
//   position: relative;
//   z-index: 10;
//    display: grid;
//   grid-template-columns: 1fr;
//   padding-left:0;
//   margin-left:0;
//   gap: 2rem;
//   @media (min-width: 768px) {
//     grid-template-columns: repeat(4, 1fr);
//   }
//   max-width: 100%;
//   width:90%;
//   margin: 0 auto;
//   padding: 36px 16px 0 16px;

//   @media (min-width: 640px) {
//     padding: 36px 12px 0 12px;
//   }

//   @media (min-width: 1280px) {
//     padding: 36px 32px 0 32px;
//   }
// `;

// const BackgroundCircle = styled.div`
//   position: absolute;
//   top: -14px;
//   left: -80px;
//   height: 400px;
//   width: 400px;
//   border-radius: 200px;
//   border: 60px solid #F0F8FF;
//   /* border-color: var(--primary-color-[100]); */
// `;

// const FooterBrandContainer = styled.div`
//   margin-bottom: 8px;
// `;

// const BrandName = styled.a`
//   font-size: 20px;
//   font-weight: bold;
//   text-decoration:none;
//   /* color: var(--primary-color-text); */
//   color: #2b6cb0;
//   margin-bottom: 20px !important;
//   && {
//     margin-bottom: 20px !important; /* Increase specificity */
//   }


//   @media (min-width: 1024px) {
//     padding-right: 30%;
//   }
// `;

// const FooterNavLinkContainer = styled.div`
//   margin-bottom: 8px;
//   display: flex;
//   flex-direction:column;
// `;

// const FooterNavLink = styled.a`
//   color: var(--primary-color-text);
//   text-decoration: none;
//   margin-bottom: 8px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const FooterServiceContainer = styled.div`
//   margin-bottom: 8px;
// `;

// const FooterService = styled.div`
//   color: var(--primary-color-text);
//   margin-bottom: 8px;
// `;

// const FooterContactContainer = styled.div`
//   margin-bottom: 8px;
// `;

// const FooterContactItem = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-bottom:20px;
//   color: #1a202c;
// `;

// const FooterIcon = styled(FontAwesomeIcon)`
//   flex: none;
//   height: 10px;
//   width: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   padding: 2px;
//   border-color:blue;
//   background-color:white;
  
 
//   margin-right: 8px;
// `;

// const FooterText = styled.div`
//   margin-bottom: 30px;
//   font-size: 20px;
//   font-weight: bold;
//   /* width: 25%; */
// `;
// const ContactSection = styled.section`
//   font-family: 'Poppins';
//   /* border: solid 1px yellow; */
//   margin-left:10px;
//   margin-bottom:70px;
//   width:100%;
 

// `;

// const ContactContainer = styled.div`
//   margin: 0 auto;

  
//   padding: 4rem 1rem;
//   /* border: solid 1px red; */

//   @media (min-width: 640px) {
//     padding: 4rem 0.5rem;
//   }

//   @media (min-width: 1024px) {
//     padding: 4rem 2rem;
//   }
// `;

// const ContactCard = styled.div`
//   border-radius: 1.5rem;
//   background-color: #fff;
//   display:flex;
//   width:90%;
//   padding: 1.5rem;
//   box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
// `;

// const ContactInfo = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const ContactDetails = styled.div`
//   background-color: #1e40af;
//   border-radius: 1.5rem;
//   padding: 2rem;
//   color: #fff;

//   @media (min-width: 768px) {
//     padding: 2rem 3rem;
//   }
// `;

// const ContactFormWrapper = styled.div`
//   padding: 1.5rem;
// `;

// const ContactForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;

//   @media (min-width: 768px) {
//     flex-direction: column;
//     gap: 2rem;
//   }
// `;

// const ContactInputWrapper = styled.div`
//   width: 100%;
// `;

// const ContactInput = styled.input`
//   width: 100%;
//   border: 1px solid #fff;
//   border-bottom-color: #d1d5db;
//   padding: 0.5rem;
// `;

// const ContactTextarea = styled.textarea`
//   width: 100%;
//   border: 1px solid #fff;
//   border-bottom-color: #d1d5db;
//   padding: 0.5rem;
// `;

// const ContactButton = styled.button`
//   background-color: #1e40af;
//   border: none;
//   border-radius: 0.5rem;
//   color: #fff;
//   padding: 0.75rem 1.5rem;
//   text-transform: uppercase;
//   font-weight: bold;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #1e3a8a;
//   }
// `;

// const ContactInfoHeading = styled.div`
//   margin-bottom: 1rem;
//   text-transform: uppercase;
// `;

// const ContactInfoTitle = styled.div`
//   margin-bottom: 2rem;
//   font-size: 2.5rem;
//   font-weight: bold;
// `;

// const ContactInfoDetails = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const ContactInfoItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 2rem;
 
// `;

// const ContactInfoIcon = styled(FontAwesomeIcon)`
//   font-size: 18px;
//   margin-right: 1rem;
//   border-radius:50%;
//   padding: 8px;
//   width:50px;
//   height:50px;
//   border:solid 1px white;
// `;

// const ContactInfoText = styled.div`
//   font-size: 1.5rem;
// `;

// const ContactFormRow = styled.div`
//   display: flex;
//   flex-wrap: nowrap;
//   gap: 1rem;

//   @media (min-width: 768px) {
//     gap: 2rem;
//   }
// `;

// const ContactLabel = styled.label`
//   font-weight: bold;
//   margin-bottom: 0.5rem;
// `;
// const AboutSection = styled.section`
//   position: relative;
//   background-color: #ffffff;
//   font-family: 'Poppins';
//   width:95%;
//   /* border:solid 1px red; */
//   margin-bottom:50px;
// `;

// const AboutContainer = styled.div`
//   max-width: 1440px;
//   margin: 0 auto;
//   padding: 32px 16px;
// `;

// const AboutContent = styled.div`
//   position: relative;
//   z-index: 10;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width:80%;
//   @media (min-width: 1024px) {
//     flex-direction: row;
//   }
// `;

// const AboutTextContainer = styled.div`
//   margin-top: 12px;
//   width: 100%;
//   max-width: 55%;
//   padding-right: 14px;
//   @media (min-width: 1024px) {
//     margin-top: 0;
//   }
// `;

// const AboutHeader = styled.h1`
//   margin-bottom: 8px;
//   text-align: center;
//   font-size: 4xl;
//   font-weight: bold;
// `;

// const AboutParagraph = styled.p`
//   margin-bottom: 12px;
//   text-align: start;
//   font-size: 1rem;
//   opacity:0.7;
//   /* color: #666; */
// `;

// const AboutCheckmarkList = styled.ul`
//   margin-bottom: 8px;
//   padding-left: 8px;
//   margin-left:20px;
//   text-align: center;
//   opacity:0.7;
//   /* color: #666; */

//   li {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     text-align:start;
//     margin-bottom: 12px;

//     svg {
//       margin-right: 4px;
//       color: #3fb950; /* green */
//     }
//   }
// `;

// const AboutSecondParagraph = styled.p`
//   margin-bottom: 12px;
//   text-align: start;
//   opacity:0.7;
  
//   /* color: #666; */
// `;

// const AboutCTAButton = styled.div`
//   margin-bottom: 12px;
//   text-align: center;

//   a {
//     display: inline-block;
//     padding: 8px 16px;
//     font-weight: bold;
//     color: #fff;
//     background-color: #007bff; /* blue */
//     border-radius: 4px;
//     transition: background-color 0.3s ease;

//     &:hover {
//       background-color: #0056b3; /* dark blue */
//     }
//   }
// `;

// const AboutImageContainer = styled.div`
//   position: relative;
//   display: none;
//   @media (min-width: 1024px) {
//     display: block;
//     margin-top: 12px;
//     width: 45%;
//     padding-left: 6px;
//   }
// `;

// const AboutImage = styled.img`
//   width: 100%;
//   border-radius: 8px;
//   object-fit: cover;
// `;

// const AboutBackground = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   height: 100px;
//   width: 100px;
//   border-radius: 50%;
//   background-color: rgba(0, 123, 255, 0.05); /* Light blue */
// `;

// const TestimonialsSection = styled.section`
//   position: relative;
//   background-color: #ffffff;
//   font-family: 'Poppins';
//   width:95%;
//   margin-left:0px;
// `;

// const TestimonialsContainer = styled.div`
//   max-width: 1440px;
//   margin: 0 auto;
//   margin-bottom:4rem;
//   padding: 32px 16px;
//   /* border:solid 1px red; */

//   @media (min-width: 640px) {
//     padding: 32px 24px;
//   }
//   @media (min-width: 1280px) {
//     padding: 32px 32px;
//   }
// `;

// const TestimonialsHeader = styled.h2`
//   margin-bottom: 24px;
//   text-align: start;
//   font-size: 2.25rem;
//   font-weight: bold;
//   color: #000;
// `;

// const TestimonialsSubtext = styled.h3`
//   margin-bottom: 16px;
//   text-align: start;
//   color: #333;
//   font-size:1.125rem;
// `;

// const TestimonialsContent = styled.div`
//   position: relative;
//   display:flex;
//   width: 80%;
//   margin-top:50px;
//   align-items:center;
//   /* border:solid 1px yellow; */
// `;

// const TestimonialsBackground = styled.div`
//   position: absolute;
//   top: 0;
//   left: 30px;
//   bottom: 0;
//   right: 0;
//   background-color: #89CFF0;
//   transform: rotate(-3deg);
//   width:80%;
//   border-radius: 1.5rem;
// `;

// const TestimonialsCard = styled.div`
//   position: relative;
//   left:30px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   width:80%;
//   padding: 24px;
//   background-color: #ffffff;
//   box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
//   border-radius: 1.5rem;
//   z-index: 10;
// `;

// const QuoteLeftIcon = styled(FaQuoteLeft)`
//   position: absolute;
//   top: -12px;
//   left: -12px;
//   color: #89CFF0;
//   font-size: 2.5rem;
// `;

// const QuoteRightIcon = styled(FaQuoteRight)`
//   position: absolute;
//   bottom: -12px;
//   right: -12px;
//   color: #89CFF0;
//   font-size: 2.5rem;
// `;

// const TestimonialsImage = styled.img`
//   margin-top: 8px;
//   margin-bottom: 24px;
//   width: 40%;
//   max-height: 200px;
//   object-fit: cover;
//   border-radius: 50%;
// `;

// const TestimonialsContentWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-left: 20px;
// `;

// const TestimonialsTopic = styled.div`
//   margin-bottom: 16px;
//   font-size: 2.25rem;
//   font-weight: bold;
// `;

// const TestimonialsQuote = styled.div`
//   margin-bottom: 16px;
//   font-size: 1rem;
// `;

// const TestimonialsName = styled.div`
//   font-size: 1rem;
//   font-weight: bold;
// `;

// const PortfolioSection = styled.section`
//   background-color: #F0F8FF;
//   padding-bottom: 32px;
//   position: relative;
//   font-family: 'Poppins';
//   /* border: 1px solid red; */
//   width:100%;
//   margin-bottom:5rem;
// `;

// const PortfolioContainer = styled.div`
//   max-width: 1440px;
//   margin: 0 auto;
//   margin-left:0px;
//   /* border:solid 1px yellow; */
//   padding: 0 40px;
//   padding-left:0px;
//   width:90%;
//   @media (min-width: 640px) {
//     padding: 0 24px;
//   }
//   @media (min-width: 1280px) {
//     padding: 0 32px;
//   }
// `;

// const PortfolioHeading = styled.div`
// /* #007bff */
//   color: #000; 
//   font-size: 1.125rem;
//   margin-bottom: 16px;
//   text-align: start;
// `;

// const PortfolioHeader = styled.div`
//   color: #000;
//   font-size: 2.25rem;
//   font-weight: bold;
//   margin-bottom: 32px;
//   text-align: start;
// `;

// const PortfolioSubtext = styled.p`
//  color: #000;
//   font-size: 1.125rem;
//   font-weight: 300;
//   margin-bottom: 32px;
//   text-align: start;
// `;

// const PortfolioGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr;
//   padding-left:0;
//   margin-left:0;
//   gap: 32px;
//   @media (min-width: 768px) {
//     grid-template-columns: repeat(3, 1fr);
//   }
// `;

// const PortfolioItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   border-radius: 1.5rem;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const PortfolioImage = styled.img`
//   width: 100%;
//   height: auto;
//   object-fit: cover;
//   border-radius: 1.5rem 1.5rem 0 0;
// `;

// const PortfolioText = styled.div`
//   background-color: #fff;
//   padding: 24px;
//   border-radius: 0 0 1.5rem 1.5rem;
// `;

// const PortfolioDecorations = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   top: 0;
//   overflow: hidden;
// `;
// const Section2 = styled.div`
// font-family: 'Poppins';
// padding: 20px 0;
// background-color: #fff;
// width:90%;
// `;
// const CodeSectionContainer = styled.div`
//   width:100%;
// `;
// const ServicesContainer = styled.div`
// display:flex;
// align-items:center;
// flex-direction:column;
//   padding: 20px 40px;
//   background-color: #fff;
//   font-family: 'Poppins';
// `;

// const ServicesHeader = styled.h2`
//   font-size: 2.5rem;
//   font-weight: bold;
//   color: #000;
//   text-align: center;
//   margin-bottom: 2rem;
// `;
// const Icon =styled(FontAwesomeIcon)`
// font-size: 2rem; /* Adjust the size as needed */
//   color: #E2725B; /* Icon color */
//   cursor: pointer
// `
// const ServicesSubtext = styled.div`
//   font-size: 18px;
//   // font-weight: bold;
//   color: #333;
//   align-items:center;
//   text-align: center;
//   width:70%;
//   // border: 1px solid green;
//   margin-bottom: 2rem;
// `;
// const ServiceItemRows = styled.div`
//   /* Add your styles for ServiceItem here */
//   position:relative;
//   grid-row: span 2; /* Spanning two rows */
// `;
// const ServicesGrid = styled.div`
//   display: grid;
//   padding: 0 20px;
//   grid-template-columns: repeat(4, minmax(250px, 1fr));
//   gap: 2rem;
// `;

// const ServiceItem = styled.div`
//   background-color: #f9fafb;
//   padding: 2rem;
//   border-radius: 10px;
//   text-align: center;
// `;

// const ServiceIcon = styled.div`
// margin: auto;
// margin-bottom: 1.5rem; /* Equivalent to mb-6 in Tailwind */
// width: 5rem; /* Equivalent to w-20 in Tailwind */
// height: 5rem; /* Equivalent to h-20 in Tailwind */
// display: flex;
// align-items: center;
// justify-content: center;
// border-radius: 1.5rem; /* Equivalent to rounded-3xl in Tailwind */
// background-color: #bee3f8; /* Equivalent to bg-blue-100 in Tailwind */
// font-size: 2.5rem; /* Equivalent to text-4xl in Tailwind */
// color: #2b6cb0; /* Equivalent to text-blue-500 in Tailwind */

// i {
//   /* If the i tag needs specific styles */
// }
// `;

// const ServiceHeader = styled.h4`
//   font-size: 1.5rem;
//   font-weight: bold;
//   color: #333;
//   margin-bottom: 1rem;
// `;

// const ServiceText = styled.p`
//   font-size: 1.25rem;
//   color: #666;
// `;
// const OverallContainer=styled.div`
// width:100vw;
// height:100vh;
// overflow:scroll;
// display:flex;
// flex-direction:column;
// align-items:center;

// `
// const HeaderContainer = styled.div`
//   /* Example container styles */
//   position: relative;
//   z-index: 10;
//   align-items:center;
//   margin: 0px 10px;
//   padding: 1rem;
//  height:30%px;
//   width:80%px;
//   background-color:#F0F8FF;
//   @media (min-width: 640px) {
//     padding: 1.5rem;
//   }
//   @media (min-width: 1024px) {
//     padding-bottom: 4rem;
//     padding-left: 2rem;
//     padding-right: 2rem;
//   }
//   @media (min-width: 1280px) {
//     padding-left: 4rem;
//     padding-right: 4rem;
//   }
// `;

// const HeaderContent = styled.div`
//   /* Example flex container styles */
//   display: flex;
//   flex-direction: column;
//   height:100%;
//   justify-content: flex-start; /* Align items to the start (left) */
//   @media (min-width: 1024px) {
//     flex-direction: row;
//     align-items: center; /* Align items to the center horizontally */
//   }
// `;

// const HeaderText = styled.div`
//   /* Example text container styles */
//   margin-top: 3rem;
//   width: 100%;
//   @media (min-width: 1024px) {
//     margin-top: 6rem;
//     width: 55%;
//     padding-right: 3rem;
//   }
// `;

// const Heading = styled.div`
//   /* Example heading styles */
  
  
//    margin-bottom:0px;
  
//    p{
//     font-size: 30px;
//     display:flex;
//     margin-bottom: 0px;
//     align-items:flex-start;
//      padding:0px;
//   font-weight: bold;
//   color: #000; /* Example text color */
//   text-align: left; 

//    }
//     /* Ensure text starts from left */
//   @media (min-width: 768px) {
//     font-size: 3.5rem;
//   }
//   @media (min-width: 1024px) {
//     font-size: 4rem;
//   }
// `;

// const BlueHeading = styled(Heading)`
//   /* Example blue heading styles */
  
//     /* Blue color */
//     margin-bottom: 0px;
 
  
 
 
  
// `;
// const Paragraph = styled.div`
//   /* Example paragraph styles */
//   margin: 0; /* Set margin to 0 */
//   font-size: 40px;
//   font-weight: bold;
//   color: #000; /* Example text color */
//   text-align: left;
// `;

// const Subtext = styled.p`
//   /* Example subtext styles */
//   margin-bottom: 2rem;
//   color: #666; /* Example text color */
//   text-align: left; /* Ensure text starts from left */
// `;

// const CTAButton = styled.div`
//   /* Example CTA button styles */
//   text-align: center;
//   @media (min-width: 768px) {
//     text-align: left;
//   }

//   a {
//     display: inline-block;
//     padding: 10px 20px;
//     font-size: 1.5rem;
//     font-weight: bold;
//     color: #fff;
//     background-color: #007bff;
//     border-radius: 5px;
//     text-decoration: none;
//   }

//   a:hover {
//     background-color: #0056b3;
//   }
// `;

// const ImageContainer = styled.div`
//   /* Example image container styles */
//   display: none;
//   height:100%;
//   @media (min-width: 1024px) {
//     display: block;
//     position: relative;
//     margin-top: 3rem;
//     margin-right:20px;
//     width: 45%;
//     padding: 1rem;
//   }
// `;

// const Image = styled.img`
//   /* Example image styles */
//   width: 100%;
//   height:90%;
//   border-radius: 0.5rem;
// `;
// const Section = styled.div`
//   font-family: 'Poppins';
//   /* display:block; */
//   position:relative;
//   margin-right: 80px;
//   margin-left:10px;
//  margin-bottom:150px;
// top:30px;
 
//   height:100%;
//   width:90%;
  
//   /* border:solid 1px black; */
//   /* padding:1rem; */
//   justify-content:flex-start;
//    align-items:flex-start;  
  
  
//   margin-top:50px;
//   /* Add more styles as needed */
// `;

// const WelcomeContainer = styled.div`
//   /* Example container styles */
//   display:flex;
//   position:relative;
//   margin-right:  10px;
//   margin-bottom:1rem;
//   margin-left:0px;
//   padding: 50px ;
//   align-items:center;
//   justify-content: space-between;
 
//   width:100%;
//   height:100%;
//   border-radius:20px;
  
//   background-color: #0047AB;
//   @media (min-width: 640px) {
//     padding: 0 1.5rem;
//   }
//   @media (min-width: 1024px) {
//     padding: 0 2rem;
//   }
//   @media (min-width: 1280px) {
//     padding: 0 4rem;
//   }
// `;
// const StyledDiv = styled.div`
//   position: absolute;
//   left: -30px;
//   top: -30px;
//   height: 300px;
//   width: 300px;
//   border-radius: 50%;
//   border: 50px solid #3b82f6; /* blue-600 */
//   border-color: #3b82f6; /* blue-600 */
//   z-index:1;
//   overflow:hidden;
//   opacity:0.5;
// `;
// const InnerDiv = styled.div`
//   position: absolute;
//   left: -110px;
//   top: -100px;
//   right:100%;
//   bottom:0px;
//   height: 700px;
//   width: 780px;
//   border-radius: 50%;
//   border: 50px solid white; /* white border for the parts outside the container */
//   box-sizing: border-box; /* Ensure the border doesn't affect the dimensions of the element */
// `;

// const CallToAction = styled.div`
//   /* Example call-to-action styles */
//   /* background-color: #3b82f6; Example background color */
//   border-radius: 3xl;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   height: 535px; /* Example height */
//   position: relative;
//   margin-bottom:10px;
// `;

// const CallToActionBackground = styled.div`
//   /* Example call-to-action background styles */
//   flex: 1;
//   display: flex;
//   height:100%;
//   width:50%;
//   border: 1px solid black;
//   margin-bottom:0;
// `;

// const CallToActionContent = styled.div`
//   /* Example call-to-action content styles */
//   margin-top:0.3rem;
//   margin-left:0.6rem;
//   margin-bottom:160px;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   padding: 24px 10px;
  
//   padding-left:8px;
//   color: white;
//   width: 60%;
//   height:70%;
//   z-index:100;
//   @media (min-width: 768px) {
//     width: 50%;
//   }
// `;

// const CallToActionText = styled.div`
//   /* Example call-to-action text styles */
//   text-align: center;
//   font-size:20px;
//   @media (min-width: 768px) {
//     text-align: left;
//   }
// `;

// const Subheader = styled.div`
//   /* Example subheader styles */
//   margin-bottom: 0.5rem;
//   font-size: 1rem;
// `;

// const Header = styled.div`
//   /* Example header styles */
//   margin-bottom: 1rem;
//   font-size: 2.25rem;
//   font-weight: bold;
//   @media (min-width: 768px) {
//     font-size: 2.5rem;
//   }
// `;

// // const Subtext = styled.div`
// //   /* Example subtext styles */
// //   margin-bottom: 2rem;
// //   font-size: 1rem;
// // `;

// const CTAButtonbdy = styled.a`
//   /* Example CTA button styles */
//   display: inline-block;
//   padding: 0.75rem 1.5rem;
//   font-size: 1rem;
//   font-weight: bold;
//   color: #3b82f6; /* Example text color */
//   background-color: white; /* Example background color */
//   border-radius: 9999px;
//   transition: background-color 0.3s ease;
//   &:hover {
//     background-color: #edf2f7; /* Example hover background color */
//   }
// `;
// const SignUpBtn = styled.button`
// margin-left:0px;
// margin-top:35px;
// display:flex;
// justify-content:flex-start;
// cursor:pointer;
// padding: 10px 30px;
// border-radius:10px;
// background-color:#E2725B;
// color:#f9f9f9;
// border:none;
// border-color:#E2725B;
// @media (max-width: 768px) {
//     font-size:10px;
//     padding: 5px 15px;
// }
// `
// const WelcomeContainerRight = styled.div`


// align-items:center;
//  position:relative; 
// margin-bottom:0;
// margin-top:-60px;
// margin-left:40px;
// margin-right:0px;
//    /* position: absolute; */
//   right: 0;
//    bottom: 0;
//    width:50%;
//    /* max-width: calc(100% - 720px - 20px); Adjust based on the width of InnerDiv and any gap */
//   height: calc(100% + 80px); /* Adjust the value as needed */
//   /* z-index: 100; */ 

//   /* border: solid 1px red; */
// `;


// const WelcomeContainerRightBackground = styled.div`
//   position: absolute;
//   right: 5%;
//   top: 30%;
//   transform: translateY(-50%);
//   height: 145px; /* Adjust as needed */
//   width: 150px; /* Adjust as needed */
//   background-image: radial-gradient(#cccccc, transparent 3px);
//   background-size: 16px 16px; /* Adjust as needed */
  
//   @media (min-width: 1440px) {
//     right: -9px; /* Adjust as needed for 2xl */
//     width: 190px; /* Adjust as needed for 2xl */
//   }
// `;

// const WelcomeCOntainerRightImage = styled.img`
//   position: absolute;
//   margin-left:15%;
//   width:80%;
//   bottom:10px;
//   z-index: 20;
//   height: 110%; /* Adjust as needed */
// `;
// // const CodeSectionContainer = styled.div`
//   /* Add your font family here */
// `;

export default News;





