
import styled from "styled-components";
import { numberWithCommas } from "../SharedFns/Functions";
import { exitMember } from "../Features/api/api";
import { useDispatch } from "react-redux";

const ExitForm=({setIsExitModalOpen,  selectedMember, totalUserContributions, balanceSheetData, userCount})=>{
  const dispatch =useDispatch()
    const handleSubmitExit = () => {
        // API call to process the exit of the member
        const exitData = {
          member_id: selectedMember.id,
          non_cleared_loans: selectedMember.loans?.filter(loan => loan.status === 'active' || loan.status === 'default'),
          total_contributions: selectedMember.total_contributions,
        };
        console.log('Processing exit for:', exitData);
        dispatch(exitMember(exitData))
        // Close the modal after submission
        setIsExitModalOpen(false);
      };
      const nonClearedLoans = selectedMember.loans?.filter(loan => loan.status === 'active' || loan.status === 'default');
      const totalNonClearedLoans = nonClearedLoans?.reduce((acc, loan) => acc + loan.current_balance, 0);
      const retainedEarnings = (-1 * balanceSheetData.equities?.retained_earnings.balance).toFixed(2);
      const overallSharedInterest = (retainedEarnings / userCount).toFixed(2);
      const actualSharedInterest = (overallSharedInterest * 0.63);
    return (<ExitFormL>
        <h3>Confirm Exit</h3>
            <p>Please confirm the exit of this member:</p>
            <p><strong>Name:</strong> {`${selectedMember.first_name} ${selectedMember.last_name}`}</p>
            <p><strong>Member ID:</strong> {selectedMember.id}</p>
            <p><strong>Total Contributions:</strong> {totalUserContributions}</p>
           <strong>Non-Cleared Loans:</strong>
            <ul> 
      {nonClearedLoans && nonClearedLoans.length > 0 ? (
        nonClearedLoans?.map((loan, index) => (
          <li key={index}>
            <p><strong>Principal:</strong> {loan.amount}</p>
            <p><strong>Current Balance:</strong> {typeof loan.current_balance === 'number' 
                ? loan.current_balance.toFixed(2) 
                : loan.current_balance}</p>
            <p><strong>Start Date:</strong> {loan.start_date}</p>
            <p><strong>Expiry Date:</strong> {loan.expiry_date}</p>
            <p><strong>Status:</strong> {loan.status}</p>
          </li>
        ))
      ) : (
        <p>No non-cleared loans.</p>
      )}
    </ul>
            <button onClick={handleSubmitExit}>Confirm Exit</button>

    </ExitFormL>)
}

const ExitFormL = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin: 5px 0;
  }
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e2725b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;
export default ExitForm 