export const  removeTimeFromDateString = (dateString) => {
    if (!dateString) {
      return null; // Handle null or undefined input gracefully
    }
  
    // Split the date string at 'T' to separate date and time, then take the first part (date)
    return dateString.split('T')[0];
  };


  export const getRequiredData = (desiredId, data, itemFinder, fieldName) => {
    if (Array.isArray(data) && data.length > 0) {
        const item= data.find(itemFinder(desiredId))
        // const payment = payments.find(payment => payment.payment_id === desiredId)
        if(item){
            return item[fieldName]
        }
        else{
            return "-"
        }
    }
    else {
        return "-"
    }

}
export const getRequiredUserData = (desiredId, data, itemFinder, userFields) => {
  if (Array.isArray(data) && data.length > 0) {
      const item = data.find(itemFinder(desiredId))
      if (item) {

          const userPart = userFields.map(fieldName => item.user[fieldName]).join(' ');


          return userPart



      }

      else {
          return "No payment data found";

      }


  }
  else {
      return "No user"
  }

}
export const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  const DEVELOPMENT = process.env.NODE_ENV !== 'production';
export const baseURL = DEVELOPMENT? "http://localhost:8000" :"https://eye20group.com"