import React, { useRef } from 'react';
import styled from 'styled-components';
import ReactToPrint from 'react-to-print';
import { numberWithCommas } from '../../SharedFns/Functions';
import { useSelector } from 'react-redux';
import Spinner from '../../SharedFns/SpinnerElement';

const UserFinancialDocument = ({ isLoading, users_shared_interest_data, user,totalUserContributions, balanceSheetData, userCount }) => {
  const contentRef = useRef(null);

//   const userContributions = contributions?.filter(contribution => contribution.user === user.username || '');

//   const totalContributions = userContributions.reduce((acc, contribution) => acc + contribution.total_contributions, 0);
  const nonClearedLoans = user.loans?.filter(loan => loan.status === 'active' || loan.status === 'default');
  const totalNonClearedLoans = nonClearedLoans?.reduce((acc, loan) => acc + loan.current_balance, 0);
  const retainedEarnings = (-1 * balanceSheetData.equities?.retained_earnings.balance).toFixed(2);
  const overallSharedInterest = user.has_cleared 
    ? 0 
    : (balanceSheetData?.users_shared_interest_data 
        ? balanceSheetData.users_shared_interest_data 
        : (userCount > 0 ? (parseFloat(retainedEarnings) / userCount) : 0)
      ).toFixed(2)
      const userSharedInterest = users_shared_interest_data?.find(
        (data) => data.user === user.id
      );
      
      // Convert balance to a number safely
      const balance = userSharedInterest ? parseFloat((-1 * userSharedInterest.balance??0.00).toFixed(2)) : 0.00;
      
      // Calculate actual shared interest, ensuring multiplication with numbers
      const actualSharedInterest = userSharedInterest 
        ? numberWithCommas(`KES ${(balance * 0.63).toFixed(2)}`) 
        : numberWithCommas((overallSharedInterest * 0.63).toFixed(2));
      
      // Ensure totalUserContributions and totalNonClearedLoans are numbers
      const totalUserContributionsNum = parseFloat(totalUserContributions) || 0.00;
      const totalNonClearedLoansNum = parseFloat(totalNonClearedLoans) || 0.00;
      
      // Convert actualSharedInterest to a number for the calculation
      const actualSharedInterestNum = userSharedInterest 
        ? balance * 0.63 
        : overallSharedInterest * 0.63;
      
      // Correct calculation of amount due on exit
      const amountDueOnExit = (
        totalUserContributionsNum + actualSharedInterestNum - totalNonClearedLoansNum
      ).toFixed(2);
      // const formattedActualSharedInterest = numberWithCommas(`KES ${actualSharedInterest.toFixed(2)}`);


      // console.log(formattedActualSharedInterest); // Formatted for display

      console.log(amountDueOnExit); // Correctly calculated amount due on exit
    
  return (
    <Wrapper>
      <DocumentContainer ref={contentRef}>
        <Header>
          <Logo src={process.env.PUBLIC_URL + '/media/logo.png'} alt="Logo" />
        </Header>

        <Title>Financial Summary for {user.first_name} {user.last_name}</Title>

        <Section>
          <Introduction>
            This financial document presents the summary of {user.first_name}'s contributions, shared interest, non-cleared loans, and the amount due on exit as per the latest calculations.
          </Introduction>
        </Section>

        <Section>
          <SectionTitle>Total Contributions</SectionTitle>
          <Row>
            <LeftColumn>Total Contributions</LeftColumn>
            <RightColumn>{totalUserContributions && numberWithCommas(`KES ${totalUserContributions}`)}</RightColumn>
          </Row>
        </Section>

        <Section>
          <SectionTitle>Shared Interest</SectionTitle>
          <Row>
            <LeftColumn>Overall Shared Interest</LeftColumn>
          <RightColumn>{userSharedInterest? numberWithCommas(`KES ${balance}`):numberWithCommas(overallSharedInterest)}</RightColumn>
          </Row>
          <Row>
            <LeftColumn>Actual Shared Interest (63% of Overall Interest)</LeftColumn>
            <RightColumn>{userSharedInterest 
    ? numberWithCommas(` ${actualSharedInterest}`) 
    : numberWithCommas((overallSharedInterest*0.63).toFixed(2))
}
</RightColumn>
          </Row>
        </Section>

        <Section>
          <SectionTitle>Non-Cleared Loans</SectionTitle>
          {nonClearedLoans?.map((loan, index) => (
            <Row key={index}>
              <LeftColumn>{loan.loan_name}</LeftColumn>
              <RightColumn>{loan.current_balance && numberWithCommas(`KES ${loan.current_balance}`)}</RightColumn>
            </Row>
          ))}
        </Section>

        <Section>
          <SectionTitle>Amount Due on Exit</SectionTitle>
          <Row>
            <LeftColumn>Amount Due on Exit</LeftColumn>
            <RightColumn>{amountDueOnExit && numberWithCommas(`KES ${(amountDueOnExit)}`)}</RightColumn>
          </Row>
         {amountDueOnExit < 0.00 && <ExitRow>
            <LeftColumn> On Exit, {user.first_name} Owe Eye20 Group an Amount of:</LeftColumn>
            <RightColumn>{amountDueOnExit &&  numberWithCommas(`KES ${(-1*amountDueOnExit).toFixed(2)}`)}</RightColumn>
          </ExitRow>}
        </Section>
      </DocumentContainer>

      <ReactToPrint
        trigger={() => <PrintButton>Print</PrintButton>}
        content={() => contentRef.current}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
`;

const DocumentContainer = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100px; /* Adjust size as needed */
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 10px 0;
  text-align: center;
`;

const Section = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const Introduction = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
`;
const ExitRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  font-weight:bold;
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
  text-align: right;
`;

const PrintButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

export default UserFinancialDocument;
