import { createSlice } from "@reduxjs/toolkit";

import { approveLoanApplication } from "../api/api";


const initialState={
    loading:false,
    error:{},
    loanApplicationApprovalFeedback:null
}
const approveLoanApplicationSlice=createSlice(
    {
        name:'ApproveLoanApplication',
        initialState,
        reducers:{},
        extraReducers: (builder)=>{
            builder.addCase(
                approveLoanApplication.pending, (state)=>{
                     state.loading=true
                     state.error={}
                     state.loanApplicationApprovalFeedback=null
                }
            )
            builder.addCase(
                approveLoanApplication.rejected, (state, action)=>{
                    state.loading=false
                    state.error=action.payload
                    state.loanApplicationApprovalFeedback=null
                }
            )   
            builder.addCase(
                approveLoanApplication.fulfilled, (state, action)=>{
                    state.loading=false
                    state.error={}
                    state.loanApplicationApprovalFeedback=action.payload
                }
            )
        }
    }
)
export default approveLoanApplicationSlice.reducer