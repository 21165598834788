
import { getUser } from "../api/api";
import { createSlice } from "@reduxjs/toolkit";

const initialState= {
    loading:true,
    user:null,
    error:{},
    isMenuToggled:false,
    userIsAuthenticated:false
}

const userDataSlice=createSlice(
    { name:'userData',
    initialState,

    reducers:{
        setLogoutState: (state)=>{
            state.loading=false
            state.user=null
            state.error={}
            state.userIsAuthenticated=false
        
        },
       setIsMenuToggled:(state)=>{

        state.loading=false
          state.isMenuToggled=!state.isMenuToggled
            state.error={}
       },
      setUserIsAuthenticated: (state, action) =>{
       state.userIsAuthenticated=action.payload

       }

    },
    extraReducers:
        (builder)=>{
        builder.addCase(getUser.pending, (state)=>{
            state.loading=true
            state.user=null
            state.error={}
            state.userIsAuthenticated=false
        })
        builder.addCase(getUser.rejected, (state, action)=>{
            state.loading=false
            state.user=null
            state.error= action.error.message
            state.userIsAuthenticated=false
        })
        builder.addCase(getUser.fulfilled, (state, action)=>{
            state.loading=false
            state.error={}
            state.user=action.payload
            state.userIsAuthenticated=true
        })
    }

    }
)

export const{ setLogoutState, setUserIsAuthenticated, setIsMenuToggled}=userDataSlice.actions

export default userDataSlice.reducer