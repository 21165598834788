import {configureStore} from '@reduxjs/toolkit'
import authReducer from  '../Features/User/authSlice'
import activationReducer from '../Features/User/activationSlice'
import signInReducer from '../Features/User/signInSlice'
import userDataReducer from '../Features/User/userDataSlice'
import getLoansReducer from '../Features/Finances/getLoansSlice'
import loanApplicationReducer from '../Features/Finances/loanApplicationSlice'
import payLoanReducer from '../Features/Finances/LoanRepaymentSlice'
import getLoanPaymentsReducer from '../Features/Finances/getLoanPaymentsSlice'
import getLoanPaymentsApprovalReducer from'../Features/Finances/getLoanApprovalPayments'
import approveLoanPaymentReducer from '../Features/Finances/approveLoanPayementSlice'
import getLoanApplicationSliceReducer from '../Features/Finances/getLoanApplicationSlice'
import approveLoanApplicationSliceReducer from '../Features/Finances/approveLoanApplicationSlice'
import  getContributionsReducer from '../Features/Finances/getContributionsSlice'
import payContributionReducer from '../Features/Finances/PayContributionSlice'
import approveMonthlyContributionSliceReducer from '../Features/Finances/approveMonthlyContributionSlice'
import getContributionsToApproveReducer from '../Features/Finances/getContributionsToApproveSlice'
import getTrialBalanceDataReducer from '../Features/Accounting/trialBalanceSlice'
import getBalanceSheetReducer from '../Features/Accounting/balanceSheetSlice'
import getProfitAndLossReducer from '../Features/Accounting/profitAndLossSlice'
import createLedgerReducer from '../Features/Accounting/createLedgerSlice'
import getDeclarationsReducer from '../Features/Accounting/getDeclarationsSlice'
import getUsersReducer from '../Features/User/usersSlice'
import deactivateUserReducer from '../Features/User/deactivateUserSlice'
import adminManageUserStatusReducer from '../Features/User/adminManageUserStatusSlice'
import assignUserRoleReducer from '../Features/User/assignUserRoleSlice'
import removeUserRoleReducer from '../Features/User/removeUserRoleSlice'
import activeUserCountReducer from '../Features/User/activeUserCountSlice'
import  fetchAllContributionsReducer from '../Features/Finances/getAllContriutionsSlice' 
import  exitMemberReducer from '../Features/Accounting/exitMemberSlice'
import clearMemberReducer from '../Features/Accounting/clearMemberSlice'

const store =configureStore({
    reducer:{
        auth:authReducer,
        activate:activationReducer,
        signIn: signInReducer,
        userData:userDataReducer,
        getLoans:getLoansReducer,
        loanApplication:loanApplicationReducer,
        loanPayment:payLoanReducer,
        getLoanPayments:getLoanPaymentsReducer,
        getLoanPaymentsToApprove:getLoanPaymentsApprovalReducer,
        approveLoanPayment:approveLoanPaymentReducer,
        getLoanApplication:getLoanApplicationSliceReducer,
        ApproveLoanApplication:approveLoanApplicationSliceReducer,
        getContributions:getContributionsReducer,
        payContribution:payContributionReducer,
        approveContribution:approveMonthlyContributionSliceReducer,
        getContributionsToApprove:getContributionsToApproveReducer,
        getLedgers:getTrialBalanceDataReducer,
        getbalanceSheet:getBalanceSheetReducer,
        getProfitAndLoss:getProfitAndLossReducer,
        createLedger:createLedgerReducer,
        getDeclarations:getDeclarationsReducer,
        getUsers:getUsersReducer,
        deactivateUser:deactivateUserReducer,
        adminManageUserStatus:adminManageUserStatusReducer,
        assignUserRole:assignUserRoleReducer,
        removeUserRole:removeUserRoleReducer,
        activeUsersCount:activeUserCountReducer,
        getAllContributions:fetchAllContributionsReducer,
        exitMember:exitMemberReducer,
        clearMember:clearMemberReducer,



    },
    
})

export default store