import { createSlice } from "@reduxjs/toolkit";

import { applyLoan } from "../api/api";


const initialState={
    loading:false,
    loanFeedback:null,
    error:{}
}

const loanApplicationSlice=createSlice(
    {
        name: 'loanApplication', 
        initialState, 
        reducers:{

        },
        extraReducers: (builder)=>{
            builder.addCase(
                applyLoan.pending, (state)=>{
                    state.loading=true
                    state.error={}
                     state.loanFeedback=null   
                    })
            builder.addCase(
                applyLoan.rejected, (state, action)=>{
                    state.loading=false
                    state.error=action.error.message
                    state.loanFeedback=null
                }

            )
            builder.addCase(
                applyLoan.fulfilled, (state, action)=>{
                    state.loading=false
                    state.error={}
                    state.loanFeedback=action.payload
                }

            )        
                
                }
           
        
    }
)
export default loanApplicationSlice.reducer