import { createSlice } from "@reduxjs/toolkit";
import { payLoan } from "../api/api";



const initialState={
    loading:false, 
    loanPaymentFeedBack:null,
    error:{}

}



const payLoanSlice=createSlice(
{
    name:'loanPayment',
    initialState, 
    reducers:{},

    extraReducers:(builder)=>{
        builder.addCase(
          payLoan.pending, (state)=>{
            state.loading=true
            state.error={}
            state.loanPaymentFeedBack=null
          }  
        )
        builder.addCase(
           payLoan.rejected, (state, action)=>{
            state.loading=false
            state.error=action.error.message
            state.loanPaymentFeedBack=null
           }
        )
        builder.addCase(
            payLoan.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.loanPaymentFeedBack=action.payload
            }
        )
    }
}
) 
export default payLoanSlice.reducer