import axios from "axios";

const DEVELOPMENT = process.env.NODE_ENV !== 'production';
const baseURL = DEVELOPMENT? "http://localhost:8000" :"https://eye20group.com"
const API = axios.create({ baseURL:baseURL  });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("session_cookie")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem(
      "session_cookie"
    )}`;
    req.headers.Accept = "application/json";
    req.headers['X-CSRFToken'] = getCookie('csrftoken');
  }
  return req;
});
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export default API;