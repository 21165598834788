import { createSlice } from "@reduxjs/toolkit";

import { approveLoanPayment } from "../api/api";


const initialState={
    loading:false,
    error:{},
    approvalResponse:null
}


const approveLoanPaymentSlice=createSlice({
    name:"approveLoanPayment", 
    initialState, 
    reducers:{

    }
    ,
    extraReducers: builder=>{
        builder.addCase(
            approveLoanPayment.pending, (state)=>{
                state.loading=true
                state.error={}
                state.approvalResponse=null
            }
        )
        builder.addCase(
            approveLoanPayment.rejected, (state, action)=>{
                state.loading=false
                state.error=action.payload
                state.approvalResponse=null

            }
        ) 
        builder.addCase(
            approveLoanPayment.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.approvalResponse=action.payload
            }
        )
    }
}

   


)
export default approveLoanPaymentSlice.reducer