import { createSlice } from "@reduxjs/toolkit";
import { exitMember } from "../api/api";

const initialState = {
  loading: false,
  error: {},
  Response: [],
};

const exitMemberSlice = createSlice({
  name: 'exitMember',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      exitMember.pending, // <- Correct action type
      (state) => {
        state.loading = true;
        state.error = {};
        state.Response= [];
      }
    );
    builder.addCase(
      exitMember.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.Response = [];
      }
    );
    builder.addCase(
      exitMember.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = {};
        state.Response = action.payload;
      }
    );
  },
});

export default exitMemberSlice.reducer;
