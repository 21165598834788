import { createSlice } from "@reduxjs/toolkit"

import { activateAccount } from "../api/api"


const initialState={
   loading:false,
   error:{}, 
   user: null

}

const activationSlice= createSlice({
    name:'activate',
     initialState,

     reducers:{

     },

     extraReducers: (builder)=>{
        builder.addCase( activateAccount.pending, (state)=>{
            state.loading = true
            state.error={}
            state.user =null

        })

        builder.addCase( activateAccount.rejected, (state, action)=>{
            state.loading=false
            state.error= action.payload?.errors
            state.user=null
        }

        )
        builder.addCase( activateAccount.fulfilled, (state, action)=>{
            state.loading=false
            state.error={}
            state.user= action.payload
        }
            )


     }

})

export default activationSlice.reducer