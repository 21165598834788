import { useDispatch, useSelector } from "react-redux"
import { getRequiredData, getRequiredUserData } from "../SharedFns/Functions"
import { useEffect, useState } from "react"
import { fetchLoanApplications } from "../Features/api/api"
import Modal from "../SharedFns/Modal"
import styled from "styled-components"
import LoanApplicationApprovalForm from "./LoanApplicationApprovalForm"
import Spinner from "../SharedFns/SpinnerElement"

const LoanApplicationApprovals=()=>{
    const dispatch=useDispatch()
    const pendingLoanApplications=useSelector( state=> state.getLoanApplication.loanApplications)
    const isLoading =useSelector(state=>state.ApproveLoanApplication?.loading)
    const user_group=useSelector(state=>state.userData.user.user.user_groups)??[]
    const requiredUserFields = ["username", "first_name", "last_name"]
    const [isModalOpen, setIsModalOpen]=useState(false)
    const [selectedLoanId, setSelectedLoanId]=useState(null)
    const loansToApprove= pendingLoanApplications?.filter(loan=> loan.approval?.approved_official === null || loan.approval?.approved_official !== user_group[0])??[]
    const HandlePayLoanClick = (LoanID) => {
        setSelectedLoanId(LoanID)
        setIsModalOpen(true)
        

    }
    const aprovalCodeToName={

        'pending_approval': 'PENDING APPROVAL',
        'rejected':'REJECTED',
        'approved':'APPROVED',
        'pending':'PENDING',
        'cleared':'CLEARED',
        'PENDING_APPROVAL':'PENDING APPROVAL',
        'emergency_loan':'Emergency Loan',
        'normal_loan':'Normal Loan',
        'active':'ACTIVE',
        'default':'DEFAULT'
    }
    const loanFinder=(desiredId)=>(loan)=>loan.loan_id===desiredId
   
    return(
        <Container>
            <div>
                {isLoading && <Spinner/>}
            </div>
            <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
               {<LoanApplicationApprovalForm LoanId={selectedLoanId} setIsModalOpen={setIsModalOpen}/>}
            </Modal> 
       {loansToApprove?.length !==0 && <h2> Pending Loan Applications</h2> }  
        {loansToApprove?.length !==0 && <p>Below is pending loan applications waiting your actions</p> }{
            Array.isArray(pendingLoanApplications) && pendingLoanApplications.length > 0 ?

                <Table>
                    <thead>
                        <tr>
                            <TableHeader>
                                Member's Names
                            </TableHeader>
                            <TableHeader>
                                Loan Application ID
                            </TableHeader>
                            <TableHeader>
                                Application Date
                            </TableHeader>
                            <TableHeader>Amount Requested</TableHeader>
                            <TableHeader>
                                Loan Type
                            </TableHeader>
                            <TableHeader>
                                Action
                            </TableHeader>


                        </tr>
                    </thead>
                    <tbody>
                        {loansToApprove.map(
                            loan => (
                                <tr key={loan.loan_id}>

                                    {/* <TableCell>{payment.approval && payment.approval.approved_official?payment.approval.approved_official:'--'}</TableCell> */}
                                    <TableCell>{getRequiredUserData(loan.loan_id, loansToApprove, loanFinder,requiredUserFields)}</TableCell>
                                    <TableCell>{loan.loan_id}</TableCell>
                                    <TableCell>{getRequiredData(loan.loan_id, loansToApprove, loanFinder, 'start_date')}</TableCell>
                                    <TableCell>{getRequiredData(loan.loan_id, loansToApprove, loanFinder,'amount')}</TableCell>
                                    <TableCell>{aprovalCodeToName[getRequiredData(loan.loan_id, loansToApprove, loanFinder, 'loan_type')]}</TableCell>


                                    <TableCell>
                                        <Button onClick={() => HandlePayLoanClick(loan.loan_id)}>
                                            Approve/Reject
                                        </Button>
                                    </TableCell>

                                </tr>
                            )
                        )

                        }

                    </tbody>
                </Table> : <NoDataInfo>
                <p>No pending loan applications available at the moment</p>
                    </NoDataInfo>}
    </Container>

)
                    }


const Container = styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
overflow-y:scroll;
`
const Table = styled.table`
border-collapse:collapse;
width:100%;

margin-bottom:100px;
`
const TableHeader = styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;

`
const NoDataInfo=styled.div`
display: flex;
align-items:center;
margin-top:70px;
padding:10px;
border-bottom: solid 1px #E2725B;
border-top: solid 1px rgba(226, 114, 91, 0.5);

`
const TableCell = styled.td`
border: 1px solid #000;
padding: 10px;
text-align: left;
@media (max-width:768px) {
width:12px;
font-size:10px;
&.hide-on-small-screen {
display: none; // hide this column on small screens
}

}
`
const Button = styled.button`
cursor:pointer;
align-items:center;
margin-left:20px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
`



export default LoanApplicationApprovals