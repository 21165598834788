import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { removeTimeFromDateString } from "../SharedFns/Functions"
import { Link, Outlet, useNavigate, useLocation, Navigate } from "react-router-dom"
import { fetchMonthlyContributions, fetchLoanApplications, fetchLoans } from "../Features/api/api"
import Modal from "../SharedFns/Modal"
import OutletModal from "../SharedFns/OutletModal"
import ContributionPaymentForm from "./ContributionPaymentForm"

import { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'



const LoanApplications = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchLoans())
    }, [dispatch]

    )
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showApprovalDetails, setShowApprovalDetails] = useState(false)
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const pendingLoans = useSelector( state => state.getLoans.loans?.pending_loans) ?? []
    const aprovalCodeToName={

        'pending_approval': 'PENDING APPROVAL',
        'rejected':'REJECTED',
        'approved':'APPROVED',
        'pending':'PENDING',
        'PENDING_APPROVAL':'PENDING APPROVAL',
        'emergency_loan':'Emergency Loan',
        'normal_loan':'Normal Loan'
    }
  
    const [isModalOpen, setIsModalOpen] = useState(false)
    // const filteredContributions = (Array.isArray(contributions)) ? contributions.filter(contribution => {
    //     const contributionMonth = new Date(contribution.contribution_month)
    //     return contributionMonth

    // }).sort((a, b) => {
    //     return new Date(b.contribution_month) - new Date(a.contribution_month)
    // }) : []

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const handleEndDateChange = (date) => {
        setEndDate(date)
    }
    const getApprovalStatus = (official, payment) => {
        const { status, approval } = payment
        if (approval) {
            if (approval.approved_official === official) {
                // return approval?.second_official_group_action 
                 return approval.second_official_group_action === "pending_approval" ? approval.first_official_group_action : approval.second_official_group_action;
            }
            else if (approval.approved_official !== official) {
                // return approval?.first_official_group_action 
                 return approval.second_official_group_action !== "pending_approval" ? approval.first_official_group_action : approval.second_official_group_action
            }


        }

        else {
            return 'PENDING_APPROVAL'
        }



    }
    const handlePaymentClick = (payment) => {
        setSelectedPayment(payment)
        setShowApprovalDetails(true)
    }
    const handleFilterButtonClick = () => {
        try {
            if (!Array.isArray(pendingLoans)) {
                throw new Error(' You have not made any loan payments yet.')
            }
            if (startDate > endDate) {
                throw new Error('End Date must be a date later than Start Date');
            }



const sortedApplications = pendingLoans.filter(loan => {
    // Convert the payment date string to a Date object
    const applicationDate = new Date(loan.application_date);

    // Check if the payment date falls within the specified date range
    return applicationDate >= startDate && applicationDate <= endDate;
}).sort((a, b) => {
    // Convert payment dates to Date objects
    const dateA = new Date(a.application_date);
    const dateB = new Date(b.application_date);

    // Compare payment dates
    return dateB - dateA;
});;


// Sort filtered contributions by contribution month in descending order
// const sortedContributions = filteredContributions.sort((a, b) => {
//     // Extract the contribution month and group year from the contribution objects
//     const contributionMonthA = new Date(a.contribution_month);
//     const contributionMonthB = new Date(b.contribution_month);

//     // Adjust the month for group year consideration (starting from September)
//     const adjustedMonthA = contributionMonthA.getMonth() < 8 ? contributionMonthA.getMonth() + 4 : contributionMonthA.getMonth() - 8;
//     const adjustedMonthB = contributionMonthB.getMonth() < 8 ? contributionMonthB.getMonth() + 4 : contributionMonthB.getMonth() - 8;

//     // Sort contributions in descending order based on the adjusted contribution month
//     return adjustedMonthB - adjustedMonthA || contributionMonthB - contributionMonthA;
// });




setFilteredTransactions(sortedApplications);
        } catch (error) {
            alert(error.message);
            setEndDate(null);
        }
    };

    const handlePayClick = () => {
        setIsModalOpen(true)
    }
    const getRejectReason = (official, payment) => {
        const { approval } = payment;
        if (approval) {
            if (approval.approved_official === official) {
                return approval.second_official_group_reject_reason? approval.second_official_group_reject_reason: approval.first_official_group_reject_reason
            } else {
                return approval.first_official_group_reject_reason;
            }
        }
    };
    
    const handleRejectionReasonClick = (reject_reason) => {
        // Check if the contribution has a rejection reason
        if (reject_reason) {
            // Show a modal or alert with the rejection reason
            alert(reject_reason);
        } else {
            // If there's no rejection reason, display a message
            alert("No rejection reason available");
        }
    }
    return (
        <Container>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {<ContributionPaymentForm setIsModalOpen={setIsModalOpen} />}
            </Modal>
            <Header>
            <h3> Your Pending/Rejected Loan Applicactions </h3>
            
            </Header>
            {/* <TransactionFilter>
                <Pickers>
                    <DatePickerContainer>
                        <label htmlFor='StartDate'>Select Start Date:</label>
                        <DatePicker

                            id='StartDate'
                            selected={startDate}
                            onChange={handleStartDateChange}
                            dateFormat='yyyy-MM-dd'
                            className='custom-datepicker'
                            showYearDropdown
                            scrollableYearDropdown
                            required
                        />
                    </DatePickerContainer>

                    <DatePickerContainer>
                        <label htmlFor='EndDate'>Select End Date:</label>
                        <DatePicker
                            id='EndDate'

                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat='yyyy-MM-dd'
                            className='custom-datepicker'
                            showYearDropdown
                            scrollableYearDropdown
                            required
                        />
                    </DatePickerContainer>

                    <Button onClick={handleFilterButtonClick}>Filter Loans</Button>
                </Pickers>
            </TransactionFilter> */}
{Array.isArray(pendingLoans) && pendingLoans.length > 0 ? <Table>
                <thead>
                    <tr>
                        <TableHeader>
                           Loan Name
                        </TableHeader>
                        <TableHeader>
                            Loan Type
                        </TableHeader>
                        <TableHeader>
                            Application Date
                        </TableHeader>
                        <TableHeader>
                            Total Amount Requested
                        </TableHeader>
                        {
                            !isSmallScreen &&
                            <>
                                <TableHeader>
                                    Treasurer's Approval Status
                                </TableHeader>
                                <TableHeader>
                                    Sec-Gen's Approval Status
                                </TableHeader>
                            </>

                        }
                        <TableHeader>

                            Approval Status
                        </TableHeader>
                        
                            {!isSmallScreen && <TableHeader>
                                Approval Date </TableHeader>}
                          
                        {!isSmallScreen &&
                            <TableHeader>
                            Expiry Date
                         </TableHeader>
                        }
                        

                    </tr>
                </thead>

                <tbody>
                    {Array.isArray(pendingLoans) && pendingLoans?.map(
                        loan => (
                            <tr key={loan.loan_id}>

                                {/* <TableCell>{payment.approval && payment.approval.approved_official?payment.approval.approved_official:'--'}</TableCell> */}
                                <TableCell>{loan.loan_name}</TableCell>
                                <TableCell>{aprovalCodeToName[loan.loan_type]}</TableCell>
                                <TableCell>{removeTimeFromDateString(loan.application_date)}</TableCell>
                                <TableCell>{loan.total_amount}</TableCell>
                                {!isSmallScreen &&
                                    <>
                                        <TableCell> {getApprovalStatus('Treasurer', loan) === 'rejected' && loan.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>loan && loan.approval && handleRejectionReasonClick(getRejectReason('Treasurer', loan))}>
                                              {aprovalCodeToName[getApprovalStatus('Treasurer', loan)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Treasurer', loan)]
                                      )}</TableCell>
                                              <TableCell> {getApprovalStatus('Sec-Gen', loan) === 'rejected' && loan.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() => loan && loan.approval && handleRejectionReasonClick(getRejectReason('Sec-Gen', loan))}>
                                              {aprovalCodeToName[getApprovalStatus('Sec-Gen', loan)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Sec-Gen', loan)]
                                      )}</TableCell>
                                    </>}
                                <TableCell>
                                    {isSmallScreen ? <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() => handlePaymentClick(loan)}>view</button> : aprovalCodeToName[loan.status]}
                                </TableCell>
                                 {!isSmallScreen && <TableCell>{(loan.approval ? removeTimeFromDateString(loan.approval.approval_date) : "Approval not complete")}</TableCell>}
                               {
                                 !isSmallScreen && <TableCell>
                                 {removeTimeFromDateString(loan.expiry_date)}
                             </TableCell>
                               } 
                                


                            </tr>
                        )
                    )

                    }

                </tbody>
            </Table> :<p> No transactions available for the selected period</p>}
            
            {

                isSmallScreen && (
                    <OutletModal isOpen={showApprovalDetails} onClose={() => setShowApprovalDetails(false)}>
                        {selectedPayment &&
                            <PopTable>
                                <Table>
                                    <thead>
                                        <tr> <TableHeader>
                                            Sec-Gen's Approval Status
                                        </TableHeader>
                                            <TableHeader>
                                                Treasurer's Approval Status
                                            </TableHeader>
                                            <TableHeader>
                                                Overall Approval Status
                                            </TableHeader>
                                            <TableHeader>
                                                Approval Date
                                            </TableHeader>

                                        </tr>
                                    </thead>
                                    < tbody>
                                        <tr>
                                        <TableCell> {getApprovalStatus('Sec-Gen', selectedPayment) === 'rejected' && selectedPayment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>selectedPayment && selectedPayment.approval && handleRejectionReasonClick(getRejectReason('Sec-Gen', selectedPayment))}>
                                              {aprovalCodeToName[getApprovalStatus('Sec-Gen', selectedPayment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Sec-Gen', selectedPayment)]
                                      )}</TableCell>  
                                        <TableCell> {getApprovalStatus('Treasurer', selectedPayment) === 'rejected' && selectedPayment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>selectedPayment && selectedPayment.approval && handleRejectionReasonClick(getRejectReason('Treasurer', selectedPayment))}>
                                              {aprovalCodeToName[getApprovalStatus('Treasurer', selectedPayment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Treasurer', selectedPayment)]
                                      )}</TableCell>
                                             
                                            <TableCell>
                                                {aprovalCodeToName[selectedPayment.status]}
                                            </TableCell>
                                            <TableCell>
                                            {selectedPayment.approval ? removeTimeFromDateString(selectedPayment.approval.approval_date) : "Approval not complete"}
                                            </TableCell>

                                        </tr>

                                    </tbody>

                                </Table>
                            </PopTable>
                        }

                    </OutletModal>)
            }

        </Container>
    )

}

const Container = styled.div`
                height: auto;
                padding: 20px;
                width:100%;
                @media (max-width: 768px) {
                padding:  10px 20px;
                
                }
                `;
const Table = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin-bottom: 100px;
  overflow-x:scroll;

  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    padding-right:50px;
    margin-right:10px;
  }
`;

const TableHeader = styled.th`
  border: solid 1px #000;
  background-color: #f2f2f2;
  padding: 10px;
  font-size: 12px;

  @media (max-width: 768px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:9px;
  }
`;

const Pickers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start of the cross axis */
  justify-content: space-between; /* Distribute items along the main axis */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

const DatePickerContainer = styled.div`
  width: 30%; /* Set width for the date picker container */
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%; /* Make date picker full-width on small screens */
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
cursor: pointer;
align-items: center;
text-decoration: none;
display: flex;
justify-content: center;
cursor: pointer;
background-color: #e2725b;
color: #f9f9f9;
border: none;
border-color: #e2725b;
margin-top: 17px;
border-radius:10px;

@media (max-width: 768px) {
  width: calc(50% - 5px); /* Set width based on the date pickers' width */
  margin-top: 10px; /* Adjust spacing on small screens */
  border-radius:10px;
}
`;

const TransactionFilter = styled.div`
 display: flex;
 width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px; /* Adjust the vertical gap between pickers and table on larger screens */

  @media (max-width: 768px) {
    margin-bottom: 5px; /* Adjust the vertical gap between pickers and table on small screens */
    flex-direction: column;
    width:100%;
  }
`;

const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width: 768px) {
    width: 8px;
    height: 10%;
    font-size: 10px;
    padding:5px; 
    &.hide-on-small-screen {
      display: none; // hide this column on small screens
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  height: 70px;
  margin-left: 20px;
  border-bottom: solid 1px gray;
`;

const Linkto = styled(Link)`
  cursor: pointer;
  align-items: center;
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #e2725b;
  color: #f9f9f9;
  border: none;
  border-color: #e2725b;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
  /* border-bottom: solid 1px gray;
  border-top: solid 1px gray; */
  margin-top: 5px;
  box-sizing: border-box; /
  h3 {
    margin-left: 0px;
    align-items: flex-start;
    display: flex;
    
  
  }
  @media (max-width: 768px) {
   
    overflow-x: scroll;
    overflow-y:hidden;
    margin-top:5px;
    height: 5vh;
    width:145%;
    margin-left: 2px;
    padding-left:0px;
    h3 {
      font-size: 12px;
      padding-top:5px;
      width:50%;
      white-space: nowrap; /* Prevent text from wrapping */
    }
   }
`;

// const Button = styled.button`
//   cursor: pointer;
//   align-items: center;
//   margin-left: 20px;
//   text-decoration: none;
//   display: flex;
//   justify-content: flex-start;
//   cursor: pointer;
//   background-color: #e2725b;
//   color: #f9f9f9;
//   border: none;
//   border-color: #e2725b;
//   margin-right: 20px;
//   margin-bottom: 20px;
//   @media (max-width: 768px) {
//     height: 30px;
//     width: 100%; /* Make the button full-width on small screens */
//     font-size: 7px;
//     margin-left: 0;
//     margin-right: 0;
//   }
// `;

const PopTable = styled.div`
  margin-left: 10px;
  overflow-x: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    margin-top:10px;
  }
`;


export default LoanApplications