import { createSlice } from "@reduxjs/toolkit";

import { fetchLedgerBalances } from "../api/api";


const initialState={
    loading:false,
    error:{},
    ledgerBalances: null
}

const getTrialBalanceData=createSlice(
{  
    name:'getLedgers',
    initialState,

    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(
            fetchLedgerBalances.pending, (state)=>{
                state.loading=true
                state.error={}
                state.ledgerBalances=null
            }
        )
        builder.addCase(
            fetchLedgerBalances.rejected, (state, action)=>{
                state.loading=false
                state.error=action.payload
                state.ledgerBalances=null
            }

        )
        builder.addCase(
            fetchLedgerBalances.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.ledgerBalances=action.payload
            }
        )
    }

}
    

)

export default getTrialBalanceData.reducer

