import {styled} from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {  setIsMenuToggled, setLogoutState } from "../Features/User/userDataSlice"
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../SharedFns/SpinnerElement'
import { faSignOutAlt, facoins, faUsers,faHome,c, faNewspaper, faMoneyBillAlt, faHandsBubbles, faMoneyBill1Wave, faMoneyBillTransfer, faExchangeAlt, faHandHoldingDollar, faPersonCircleCheck, faCoins} from '@fortawesome/free-solid-svg-icons';



const DashboardLayout=({children})=>{
const username = useSelector(state => state.userData.user?.user.username)
const loading = useSelector(state => state.userData.loading)
const user_group=useSelector(state=>state.userData?.user?.user?.user_groups)??[]
const isAuthorizedAdmin = user_group.includes('Chairperson');
const isAuthorizedApprover = user_group.includes('Sec-Gen') || user_group.includes('Treasurer');

const ismenutoggled = useSelector( state=> state.userData.isMenuToggled)
const isToggled=true
const [isDropDownToggled, setIsDropDownToggled]=useState(false)
const dispatch=useDispatch() 
const navigate=useNavigate() 

const handleToggleClick=()=>{
   dispatch(setIsMenuToggled())
  
}
const handleDropDownToggle= (e)=>{
    e.preventDefault()
    setIsDropDownToggled(!isDropDownToggled)
}
const SignOuthandleClick= async()=>{
    try {
     await localStorage.clear()
     await    dispatch(setLogoutState())
           
    
    
     navigate('/')
    } catch (error) {
        console.log(error)
    }
    
}

    return(
          <Section>
              <div>
        {loading && <Spinner/>
      }
      </div>
  <Header>
  <TogglerContainer>
<Button onClick={handleToggleClick}>
 <HumbergerLine/>

 <HumbergerLine/>
 <HumbergerLine/>
 
</Button> { ismenutoggled && <LogoName>
     {/* <img src="/images/logo.svg" alt=""/> */}
 Eye20 Group
</LogoName>}

</TogglerContainer>
    </Header>         

    <Container ismenutoggled={ismenutoggled}> 
    <NavMenu ismenutoggled={ismenutoggled}>



{/* <Logo>
{isMenuToggled && `Logo`}
</Logo>   */}

<IconContainer to= '/dashboard'>
    <Icon icon= {faHome}/>        
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Home</IconText> : <IconText className='icon-text'>Home</IconText>}

</IconContainer>
{ isAuthorizedAdmin &&
<IconContainer to= 'admin'>
    <Icon icon= {faUsers}/>        
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Admin</IconText> : <IconText className='icon-text'>Admin</IconText>}

</IconContainer>}





<IconContainer to="news" >
    <Icon icon= {faNewspaper}/>  
    {ismenutoggled?         
    <IconText style={{opacity:'1'}} >News</IconText>: <IconText className='icon-text'>News</IconText>}

</IconContainer>


<IconContainer to= 'transactions' >
    <Icon icon= {faMoneyBillTransfer}/>   
    {ismenutoggled ?       

    <IconText style={{opacity:'1'}} >Transactions</IconText> : <IconText className='icon-text'>Transactions</IconText>}    
   {/* <DropDown className='dropdown'>
   <Option to='transactions'>Loan Transactions</Option>
        <Option>Contribution Transactions</Option>
   </DropDown> */}

</IconContainer >
{ isAuthorizedApprover &&
<IconContainer to='approvals'>
    <Icon icon= {faPersonCircleCheck}/>   
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Approvals</IconText> : <IconText className='icon-text'>Approvals</IconText>}    
   

</IconContainer >}
<IconContainer to='contributions'>
    <Icon icon= {faHandHoldingDollar}/>   
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Contributions</IconText> : <IconText className='icon-text'>Contributions</IconText>}     
   

</IconContainer >
<IconContainer to='loans'>
    <Icon icon= {faMoneyBillAlt}/>   
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Loans</IconText> : <IconText className='icon-text'>Loans</IconText>}     
   

</IconContainer >

<IconContainer to= 'accounting' >
    <Icon icon= {faCoins}/>   
    {ismenutoggled ?       
    
    <IconText style={{opacity:'1'}} >Accounting</IconText> : <IconText className='icon-text'>Accounting</IconText>}    
   {/* <DropDown className='dropdown'>
   <Option to='trial-balance'> Trial Balance</Option>
        <Option>Contribution Transactions</Option>
   </DropDown> */}
   </IconContainer>
   <IconContainer to='financial-summary'>
   
    <Icon icon= {faExchangeAlt}/>   
       
   {ismenutoggled ?       
   <IconText style={{opacity:'1'}} >Finacial Summary</IconText> : <IconText className='icon-text'>Financial Summary</IconText>}     
  

</IconContainer >
 <IconContainer onClick={SignOuthandleClick}>
    
    <Icon icon={faSignOutAlt} />
    {ismenutoggled ?       
    <IconText style={{opacity:'1'}} >Sign Out</IconText> : <IconText className='icon-text'>Sign Out</IconText>}  
   
   
    </IconContainer>  
    
</NavMenu>
<Main >
   <Outlet/>
 </Main>
     </Container> 
            


          </Section>
        
         
    )
}
const Section=styled.section`
height:100vh;
width:100vw;

overflow:hidden;
display: flex;
flex-direction: row;
justify-content:space-between;
background-color:#f9f9f9;

h1{
    margin-left: auto;
    margin-right: auto;
    text-align:center;
    font-size: 20px;
    margin-top: 70px;
}

`
const Container=styled.div`
height:100vh;
display:flex;
flex-direction: row;
overflow:hidden;
width: 100vw;
transition: width cubic-bezier(0.39, 0.575, 0.565, 1);
width:${props => props.ismenutoggled? '100%': '110%'};
`

const NavMenu= styled.div`
width: ${props => props.ismenutoggled? '15%': '100px'};
transition: width 0.3s ease;
margin-top:70px;
height:100%;
z-index: 3;
background-color: #f5f5f5  ; /*rgb(235, 245, 248)#034694 #f8f8ff  #99FFFF*/
border-right: solid 1px #E2725B;
display:flex;
flex-direction:column;
box-shadow:rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;
margin-top:70px;
@media (max-width: 768px) {
width: ${props => props.ismenutoggled? '120px': '50px'};
transition: width 0.3s ease; 
}
`


 
 
const Button =styled.button`
border-radius:60%;
width: 35px;
height:35px;

margin-top:10px;
margin-bottom:50px;
cursor:pointer;
border: solid 1px rgb(235, 236, 250);
display:flex;
flex-direction:column;
justify-content:space-between;
align-items: center;
padding: 10px;
transition: background-color 0.3s ease;
background-color:#034694;
&:hover{
    /* background-color:#034694;  */
    background-color:#E2725B;
    border:none;
}
`
const HumbergerLine=styled.div`
width: 15px;
height: 3px;
background-color:#f9f9f9;
`
// const SignOut =styled(FontAwesomeIcon)`
// font-size: 20px; /* Adjust the size as needed */
//   color: #E2725B; /* #333;#034694 Icon color */
//   cursor: pointer
// `
// const SignOutText=styled.span`
// margin-left: 5px;
// font-size :15px;
// `
// const SignOutContainer=styled.button`
// display: flex;
// align-items: center;
// margin-left: 5px;
// cursor: pointer;
// margin-bottom: 10px;
// border:none;
// background-color:#f5f5f5;
// margin-top: 30px;
// `
const IconContainer=styled(Link)`
display: flex;
align-items: center;
margin-left: 5px;
margin-bottom: 10px;
margin-top: 20px;
cursor: pointer;
text-decoration:none;
color:gray;
position: relative;
flex-wrap:nowrap;

  
&:hover{
   .icon-text{
    opacity:1;
    font-size:10px;
    @media (max-width: 768px) {
    opacity:0;
}
   }
   .dropdown{
    display:flex;
      position:absolute;
     left:22px;
     height: 60px;
     background-color:#f5f5f5 ;
     border:none;

   flex-direction:column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 z-index: 1;
 font-size:10px;
 
   }

}
`
const Icon =styled(FontAwesomeIcon)`
font-size: 16px; /* Adjust the size as needed */
  color: #E2725B; /* Icon color */
  cursor: pointer
`
const IconText=styled.span`
margin-left: 5px;
font-size :15px;
opacity:${props => props.ismenutoggled? '1': '0'};
transition: opacity 0.3s ease;

`
const TogglerContainer=styled.div`
display:flex;
flex-direction: row;
justify-content: flex-start;
margin-left:5px;
max-width: 270px;

`
const LogoName=styled.a`
width: 100%;
max-height:70px;
padding-top: 6px;
display:inline-block;
margin-right:10px;
margin-top: 10px;
cursor: pointer;
font-weight:bold;
height:35px;
color: #f9f9f9;
align-items:center;
margin-left: 20px;
img{
    width:100%;
    height:100%;
    display:block;
    border-radius: 60%;
    padding-top: 5px 5px;
}
`
const Header=styled.nav`
   height: 70px;
   width: 100%;
   background-color:#034694;
   position:absolute;
   display:flex;
   flex-direction:row;
   justify-content: space-between;
`
const Main=styled.div`

margin-right: 0px;
margin-top: 75px;
position:relative;

display: flex;
width:100%;
height:100%;
justify-content:flex-start;
flex-direction: column;
align-items:center;
flex:1;

`
const DropDown=styled.div`
display:none;

/* flex-direction:column;
display:${({visible})=>(visible? 'flex':'none')};
position:absolute;
top:100%; */
/* position:absolute;

left:20px;
height:30px;
background-color: #fff;

flex-direction:column;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 z-index: 1;
 font-size:10px; */
`
const Option=styled(Link)`
padding: 5px;
text-decoration:none;
color:gray;
position: block;
height:50%;
  &:hover {
    background-color: #f0f0f0;
  }
`
export default DashboardLayout