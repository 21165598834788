import styled from "styled-components"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { applyLoan, fetchContributionsToApprove, fetchLoanApplications, fetchLoanPaymentsToApprove, fetchMonthlyContributions } from "../Features/api/api"
import Spinner from "../SharedFns/SpinnerElement"




const AllApprovals=()=>{
  const  notApprovedPayments=useSelector(state=> state.getLoanPaymentsToApprove.pendingLoansPayment)
  const pendingLoanApplications=useSelector( state=> state.getLoanApplication.loanApplications??[])
  const pendingContributionApprovals=useSelector(state=>state.getContributionsToApprove.contributionsToApprove??[])
 const isLoading= useSelector(state=> state.getLoanPaymentsToApprove?.loading || state.getLoanApplication?.loading || state.getContributionsToApprove?.loading)
  const user_group=useSelector(state=>state.userData.user.user.user_groups)??[]
  const dispatch=useDispatch()
  useEffect( ()=>{
    dispatch(fetchLoanApplications())
    dispatch(fetchLoanPaymentsToApprove())
    dispatch(fetchContributionsToApprove())
}, [dispatch]
)
//   const notApprovedPayments = loanPayments.filter(payment => {
//     const isPendingApproval = payment.official_group_action_status === 'pending_approval';
//     const isNotApproved = payment.approved === false;
//     const hasNoApprovedOfficial = payment.approved_official === null || payment.approved_official !== user_group[0];
  
//     return isPendingApproval && isNotApproved && hasNoApprovedOfficial;
//   })
    const paymentsToApprove= notApprovedPayments?.filter( payment=> payment.approval?.approved_official === null || payment.approval?.approved_official !== user_group[0])??[]
    const loansToApprove=pendingLoanApplications?.filter(loan=> loan.approval?.approved_official === null || loan.approval?.approved_official !== user_group[0])??[]
    const approvalCount=paymentsToApprove?.length
    const LoanApplicationCount= loansToApprove?.length
    const contributionApprovals= pendingContributionApprovals?.filter(payment=> payment.approval?.approved_official === null || payment.approval?.approved_official !== user_group[0])
    
    const contributionApprovalsCount=contributionApprovals?.length
    const noData = loansToApprove.length === 0 && paymentsToApprove.length ===0 && contributionApprovals.length ===0 ;
    console.log(contributionApprovals)
    return(
        <ApprovalsContainer>
            <div>
                {isLoading && <Spinner/>}
            </div>
            <h2> Available Approvals </h2>
            <MainContainer>
            { loansToApprove.length !== 0 && <ItemContainer>
            Loan Application Approvals
                <ViewBox>
                    <LinkBox to='approve-loan-applications'>
                    View
                    </LinkBox>
                    
                    <p> Number of pending approvals: {LoanApplicationCount}</p>
                </ViewBox>
            
            </ItemContainer>}
            { paymentsToApprove.length !==0 &&  <ItemContainer>
             Loan Payment Approvals
             <ViewBox>
             <LinkBox to='approve-loan-payments'>
                    View
                    </LinkBox>
                    <p> Number of pending approvals: {approvalCount}</p>
                </ViewBox>
            
            </ItemContainer>}
           { contributionApprovals.length !==0 && <ItemContainer>
             Monthly Contribution Approvals
             <ViewBox>
             <LinkBox to='approve-monthly-contributions'>
                    View
                    </LinkBox>
                    <p> Number of pending approvals: {contributionApprovalsCount}</p>
                </ViewBox>
            
            </ItemContainer> }
            { noData && <ItemContainer>
             <p> No approvals available for you at the moment</p>
            </ItemContainer>}
            
            </MainContainer>
            
         
        </ApprovalsContainer>
        
           
        
    )
}


const ApprovalsContainer=styled.div`
display: flex;
justify-content:flex-start;
flex-direction:column;
height:100%;
width: 100%;
margin-left:20px;



`
const NavigateTo=styled(Link)`
    background-color:#007FFF;
    border:none;
    cursor: pointer;
    border-radius:8px;
    margin-right:2px;
    font-size:15px;
    width:15px;
`
const ItemContainer=styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
width: 98%;
padding:10px;
height:70px;
color:#007FFF;
border-bottom:solid 1px rgb(230, 236, 260);
border-top:solid 1px rgb(230, 236, 260);
margin-top: 15px;
`
const MainContainer=styled.div`
display:flex;
flex-direction:  column;
justify-content: flex-start;
width:100%;
padding:10px;
border-top:solid 2px rgb(230, 236, 260);
border-bottom:solid 1px black;
margin-top: 15px;
`
const ViewBox=styled.div`
display:flex;
width:150px;
height: 20px;
justify-content:flex-start;
flex-direction:column;
align-items:end;
color:black;

margin-right: 10px;
p{
    font-size:10px;
    margin-right:2px;
    margin-top:5px;
}
Link{
    background-color:#007FFF;
    cursor: pointer;
    border-radius:8px;
    padding:5px;
    margin-right:2px;
    font-size:12px;
    text-decoration:none;
   
    
}
`
const LinkBox=styled(Link)`
padding:5px;
background-color:#007FFF;
cursor:pointer;
border-radius:8px;
font-size:12px;
width:30%;
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
text-decoration:none;
`
export default AllApprovals