import { createSlice } from "@reduxjs/toolkit"; 
import { approveMonthlyContribution } from "../api/api";



const initialState={
 loading:false,
 error:{},
 successApprovalResponse:null
    
}


const approveContributionSlice=createSlice({
    name:'approveContribution',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase(approveMonthlyContribution.pending, (state)=>{
            state.loading =true
            state.error={}
            state.successApprovalResponse=null
        } )

        builder.addCase( approveMonthlyContribution.rejected, (state, action)=>{
            state.loading=false
            state.error=action.payload
            state.successApprovalResponse=null
        })
        builder.addCase(approveMonthlyContribution.fulfilled, (state, action)=>{
            state.loading=false
            state.error={}
            state.successApprovalResponse=action.payload
        })
    }

})

export default approveContributionSlice.reducer