import React, { useId, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getUsers, deactivateUser, adminManageUserStatus } from '../Features/api/api';
import Spinner from '../SharedFns/SpinnerElement';
import AssignRoleForm from './AssignRolesForm';
import Modal from '../SharedFns/Modal';
import RemoveRoleForm from './RemoveUserRoles';
import { baseURL } from '../SharedFns/Functions';
import ChargeFineForm from './accounting/ChargeFineForm';
import MemberShipStatusForm from './MembershipStatusForm';
// Sample data for members

const members = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    regNumber: '12345',
    title: 'Manager',
    profilePic: 'https://via.placeholder.com/50'
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    regNumber: '67890',
    title: 'Developer',
    profilePic: 'https://via.placeholder.com/50'
  }
  // Add more members as needed
];



const Directors = () => {
    const dispatch=useDispatch()
    useEffect( ()=>{
        dispatch(getUsers())
      }, [dispatch])
    const [isModalOpen, setIsModalOpen]=useState(false)
    const [isRemoveModalOpen, setIsRemoveModalOpen]=useState(false)
    const [isChargeFineModalOpen, setISChargeFineModalOpen]=useState(false)
    const [isMembershipStatusFormOpen, setIsMembershipStatusFormOpen]=useState(false)
    const getFullPhotoUrl = (photo) => {
      const baseUrl =baseURL // Adjust base URL as necessary
    return baseUrl + photo;
  };
    const users=useSelector(state=> state.getUsers?.users?.users)
    const isLoading=useSelector(state => state.getUsers?.loading)
    const isActivateLoading=useSelector(state=>state.adminActivateUser?.loading || state.deactivateUser?.loading)
    const is_active=useSelector(state=>state.getUsers?.users?.users.is_active )
    const [selectedUserId, setSelectedUserId]=useState(null) 
    const [selectedmember, setSelectedMember]=useState(null)
   
   
   const statusCodeToName={
    'pending_approval':'Pending Approval',
    'active':'Active',
    'dormant':'Dormant',
    'cleared':'Cleared',

   }
    const handleDeactivate=(user_id)=>{
      dispatch( deactivateUser({user_id}))

    }
    const handleMembershipStatus =  (user_id, member, e) => {
      e.preventDefault();
      setIsMembershipStatusFormOpen(true)
      setSelectedMember(member)
      setSelectedUserId(user_id)
      console.log(user_id);
  };
  
    // const handleActivate=(user_id)=>{
    //   dispatch( adminActivateUser({user_id}))

    // }
   const handleAssignRole=(userId)=>{
    
    setIsModalOpen(true)
    setSelectedUserId(userId)
    console.log('The initial selected ID',userId)
    console.log('The set selected ID',selectedUserId)
      // dispatch( adminActivateUser({user_id}))

    }
    const handleRemoverole=(userId)=>{
     
      setIsRemoveModalOpen(true)
      setSelectedUserId(userId)
        // dispatch( adminActivateUser({user_id}))
  
      }

      const handleFineUser=(userId)=>{
        setISChargeFineModalOpen(true)
        setSelectedUserId(userId)
      } 
  return (
    
    <MemberListContainer>
        <div>
    { isLoading && <Spinner/>}
    {isActivateLoading && <Spinner/>}
    </div>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {<AssignRoleForm setIsModalOpen={setIsModalOpen} userId={selectedUserId} />}
            </Modal> 
            <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)}>
                {<RemoveRoleForm setIsRemoveModalOpen={setIsRemoveModalOpen} userId={selectedUserId} />}
            </Modal>    
            <Modal isOpen={isChargeFineModalOpen} onClose={() => setISChargeFineModalOpen(false)}>
  <ChargeFineForm setISChargeFineModalOpen={setISChargeFineModalOpen} userId={selectedUserId} />
</Modal>
      <Modal isOpen={isMembershipStatusFormOpen} onClose={()=>setIsMembershipStatusFormOpen(false)}>
        <MemberShipStatusForm setIsMembershipStatusFormOpen={setIsMembershipStatusFormOpen} userId={selectedUserId} member={selectedmember}/>
      </Modal>
      {users?.map(member => (
        <MemberCard key={member.id}>
          <ProfilePic src={getFullPhotoUrl( member.photo)} alt={`${member.first_name}`} />
          <MemberInfo>
            <MemberName>{`${member.first_name} ${member.last_name}`}</MemberName>
            <MemberDetails>ID: {member.id}</MemberDetails>
            <MemberDetails>Reg Number: {member.reg_number}</MemberDetails>
            <MemberDetails>Title: {member.user_groups[0]?member.user_groups[0]:'Member'}</MemberDetails>
            <StatusDetail status={member.status}>Membership Status: {statusCodeToName[member.status]}</StatusDetail>
           
          </MemberInfo>
          {/* {member.is_active ?
  <SignUpBtn onClick={() => handleDeactivate(member.id)}> Deactivate User</SignUpBtn> : <SignUpBtn onClick={() => handleActivate(member.id)}> Activate User</SignUpBtn>
} */}
          {member.user_groups[0]?<SignUpBtn onClick={()=>handleRemoverole(member.id)}>Remove Role</SignUpBtn>:<SignUpBtn onClick={()=> handleAssignRole(member.id)}>Assign Role</SignUpBtn>}
          <SignUpBtn onClick={() => handleFineUser(member.id)}> Fine Member</SignUpBtn> 
          <SignUpBtn onClick={(e) => handleMembershipStatus(member.id, member, e)}>Change Membership Status</SignUpBtn>

        </MemberCard>
      ))}
    </MemberListContainer>
  );
};
// Styled-components
const MemberListContainer = styled.div`
width:100%;
height:80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  overflow:scroll;
`;

const MemberCard = styled.div`
  display: flex;
  align-items: flex-start;
  /* flex-direction:row; */
  justify-content:flex-start;
  background-color: #f8f9fa;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  width: 80%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width:768px) {
    flex-direction:column;
    align-items:start;
    margin-bottom:10px;
    
    
  }
`;

const ProfilePic = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const MemberDetails = styled.div`
  font-size: 14px;
  color: #666;
`;
const StatusDetail = styled.div`
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'cleared':
        return 'blue';
      case 'dormant':
        return 'red';
      case 'pending_approval':
        return  'orange'
      default: 
        return '#333';
    }
  }};
`;
const SignUpBtn = styled.button`
margin-left:20px;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
    margin-left:2px;
    margin-bottom:10px;
    margin-top:10px;
}
`
export default Directors;
