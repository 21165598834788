import { createSlice } from "@reduxjs/toolkit";

import { fetchLoanPayments } from "../api/api";



const initialState={
    loading:false, 
    error:{},
    loanPayments: null
}

const getLoanPaymentsSlice=createSlice({
  name:'getLoanPayments', 
  initialState, 
  reducers:{

  },
  extraReducers: (builder)=>{
    builder.addCase( fetchLoanPayments.pending, (state)=>{
        state.loading=true
        state.error={}
        state.loanPayments=null
    }
    )
    builder.addCase( fetchLoanPayments.rejected, (state, action)=>{
        state.loading=false
        state.error=action.error.message
        state.loanPayments=null
    }
         )
    builder.addCase(
        fetchLoanPayments.fulfilled, (state, action)=>{
            state.loading=false
           
            state.error={}
            state.loanPayments=action.payload
        }
    )


    }
  
}
)
 export default getLoanPaymentsSlice.reducer 
 