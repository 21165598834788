


const Commoners=()=>{
    return(
        <div>
            Commoners
        </div>
    )
}

export default Commoners