import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { activateAccount, fetchBalanceSheet, fetchProfitAndLossReport } from '../../Features/api/api';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';

const DateInputFormPandL = () => {
  const [StartDate, setStartDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(StartDate);

  const [EndDate, setEndDate] = useState(null);
  const handleEndDate= (date)=> {setEndDate(date)}
  const handleStartDate= (date)=>{setSelectedStartDate(date)}

  const dispatch = useDispatch()
  //   useEffect(() => {
  //     const fetchData = async () => {
  //     //   if (!isDateFormOpen) {
  //         // Fetch balance sheet data when the component mounts
  //        dispatch(fetchProfitAndLossReport({'start_date': EndDate, 'end_date':StartDate}));
  //         // await dispatch(fetchProfitAndLossReport());
  //     //   }
  //     };

  //     fetchData();
  //   }, [dispatch, EndDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission with the selectedDate
    try {
      
     
        if (selectedStartDate > EndDate) {
            throw new Error('End Date must be a date later than Start Date');
        } }
       catch (error) {
        alert(error.message);
       return
    } 

 

    const formattedStartDate = `${selectedStartDate?.getFullYear()}-${selectedStartDate?.getMonth() + 1}-${selectedStartDate?.getDate()}`

    const formattedEndDate = `${EndDate?.getFullYear()}-${EndDate?.getMonth() + 1}-${EndDate?.getDate()}`
    dispatch(fetchProfitAndLossReport({ 'start_date': formattedStartDate, 'end_date': formattedEndDate }));
    setStartDate(selectedStartDate)
    
    // Perform any additional logic or API calls here


  }

    ;

  return (
   
    <Container>
    <PaymentForm onSubmit={handleSubmit}>
      <h3>Select Start Date and  End Date</h3>
      <TransactionFilter>
        <Pickers>
          <DatePickerContainer>
            <label htmlFor='StartDate'>Select Start Date:</label>
            <DatePicker
              selected={selectedStartDate}
              id='StartDate'
              onChange={handleStartDate}
              dateFormat='yyyy-MM-dd'
              className='custom-datepicker'
              showYearDropdown
              scrollableYearDropdown
              required
            />
          </DatePickerContainer>

          <DatePickerContainer>
            <label htmlFor='EndDate'>Select End Date:</label>
            <DatePicker
              id='EndDate'
              selected={EndDate}
              onChange={handleEndDate}
              dateFormat='yyyy-MM-dd'
              className='custom-datepicker'
              showYearDropdown
              scrollableYearDropdown
              required
            />
          </DatePickerContainer>

          <Button type='submit'>Submit</Button>
        </Pickers>
      </TransactionFilter>
    </PaymentForm>
  </Container>
);
};

const Container = styled.div`
height: auto;
padding: 20px;
@media (max-width: 768px) {
  padding:  10px 20px;
  
}
`;

const PaymentForm = styled.form`
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 100%;
max-width: 600px;
margin: 0 auto;
`;

const Pickers = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;

@media (max-width: 768px) {
  flex-direction: column; /* Stack items vertically on small screens */
}
`;

const DatePickerContainer = styled.div`
flex: 1;
margin-right: 10px;
width: 100%; /* Make date picker full-width on larger screens */

@media (max-width: 768px) {
  width: 100%; /* Make date picker full-width on small screens */
  margin-bottom: 10px;
}
`;

const Button = styled.button`
cursor: pointer;
align-items: center;
text-decoration: none;
display: flex;
justify-content: center;
cursor: pointer;
background-color: #e2725b;
color: #f9f9f9;
border: none;
border-color: #e2725b;
margin-top: 17px;

@media (max-width: 768px) {
  width: calc(50% - 5px); /* Set width based on the date pickers' width */
  margin-top: 10px; /* Adjust spacing on small screens */
}
`;

const TransactionFilter = styled.div`
 display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px; /* Adjust the vertical gap between pickers and table on larger screens */

  @media (max-width: 768px) {
    margin-bottom: 5px; /* Adjust the vertical gap between pickers and table on small screens */
    flex-direction: column;
  }
`;
export default DateInputFormPandL;
