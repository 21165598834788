import styled from 'styled-components'
import { activateAccount } from '../Features/api/api'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const ActivateAccount=(props)=>{

    const {activation_token}=useParams()
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const handleActivate= (e)=>{
        e.preventDefault()
        
        dispatch(activateAccount( {activation_token}))
         .finally( navigate('/') )
    }
    return(
        <Container>
            <Control>
            <h3> Click the button below to verify your account</h3>
           <ActivateBtn type='submit' onClick={handleActivate}>
            Verify your Account
           </ActivateBtn>
            </Control>
           
        </Container>
        
           
        
    )
}


const Container=styled.div`
height:80vh;
width:70vw;
background-color:#f9f9f9;

display: flex;

flex-direction: column;
align-items:center; 

top:0px;
left:0px;
right:0px;
`
const Control= styled.div`
display:flex;
padding-top:30px;
margin-top:50px;
flex-direction:column;
align-items:center;
width:50%;
margin-top:0px;
.h3{

}
`
const ActivateBtn=styled.button`
margin-top:10px;
margin-left:0px;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default ActivateAccount