import styled from "styled-components";


const ExitFormModal=({isOpen, onClose, children })=>{
    if(!isOpen){
        return null
    }

    return(
        <ModalOverLay>
            <ModalContainer>
            <CloseButton onClick={onClose}>Close</CloseButton>   
            {children}
            </ModalContainer>
         
        </ModalOverLay>
    )

}


const ModalOverLay=styled.div`
position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
 /* position: fixed;
  top: 120px;
  left: 120px;
  right:0px;
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow:scroll; */
`
const ModalContainer=styled.div`
background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 60%;
  max-width: 90%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  @media (max-width: 768px) {
    width :100vw;
    
  }
/* background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 5px;
 
  box-shadow: rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;
  position: relative;
  width: 80%;
  max-width: 100%;
  height:80%;
  display:flex;
  align-items:center;
  flex-direction:column;
  overflow-x:scroll;
  overflow-y:scroll */


`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
`
export default ExitFormModal