import { createSlice } from "@reduxjs/toolkit";

import { fetchMonthlyContributions } from "../api/api";



const initialState={
    loading: false,
    contributions: null,
    error:{}
}

const getContributions=createSlice({

    name:'getContributions',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase( fetchMonthlyContributions.pending, (state)=>{
            state.loading=true
            state.contributions=null
            state.error={}
        }

        )
        builder.addCase(  fetchMonthlyContributions.rejected, (state, action)=>{
            state.loading=false
            state.contributions=null
            state.error=action.payload
        }

        )
        builder.addCase( fetchMonthlyContributions.fulfilled, (state, action)=>{
            state.loading=false
            state.contributions=action.payload
            state.error={}
        }

        )
    }

}
   
)
export default getContributions.reducer