import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activateAccount, fetchBalanceSheet, fetchProfitAndLossReport } from '../../Features/api/api';
import styled from 'styled-components';
import PrintableDocument from '../documents/PrintableDocument';
import Modal from '../../SharedFns/Modal'
import DateInputForm from './DateInputForm';
import PrintablePandLDocument from '../documents/PrintablePandLDocument';
import Spinner from '../../SharedFns/SpinnerElement';
import { numberWithCommas } from '../../SharedFns/Functions';

const BalanceSheet = () => {
  const dispatch = useDispatch();
    

  const [isModalOpen, setIsModalOpen]=useState(false)
  const [isDateFormOpen, setIsDateFormOpen ]=useState(false)
  const [date, setDate]= useState(new Date())
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
// const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
const formattedDate= `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`



  useEffect(() => {
    const fetchData = async () => {
    //   if (!isDateFormOpen) {
        // Fetch balance sheet data when the component mounts
      //  dispatch(fetchBalanceSheet(selectedDate.toISOString()));
         await dispatch(fetchProfitAndLossReport({'start_date':formattedDate, 'end_date':formattedDate}));
    //   }
    };

    fetchData();
  }, [dispatch]);
 
  const accountCodeToName = {
    'bank': 'Equity Bank',
    'monthly_contribution': 'Monthly Contributions',
    'loan_receivable': 'Loan Receivables',
    'equity_fixed_deposit':'Equity Bank Fixed Deposit',
    'fixed_deposit_interest':'Fixed Deposit Interest',
    'interest_receivable': 'Interest Receivables',
    'fine':'Fines',
    'expense':'Expenses',
    'investment':'Investments',
    'good_will':'Good Will',
    'retained_earnings':'Retained Earnings',
    'dividends_payable':'Dividends Payble',
    'other_income':'Other Income',
    'fine_receivable':'Fines Receivables',
    'ordinary_shares':'Ordinary Shares'
    
  };
  //Get balance sheet data from Redux state

  const isLoading = useSelector((state) => state.getbalanceSheet?.loading);
  const balanceSheetData = useSelector(state => state.getbalanceSheet?.balanceSheetData?.balance_sheet??[]);
  const profitLossData= useSelector( state => state.getProfitAndLoss?.ProfitAndLossData?.profit_and_loss)
  const {current_year_expenses, previous_year_expenses,current_year_revenues, previous_year_revenues,current_year_total_revenue,
    previous_year_total_revenue,current_year_total_expenses,previous_year_total_expenses,current_year_net_profit_or_loss,previous_year_net_profit_or_loss
  } = profitLossData || {};
 
  //  fallback UI like spinner(I will implement spinner)
  
  const totalAssets=balanceSheetData.total_assets
  const totalLiabilities=(-1 *balanceSheetData.total_liabilities) 
  const currentDate = new Date();
  

  
 

  
  const totalEquities=Number((-1*balanceSheetData.total_equities).toFixed(2))
  const data = { ...balanceSheetData, current_year_net_profit_or_loss, totalLiabilities };
  const handlePrintPdf= ()=>{
    setIsModalOpen(true)
  }

 const handleDateCLick= (e)=>{
  setIsDateFormOpen(true)
 }

  
  return (
    <Container>

      <div>
        {isLoading && <Spinner/>
      }
      </div>
      {/* <Modal isOpen={isDateFormOpen} onClose={()=>setIsDateFormOpen(false)}> */}
      <DateInputForm setIsDateFormOpen={setIsDateFormOpen} setDate={setDate} />
       {/* </Modal> */}
      {/* <Button onClick={handleDateCLick}> Select End Date </Button> */}
      
    <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
       <PrintableDocument title="Eye20 Group Balance Sheet as on" date={formattedDate} data={data } accountCodeToName={accountCodeToName}/>
       {/* <PrintablePandLDocument  title="Eye20 Group Balance Sheet as on" date={formattedDate} currentYearData={data} previousYearData={data } accountCodeToName={accountCodeToName}/> */}
    </Modal>
            
      <h1>Balance Sheet</h1>
      <Table>
        <thead>
          <tr>
            <TableHeader>Category</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          {/* Assets Section */}
          <tr>
            <SectionHeader colSpan='2'>Assets</SectionHeader>
          </tr>
          {balanceSheetData && balanceSheetData.assets && Object.values(balanceSheetData.assets).map(asset => (
            <tr key={asset.account}>
              <TableCell>{accountCodeToName[asset.account]}</TableCell>
              <TableCell>{numberWithCommas(asset.balance?.toFixed(2))}</TableCell>
            </tr>
          ))}
          {/* Liabilities Section */}
          <tr>
            <SectionHeader colSpan='2'>Liabilities</SectionHeader>
          </tr>
          {balanceSheetData && balanceSheetData.liabilities && Object.values(balanceSheetData.liabilities).map(liability => (
            <tr key={liability.account}>
              <TableCell>{accountCodeToName[liability.account]}</TableCell>
              <TableCell>{numberWithCommas((-1 * liability.balance)?.toFixed(2))}</TableCell>
            </tr>
          ))}
          {/* Equity Section */}
          <tr>
            <SectionHeader colSpan='2'>Equities</SectionHeader>
          </tr>
          {balanceSheetData && balanceSheetData.equities && Object.values(balanceSheetData.equities).map(equity => (
            <tr key={equity.account}>
              <TableCell>{accountCodeToName[equity.account]}</TableCell>
              <TableCell>{numberWithCommas((-1 * equity.balance)?.toFixed(2))}</TableCell>
            </tr>
          ))}
          {/* Check Section */}
          <tr>
            <SectionHeader colSpan='2'>Check</SectionHeader>
          </tr>
          <tr>
            <TableCell>Total Liabilities plus Equities</TableCell>
            <AmountCell>{numberWithCommas((totalLiabilities + totalEquities).toFixed(2))}</AmountCell>
          </tr>
          <tr>
            <TableCell>Total Assets</TableCell>
            <AmountCell>{totalAssets && numberWithCommas(totalAssets?.toFixed(2))}</AmountCell>
          </tr>
          <tr>
            <TableCell>Check</TableCell>
            <AmountCell>{numberWithCommas((totalAssets - (totalLiabilities + totalEquities)).toFixed(2))}</AmountCell>
          </tr>
        </tbody>
      </Table>

      {/* <Table>
        <thead>
          <tr>
            <TableHeader>Assets</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          {balanceSheetData && balanceSheetData.assets && Object.values(balanceSheetData.assets).map(asset => (
            <tr key={asset.account}>
              <TableCell>{ accountCodeToName[asset.account]}</TableCell>
              <TableCell>{numberWithCommas(asset.balance?.toFixed(2))}</TableCell>
              
            </tr>
          ))}
          <tr>
          <TableCell> Total</TableCell>
            <TableCell>{totalAssets && numberWithCommas(totalAssets?.toFixed(2))}</TableCell>
          </tr>
         
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <TableHeader>Liabilities</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          {balanceSheetData && balanceSheetData.liabilities  && Object.values(balanceSheetData.liabilities).map(liability => (
            <tr key={liability.account}>
              <TableCell>{accountCodeToName[liability.account]}</TableCell>
              <TableCell>{numberWithCommas((-1*liability.balance)?.toFixed(2))}</TableCell>
            </tr>
          ))}
        
           <tr>
          <TableCell> Total</TableCell>
            <TableCell>{totalLiabilities && numberWithCommas((totalLiabilities)?.toFixed(2))}</TableCell>
          </tr>
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <TableHeader>Equity</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          {balanceSheetData && balanceSheetData.equities && Object.values(balanceSheetData.equities).map(equity => (
            <tr key={equity.account}>
              <TableCell>{accountCodeToName[equity.account]}</TableCell>
              <TableCell>{numberWithCommas((-1*equity.balance)?.toFixed(2))}</TableCell>
            </tr>
          ))}
           <tr>
          <TableCell> Total</TableCell>
            
            <TableCell>{ numberWithCommas(totalEquities?.toFixed(2))}</TableCell> 
          </tr>
        </tbody>
 
      </Table>
      <Check>
  <tr>
    <FirstTableCell>Item</FirstTableCell>
    <FirstTableCell>Amount</FirstTableCell>
  </tr>
  <tr>
    <TableCell>Total liabilities plus equities</TableCell>
    <AmountCell>{numberWithCommas((totalLiabilities + totalEquities).toFixed(2))}</AmountCell>
  </tr>
  <tr>
    <TableCell>Total Assets</TableCell>
    <AmountCell>{totalAssets && numberWithCommas(totalAssets?.toFixed(2))}</AmountCell>
  </tr>
  <tr>
    <TableCell>Check</TableCell>
    <AmountCell>{numberWithCommas((totalAssets - (totalLiabilities + totalEquities)).toFixed(2))}</AmountCell>
  </tr>
</Check> */}

      <GetPdf>
         <Button onClick={handlePrintPdf}> Get PDF </Button>
      </GetPdf>

    </Container>
  );
};


const Container=styled.div`
width:100%;
height:100%;
overflow-y:scroll;
display: flex;
flex-direction:column;
align-items:flex-start;
 /* Hide the vertical scrollbar for webkit-based browsers */
 ::-webkit-scrollbar {
    width: 0.5em;
  }

  /* Track (the area where the scrollbar is) for webkit-based browsers */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Handle (the draggable part of the scrollbar) for webkit-based browsers */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover for webkit-based browsers */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Hide the vertical scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Optional: Add padding to the content area to prevent overlap with scrollbar */
  padding-right: 0.5em;
  @media (max-width:768px) {
  overflow-x:scroll;
  
}
`

const GetPdf=styled.div`
margin-bottom: 70px;
margin-top:20px;

`


const Table = styled.table`
border-collapse:collapse;
width:80%;

margin-bottom:50px;
`
const TableHeader = styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;
width:100%;

`
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
  width:12px;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}
`
const Check = styled.table`
  width: 80%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SectionHeader = styled.th`
border: solid 1px #000;

background-color: #f2f2f2;
padding: 10px;
font-weight: bold;
width:100%;
`;

const FirstTableCell = styled(TableCell)`
  font-weight: bold;
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
  width:12px;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }}
`;

const AmountCell = styled(TableCell)`
  text-align: left;
`;
const Button = styled.button`
cursor:pointer;
align-items:center;
margin-left:20px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
`

export default BalanceSheet;
