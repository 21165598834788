import { createSlice } from "@reduxjs/toolkit";
import { getDeclarations } from "../api/api";

const initialState = {
  loading: false,
  error: {},
  declarations: [],
};

const getDeclaration = createSlice({
  name: 'getDeclarations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDeclarations.pending, // <- Correct action type
      (state) => {
        state.loading = true;
        state.error = {};
        state.declarations= [];
      }
    );
    builder.addCase(
      getDeclarations.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.declarations = [];
      }
    );
    builder.addCase(
      getDeclarations.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = {};
        state.declarations = action.payload;
      }
    );
  },
});

export default getDeclaration.reducer;
