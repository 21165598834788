import { useState } from "react"
import { useNavigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux'
import { login, getUser } from "../Features/api/api";
import Modal from "../SharedFns/Modal";
import PasswordResetForm from "./PasswordResetForm";
import Spinner from "../SharedFns/SpinnerElement";



const SignIn = ({ onChange, onRegisterClick }) => {
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const username = useSelector(state => state.userData.user?.user.username)
    const isLoading = useSelector(state => state.signIn?.loading)
    const initialFormData={
        email: "",
        password: ""
    }
    const [logInData, setLoginData] = useState(
        initialFormData
    )

    const [ISPasswordTouched, setIsPassowrdTouched] = useState(false)
    const [ISEmailTouched, setISEmailTouched] = useState(false)
    const [isModalOpen, setIsModalOpen]=useState(false)

    const IsSubmitDisabled = (!ISEmailTouched || !ISPasswordTouched)


    const handleClick = (e) => {
        e.preventDefault()
        onRegisterClick()
        onChange(true)



    }
    const resetFormValues= ()=>{
        setLoginData(initialFormData)
        setIsPassowrdTouched(false)
        setISEmailTouched(false)

    }
   
    const handleSubmit = async(e) => {
        e.preventDefault()
      
        try {
            // Dispatch the login action and wait for it to complete
            await dispatch(login(logInData));
        
            // Reset form values after login is completed
            resetFormValues();
        
            // Dispatch the getUser action after login is completed
            await dispatch(getUser());

            await navigate('/dashboard')
          } catch (error) {
            // Handle any errors here
            console.error(error);
          }
         
      }
       

    const handleOnClick=(e)=>{
        setIsModalOpen(true)
    }
    
    const handleChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        setLoginData({
            ...logInData, [name]: value

        })

        if (value === "") {
            switch (name) {
                case "email":
                    setISEmailTouched(false)
                    break;
                case "password":
                    setIsPassowrdTouched(false)
                    break

                default:
                    setISEmailTouched(false)
                    setIsPassowrdTouched(false)
                    break;
            }

        }
        else if (name === "email") {
            setISEmailTouched(true)
        }
        else {
            setIsPassowrdTouched(true)
        }


    }
    return (

        <Container>
              <div>
        {isLoading && <Spinner/>
      }
      </div>
            <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
               {<PasswordResetForm />}
            </Modal> 
            <h3> Sign in</h3>
            <p> Sign in to continue. Remember, your password is yours, do not share it with anyone.</p>
           
            <p>If this is your first time here, you can register using your Eye20 Group registration details.</p>
            <SignInForm>
                <form onSubmit={handleSubmit}>
                    <Form_Row>
                        <label htmlFor="email" style={{ color: ISEmailTouched ? 'red' : 'initial' }}>
                            {ISEmailTouched ? 'Email address or phone number' : "Email address or phone number"}
                        </label>

                    </Form_Row>
                    <Form_Row>
                        <input name="email"
                            id="email"
                            type="email" value={logInData.email} onChange={handleChange} placeholder="Email or phone number" required />
                        {ISEmailTouched && <h3 style={{ color: ISEmailTouched ? 'red' : 'initial', fontSize: '8px' }}> Your email address or mobile number is required</h3>}
                    </Form_Row>

                    <Form_Row>
                        <label htmlFor="password" style={{ color: ISPasswordTouched ? 'red' : 'initial' }}>
                            {ISPasswordTouched ? 'Enter Password' : "Enter Password"}
                        </label>
                    </Form_Row>
                    <Form_Row>
                        <input name="password" autocomplete="current-password" placeholder="Enter Password" value={logInData.password} id="password" type="password" onChange={handleChange} style={{ borderBottom: ISPasswordTouched ? '1px solid red' : '1px solid #353935' }} required />
                        {ISPasswordTouched && <h3 style={{ color: ISPasswordTouched ? 'red' : 'initial', fontSize: '8px' }}> Please enter your password</h3>}
                    </Form_Row>
                    <ForgotPassword type="button" onClick={handleOnClick}>
                        Forgot your password?
                    </ForgotPassword>
                    <AuthButtons>
                        <SignInBtn disabled={IsSubmitDisabled} type="submit" onClick={handleSubmit} >  Sign in </SignInBtn>
                        {/* <SignUpBtn type="button" onClick={handleClick}> Register</SignUpBtn> */}

                    </AuthButtons>
                </form>


            </SignInForm>

        </Container>


    )
}
const Container = styled.div`
display: flex;
flex-direction:column;
width:70%;
`
const SignInForm = styled.div`
margin-top: 30px;

display:flex;
flex-direction:column;
justify-content:space-around;
`
const Form_Row = styled.div`

margin-bottom:10px;
input {
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
label{
    font-size:12px;
}
`
const Form_col = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
padding:20px;
margin-top:10px;
`
const ForgotPassword = styled.button`
    color: #E2725B;
    font-size:15px;
    padding-left:0px; 
    margin-top:20px;
    cursor: pointer;
    width:50%;
    border:none;
    background-color:#f9f9f9;
    display:flex;
    justify-content:flex-start;
    @media (max-height:768px) {
        font-size:10px;
    }
`
const AuthButtons = styled.div`
display: flex;

flex-direction:row;
justify-content:flex-start;
align-items:center;
margin-top:20px;
width:80%;
padding-left: 0px;
margin-left:0px;
`
const SignInBtn = styled.button`
display:flex;
justify-content:flex-start;
cursor: ${props => props.disabled ? 'arrow' : 'pointer'};
padding: 10px 30px;
margin:auto;
border-radius:10px;
background-color: ${props => props.disabled ? '#ccc' : '#3498db'};
border:none;
color:#f9f9f9;
border-color:#034694;
@media (max-width: 768px) {
    font-size:12px;
    padding: 5px 15px;
}
`
const SignUpBtn = styled.button`
margin-left:20px;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default SignIn 