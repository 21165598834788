import { createSlice } from "@reduxjs/toolkit";

import { payContribution } from "../api/api";


const initialState={
    loading: false,
    error:{},
    contribPaySuccess: null
}

const payContributionSlice=createSlice({
    name:'paycontribution',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase( payContribution.pending, state=>{
            state.loading=true
            state.error={}
            state.contribPaySuccess=null
        })
        builder.addCase(payContribution.rejected,  (state, action)=>{
            state.loading=false
            state.error=action.payload
            state.contribPaySuccess=null
        }
            )
            builder.addCase( payContribution.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.contribPaySuccess=action.payload
            }

            )
    }

    }
)

export default payContributionSlice.reducer