
import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "../../SharedFns/axios";
import { useDispatch } from "react-redux";




export const signupUser = createAsyncThunk('/auth/signup', async (formData) => {

  try {
    const response = await API.post('/api/user/signup/', formData);
    // Return the response data when the request is successful
    alert(response.data ? response.data.detail : "Check whetehr your email is valid")
    return response.data
  }
  catch (error) {
    if (error.response && error.response.status === 400) {
      const errorData = error.response.data
      alert(errorData ? errorData.detail : "Check whetehr your email is valid");
      const validationError = new Error('Validation error');
      validationError.errors = errorData?.detail;
      throw validationError;


    } else {
      throw error;
    }
  }

})

export const activateAccount = createAsyncThunk('auth/activate-account', async ({ activation_token }) => {
  
  try {
    const response = await API.post('/api/user/activate/', { activation_token })
    alert(response.data ? response.data.detail : "Check whetehr your email is valid")
    return response.data
 
  } catch (error) {

    if (error.response && error.response.status === 400) {
      const errorData = error.response.data
      alert(errorData ? errorData.detail : "Check whether your email is valid");
      const errors = errorData?.detail;
      throw new Error(JSON.stringify({ errors }));


    } else {
      throw error;
    }

  }
}

)

export const deactivateUser= createAsyncThunk('auth/deactivate-user', async({user_id})=>{
  try {
    const response=await API.post(`/api/user/deactivate_user/${user_id}/`)
    alert(response.data?.detail)
    return response.data
  } catch (error) {
    
    if (error.response && error.response?.status===400 || error.response && error.response?.status===403){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
  
})
export const adminManageUserStatus= createAsyncThunk('auth/adminActivate-user', async({user_id,approvalData} )=>{
  try {
    const response=await API.post(`/api/user/admin_manage_user/${user_id}/`,approvalData)
    alert(response.data?.detail)
    return response.data?.detail
  } catch (error) {
    
    if (error.response && error.response?.status===400 || error.response && error.response?.status===403){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
  
  
})

export const login= createAsyncThunk( 'auth/login', async(loginData )=>{
  try { const response= await API.post('/api/user/login', loginData );
        
        localStorage.setItem('session_cookie', response.data?.access)
        
        
        alert('Login Was successful ')
      
         
       
        return response.data?.detail 
    
  } catch (error) {
    if (error.response && error.response.status === 401){
      const errorData = error.response.data
      // alert(errorData ? errorData.detail : "Check whetehr your email is valid");
      const validationError = new Error('Some of your login information was incorrect');
      alert(validationError)
      throw validationError
    }
       
    else{
      throw error
    }
    
  }
}
)
export const ResetPassword = createAsyncThunk( 'auth/ResetPassWord', async({ RestePasswordData, navigate })=>{

  try {
    
    const response= await API.post('/api/user/forgot-password/', RestePasswordData)
    alert(response.data?.detail)
     navigate('/')
    return response.data
    
  } catch (error) {
    
    if(error.response && error.response.status === 400){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
})
export const sendResetPassword = createAsyncThunk( 'auth/ResetEmail', async(ResetEmail)=>{
  try {
    const response= await API.post('/api/user/password-reset/', ResetEmail)
    alert(response.data?.detail)
    return response.data
    
  } catch (error) {
    
    if(error.response && error.response.status === 400){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
})
export const getUser= createAsyncThunk('auth/get-user-data', async()=>{
  try {
     const response= await API.get('/api/user/get_user_data')
     alert(response.data?.detail)
     return response.data
  } catch (error) {
    if(error.response && error.response.status === 401 ){
      const validationError = new Error('Please Login again');
      alert(validationError)
      throw validationError
    }
    else{
      throw error
    }
    
  }
})
export const fetchLoans= createAsyncThunk('loans/fetchLoans', async ()=>{

  try {
    const response= await API.get('/api/finances/get-create-user-loans/')
    return response.data
    
  } catch (error) {

    if(error.response && error.response.status === 400 ){
      const validationError = error.response.data?.detail
      alert(validationError)
      throw validationError
    }
    else{
      throw error
    }
    
  }

})
export const applyLoan = createAsyncThunk( 'finances/applyLoan', async(loanData)=>{
  try {
    const response= await API.post('/api/finances/get-create-user-loans/', loanData)
    alert(response.data?.detail)
    return response.data
    
  } catch (error) {
    
    if(error.response && error.response.status === 400){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
}

)
export const payLoan= createAsyncThunk( 'finances/payloan', async( {loan_id, paymentData})=>{
  try {
    
    const response= await API.post( `/api/finances/pay-loan/${loan_id}/`,   paymentData)
    alert(response.data?.detail)
    return response.data
  } catch (error) {
    if (error.response && error.response.status===400){
     alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }
  
})  
export const fetchLoanPayments= createAsyncThunk('finances/fetchTransactions', async()=>{
  try {
    const response= await API.get('/api/finances/get-loan-payments/')
    return response.data
  } catch (error) {
    if(error.response && error.response.status===400){
      const validationError= error.response?.detail
      alert(validationError)
    }
   else{
    throw error
   }  
    
  }
})
export const fetchLoanPaymentsToApprove=createAsyncThunk('finances/fetchLoanPaymentsToApprove', async()=>{
 try {
  const response= await API.get('/api/finances/get-loan-payment-approvals/') 
  return response.data
  
 } catch (error) {
  if (error.response && error.response.status===400){
    console.log(error.response.data?.detail)
    // alert(error.response.data?.detail)
   }
  else{
    throw error
  }

 }
})
export const approveLoanPayment=createAsyncThunk('finances/approveLoanPayment', async({transaction_id, approvalData})=>{
  try {
    
    const response=await API.post(`/api/finances/loan-payment-approval/${transaction_id}/`, approvalData)
    alert(response.data?.detail)
    return response.data
  } catch (error) {
    if(error.response && error.response.status === 400){
      const validationError =error.response.data?.detail
       alert(validationError)
    }
   else{
    throw error
   } 
  }
}
 )

 export const fetchLoanApplications= createAsyncThunk('finances/fetchLoanApplications', async()=>{
  try {
   const response=await API.get('/api/finances/get-loan-application-approval/')

    return response.data
  } catch (error) {

    if(error.response && error.response.status === 400){
      // alert(error.response.data?.detail)
    }
    else{
      throw error
    }
    
  }
 })

 export const approveLoanApplication=createAsyncThunk('finances/approveLoanApplication', async({loan_id, approvalData})=>{
  try {
    const response=await API.post(`api/finances/loan-application-approval/${loan_id}/`, approvalData)
    alert(response.data?.detail)
    return response.data
  } catch (error) {
    if (error.response && error.response.status === 400){
      const validationError=error.response.data?.detail
      alert(validationError)
    }
    else{
      throw error
    }
  }
 })

 export const fetchMonthlyContributions=createAsyncThunk('finances/fetchContributions', async()=>{
  try {
    const response=await API.get('api/finances/monthly-contribution/')
    // alert(response.data?.detail)
    
   
    return response.data

  } catch (error) {

    if (error.response && error.response.status === 400){
      const validationError= error.response.data?.detail
      alert(validationError)
    }
    else{
      throw error
    }
    
  }
 } 

 )

 export const payContribution= createAsyncThunk('finances/payContribution', async(contributionData)=>{
  try {
    const response= await API.post('api/finances/monthly-contribution/', contributionData)

    alert(response.data?.detail)
    return response.data
    
  } catch (error) {
    if (error.response && error.response.status === 400){
      const validationError =error.response.data?.detail
      alert(validationError)
    }
    else{
      throw error
    }
    
  }
 
 }
  )

export const fetchContributionsToApprove=createAsyncThunk('finances/fetchContributionApprovals', async()=>{
  try {
    
    const response=await API.get('api/finances/monthly-contribution-approval/')
    // alert(response.data?.detail)
    return response.data
  } catch (error) {
    if(error.response && error.response.status === 400){
      const validationError=error.response.data?.detail
      // alert(validationError)
    }
    else{
      throw error
    }
    
  }
})  

export const approveMonthlyContribution=createAsyncThunk('finances/payContribution', async( {approvalData, transaction_id})=>{
  try {
    const response= await API.post(`api/finances/monthly-contribution-approval/${transaction_id}/`, approvalData)
    alert(response.data?.detail)
  return response.data
  } catch (error) {
    if(error.response && error.response?.status===400 || error.response && error.response?.status===403){
      const validationError=error.response.data?.detail
      alert(validationError)
    }
    else{
      throw error
    }
    
  }
  
})
export const fetchLedgerBalances= createAsyncThunk( 'accounting/trialbalance', async(date)=>{
 try {
   const response= await API.get(`api/accounting/get_trial_balance/?date=${date}`)
   return response.data
 } catch (error) {
  if (error.response && error.response.status === 400){

    const errorData = error.response.data?.detail
    alert(errorData)
  }
  else{
    throw error
  }
 }

}
)

// export const  fetchBalanceSheet=createAsyncThunk('accounting/balanceSheet',  async(date)=>{
//   try {
    
//     const response= await API.get(`api/accounting/get_balance_sheet/?date=${date}`)
//     return  response.data
//   } catch (error) {
//     if(error.response && error.response.status === 400){

//       const errorData= error.response.data?.detail
//     }
//     else{
//       throw error
//     }
    
//   }
   
 
// }
// )
export const fetchBalanceSheet = createAsyncThunk(
  'accounting/balanceSheet',
  async ( date , { rejectWithValue }) => {
    try {
      // Check if the provided date is a valid Date object
      console.log(date)
      const formattedDate = date instanceof Date && !isNaN(date)
        ? date.toISOString()
        : '2024-08-31';  // Provide a default date if the input date is invalid

      // Make the API request with the formatted date
      console.log(formattedDate)
      const response = await API.get(`api/accounting/get_balance_sheet/?date=${date}`);
      
      // Return the data on a successful response
      return response.data;
    } catch (error) {
      // Handle 400 Bad Request separately to extract error details
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data?.detail;
        return rejectWithValue(errorData);
      } else {
        // Re-throw other errors to be handled by the standard error handling
        throw error;
      }
    }
  }
);


 export const fetchProfitAndLossReport = createAsyncThunk('accounting/profit&loss', async({start_date, end_date})=>{
  try {
  const response= await API.get(`/api/accounting/get_profit&loss/?start_date=${start_date}&end_date=${end_date}`)
 return response.data

  } catch (error) {
    if (error.response && error.response.status === 400)
    { 
      const errorData=error.response.data?.detail
      alert(errorData)
    

    }

    else{
      throw error
    }

    
    
  }
 

  })

  export const createLedgers= createAsyncThunk('accounting/createLedgers', async(ledgerdata)=>{
    try {
       const response = await API.post('api/accounting/create_ledger_entry/', ledgerdata )
       alert(response.data?.detail)
       return response.data
      
    } catch (error) {
      if (error.response && error.response.status === 403 || error.response && error.response.status === 400  )
      { 
        const errorData=error.response.data?.detail
        console.error('Permission Error:', errorData);
        alert(errorData)
  
      }
      
  
      else{
        throw error
      }

      
    }

  })
export const getUsers=createAsyncThunk('users/getUsers', async()=>{
  try {
    const response=await API.get('api/user/get_users')
    return response.data
  } catch (error) {
    if (error.response && error.response?.status===400 || error.response && error.response?.status===403){
      const errorData=error.response.data?.detail
      alert(errorData)

    }
    else{
      throw error
    }
    
  }
}
)
export const getActiveUserCount=createAsyncThunk('users/getActiveUsersCount', async()=>{
  try {
    const response=await API.get('api/user/get_active_user_count')
    return response.data
  } catch (error) {
    if (error.response && error.response?.status===400){
      const errorData=error.response.data?.detail
      alert(errorData)

    }
    else{
      throw error
    }
    
  }
}
)

  export const getDeclarations= createAsyncThunk('accounting/createLedgers', async()=>{
    try {
       const response = await API.get('api/accounting/create_ledger_entry/' )
      //  alert(response.data?.detail)
       return response.data
      
    } catch (error) {
      if (error.response && error.response.status === 403)
      { 
        const errorData=error.response.data?.detail
        alert(errorData)
  
      }
  
      else{
        throw error
      }

      
    }

  })

  export const assignUserRole= createAsyncThunk('auth/AssignUser-Role', async({approvalData, user_id})=>{
    try {
      const response=await API.post(`/api/user/assign_groups/${user_id}/`, approvalData)
      alert(response.data?.detail)
      return response.data?.detail
    } catch (error) {
      
      if (error.response && error.response?.status===400 || error.response && error.response?.status===403){
        alert(error.response.data?.detail)
      }
      else{
        throw error
      }
    }
  
})
export const removeUserRole= createAsyncThunk('auth/RemoveUserRole', async({approvalData, user_id})=>{
  console.log('this is the data needed:',approvalData)
  try {
    const response=await API.delete(`/api/user/remove_groups/${user_id}/`, {
      data: approvalData
    })
    alert(response.data?.detail)
    return response.data?.detail
  } catch (error) {
    
    if (error.response && error.response?.status===400 || error.response && error.response?.status===403){
      alert(error.response.data?.detail)
    }
    else{
      throw error
    }
  }

})

export const fetchAllContributions= createAsyncThunk('Contributions/fetchAllContriutions', async ()=>{

  try {
    const response= await API.get('/api/finances/get-monthly-contributions/')
    return response.data
    
  } catch (error) {

    if(error.response && error.response.status === 400 ){
      const validationError = error.response.data?.detail
      alert(validationError)
      throw validationError
    }
    else{
      throw error
    }
    
  }

})

export const exitMember= createAsyncThunk('accounting/ExitMember', async(exitData)=>{
  try {
     const response = await API.post('api/accounting/exit_member/', exitData )
     alert(response.data?.detail)
     return response.data
    
  } catch (error) {
    if (error.response && error.response.status === 403 || error.response && error.response.status === 400  )
    { 
      const errorData=error.response.data?.detail
      console.error('Permission Error:', errorData);
      alert(errorData)

    }
    

    else{
      throw error
    }

    
  }

})

export const clearMember= createAsyncThunk('accounting/ClearMember', async(clearanceData)=>{
  try {
     const response = await API.post('api/accounting/clear_member/', clearanceData )
     alert(response.data?.detail)
     return response.data
    
  } catch (error) {
    if (error.response && error.response.status === 403 || error.response && error.response.status === 400  )
    { 
      const errorData=error.response.data?.detail
      console.error('Permission Error:', errorData);
      alert(errorData)

    }
    

    else{
      throw error
    }

    
  }

})