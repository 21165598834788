import styled from "styled-components"
import { Link, Outlet, useNavigate } from "react-router-dom"
import Modal from "../SharedFns/Modal"
import LoanPaymentTransactions from "./LoanPaymentTransactions"
import { useState } from "react"

const FinancialSummary=()=>{
    const[isModalOpen, setIsModalOpen]=useState(false)
    const navigate=useNavigate()
    const handleClick= (route)=>{
           navigate(route) 
    }
    return(
        <Container>
            <Content>
            <Header>
                <h2> Financial Summary</h2>
            </Header>
            <LinksContainer>
            <Linkto  to ='loan-summary' > Summary </Linkto>
            <Linkto to='commoners'> Commoners</Linkto>
            {/* <Linkto to='loan-applications'>Loan Applications</Linkto> */}
           
            </LinksContainer>
            <RenderTransactions>
            <Outlet/>
            </RenderTransactions>
          
            {/* <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
                {<LoanPaymentTransactions />}
            </Modal> */}
            
            </Content>
            
           
          
            </Container>
    )


}
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100%;
    /* Remove the overflow-x: scroll property */
    /* overflow-x: scroll; */
  }
`;

const Content=styled.div`
width:100%;
display:flex;
flex-direction:column;
height:100%;
overflow-x:scroll;
`
const Table=styled.table`
border-collapse:collapse;
width:100%;

margin-bottom:100px;
`
const TableHeader=styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;

` 
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
  width:12px;
  height: 10%;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}
`
const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: solid 1px gray;
  margin-bottom: 10px;
  margin-left:20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-around;
    width:145%;
    padding-bottom:5px;
    padding-left:10px;
    overflow-x: auto;
    height:70px;
    margin-bottom:20px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height: 70px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-bottom: solid 1px gray;
  margin-top: 20px;

  @media (max-width: 768px) {
    height: 50px;
    width:145%;
    .h2 {
      font-size: 14px;
    }
  }
`;

const RenderTransactions = styled.div`
  width: 100%;
  padding: 10px;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0px;
 
    width:100%;
    /* width: calc(100% - 20px); Adjust the margin accordingly */
  }
`;
const Linkto = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin: 5px;
  width: 24%; /* Set width to create a 2 by 2 layout on small screens */
  box-sizing: border-box; /* Ensure padding and border don't affect width */
  padding:10px;
  border-radius: 10px;
  background-color: #e2725b;
  color: #f9f9f9;
  border: none;
  border-color: #e2725b;

  @media (max-width: 768px) {
    width: 100%; /* Adjust width for small screens */
    max-width: 100px; /* Remove max-width for small screens */
    height: 30px;
    font-size: 10px;
    padding-left:5px;
    margin-left: 2px;
   
  }
`;



// const RenderTransactions=styled.div`
// width:100%;
// padding:10px;
// margin-left:20px;
// @media (max-width: 768px) {
//     width:100vw;
    
// }

// `

export default FinancialSummary