import { createSlice } from "@reduxjs/toolkit";


import { fetchProfitAndLossReport } from "../api/api";



const initialState={
    loading:false,
    error:{},
    ProfitAndLossData: []
}


const getProfitAndLossData=createSlice({
name:'getProfitAndLoss',    
initialState,
reducers:{},
extraReducers: (builder)=>{
    builder.addCase(
        fetchProfitAndLossReport.pending,  (state)=>{
            state.loading=true
            state.error= {}
            state.ProfitAndLossData=[]
        }

    )
    builder.addCase(
        fetchProfitAndLossReport.rejected, (state, action)=>{
            state.loading=false
            state. error= action.payload
            state.ProfitAndLossData=[]
        }

    )
    builder.addCase(
        fetchProfitAndLossReport.fulfilled, (state, action)=>{
         state.loading=false
         state.error={}
         state.ProfitAndLossData=action.payload

        }
    )
}
 

}

)

export default getProfitAndLossData.reducer