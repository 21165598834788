import React, {useRef} from 'react';
import styled from 'styled-components';

import ReactToPrint from 'react-to-print';
import { numberWithCommas } from '../../SharedFns/Functions';




const PrintableDocument = ({ title, date, data, accountCodeToName }) => {
  const contentRef = useRef(null);
  if (!data) {
    return <div>Loading...</div>; //  fallback UI like spinner(I will implement spinner)
  }
  console.log(data)
  
  return (
    <Wrapper >
      {/* Header */}
      <div  ref={contentRef}>
      <Header>
        <Logo src={process.env.PUBLIC_URL + '/media/logo.png'}alt="Logo" />
        <Title style={{borderBottom:'solid 1px' }}>{title}: {date} </Title>
      </Header>

      {/* Content */}
      <Section>
         <TitleHolders>
         <Title>Assets</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {data.assets &&
          Object.values(data.assets).map((asset) => (
            <Row key={asset.account}>
              <Label>{accountCodeToName[asset.account]}</Label>
              <Value>{numberWithCommas(asset.balance?.toFixed(2))}</Value>
            </Row>
          ))}
           <RowWithBorder>
           
              <Title style={{border:'none'}}>
                Total Assets
                </Title>
          
            <Value> {numberWithCommas(data.total_assets?.toFixed(2))}</Value>
            
          </RowWithBorder>
      </Section>

      <Section>
      <TitleHolders>
         <Title>Liabilities</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {data.liabilities &&
          Object.values(data.liabilities).map((liability) => (
            <Row key={liability.account}>
              <Label>{accountCodeToName[liability.account]}</Label>
              <Value>{numberWithCommas((-1*liability.balance)?.toFixed(2))}</Value>
            </Row>
          ))}
          {/* <Row> 
          <Label>Net Profit/Loss</Label>
              <Value>{(data.current_year_net_profit_or_loss)?.toFixed(2)}</Value>

          </Row> */}
          <RowWithBorder>
            <Title style={{border:'none'}}>
              Total Liabilities
            </Title>
            <Value> {numberWithCommas((data.totalLiabilities)?.toFixed(2))}</Value>
          </RowWithBorder>
      </Section>

      <Section>
      <TitleHolders>
         <Title>Equities</Title>
         <Title> Amount (KES)</Title>
         </TitleHolders>
        
        {data.equities &&
          Object.values(data.equities).map((equity) => (
            <Row key={equity.account}>
              <Label>{accountCodeToName[equity.account]}</Label>
              <Value>{numberWithCommas((-1*equity.balance)?.toFixed(2))}</Value>
            </Row>
          ))}
      </Section>

      <Section>
      <RowWithBorder>
            <Title style={{border:'none'}}>
              Total Equities
            </Title>
            <Value> {numberWithCommas((-1*data.total_equities)?.toFixed(2))}</Value>
          </RowWithBorder>
      </Section>

      <Section>
      <RowWithBorder>
            <Title style={{border:'none'}}>
              Total Assets less Equties and Liabilities
            </Title>
            <Value> {numberWithCommas((data.total_assets - (data.totalLiabilities + (-1*data.total_equities))).toFixed(2))}</Value>
          </RowWithBorder>
      </Section>


      {/* Footer */}
      <Author>
        <Text>Signed by Director 1: .........................</Text>
        <Text>Signed by Director 2: .........................</Text>
        <Text>Signed by Director 2: .........................</Text>
        <Text>{date}</Text>
      </Author>
      </div>
     
      <ReactToPrint
      trigger={() => <DownloadButton>Download PDF</DownloadButton>}
      content={() => contentRef.current}
    />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* Your global styles here */
  height: 50%;
  width:80%;
  margin-top:10px;
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 10px;
  margin-top:0px;
`

const Logo = styled.img`
  width: 130px;
  border-radius: 50%;
  margin-left:50px;
  background-color:white;
  padding:5px 10px;
  
  object-fit: cover;
  margin-bottom:15px;
  @media (max-width:768px) {
    width:100px;
    margin-bottom:5px;
    margin-top:0px;
    padding-top:0;
    margin-left:30px
  }
`;

const TitleHolders=styled.div`
display: flex;
justify-content: space-between;
border-bottom:solid 1px;
padding-bottom:0px;
margin-bottom: 3px;
  

`
const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
 
`

const Section = styled.div`
  margin: 10px;
  padding: 10px;
  flex-grow: 1;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Label = styled.div`
  /* Your label styles here */
`;

const Value = styled.div`
  /* Your value styles here */
`;

const Author = styled.div`
  text-align: start;
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
  font-size: 14px;
`;

const Text = styled.div`
  /* Your text styles here */
 
  margin-bottom:2px;
  margin-left: 15px;
`;
const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
const RowWithBorder = styled(Title)`
  border-top: 1px solid black; 
  border-bottom: 1px solid black; 
  display:flex;
  justify-content: space-between;
  padding: 5px; 
  width:100%;
`;

const ValueWithBorder = styled(Value)`
  border-top: 1px solid black; // Adjust the color as needed
  border-bottom: 1px solid black; // Adjust the color as needed
  padding: 5px  `; // Add padding to improve spacing

export default PrintableDocument;
