
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchLedgerBalances } from "../../Features/api/api"
import styled from "styled-components"
import Spinner from "../../SharedFns/SpinnerElement"
import DateInputForm from './DateInputForm';
import { useState } from "react"
const TrialBalance=()=>{

const dispatch=useDispatch()
useEffect( ()=>{
  dispatch(fetchLedgerBalances())
}, [dispatch])
const isLoading=useSelector(state=>state.getLedgers?.loading)
const journalEntries=  useSelector(state=> state.getLedgers.ledgerBalances?.trial_balance??[])
const [date, setDate]= useState(new Date())
const formattedDate= `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`
const [isDateFormOpen, setIsDateFormOpen ]=useState(false)
const accountCodeToName = {
  'bank': 'Equity Bank',
  'monthly_contribution': 'Monthly Contributions',
  'loan_receivable': 'Loan Receivables',
  'interest_receivable': 'Interest Receivables',
  'fine':'Fines',
  'expense':'Expenses',
  'investment':'Investments',
  'good_will':'Good Will',
  'interest_income': 'Interest Received',
  'tax': 'Tax Expense', 
  'income_summary':'Income Summary',
  "end_year_party": "End of Year Party",
  "server_expense": "Server Expense",
  'certificate_renewal': 'Eye20 Certificate Renewal',
  'retained_earnings':'Retained Earnings',
    'ordinary_shares':'Ordinary Shares',
    'dividends_payable':'Dividends Payable',
    'social_welfare': 'Social Welfare',
  "other_expense": "Other Expenses",
  'transaction_cost':'Transaction Costs',
  'other_income':'Other Income',
  'other_statistic':'Other Statistic from Group Recon',
  'equity_fixed_deposit':'Equity Bank Fixed Deposit',
    'fixed_deposit_interest':'Fixed Deposit Interest',
    'fine_receivable':'Fine Receivables',
    'interst_income_from_exit_member': 'Income from Manually cleared Loans',
    'interest_income':'Interest Income',
    'income_from_exited_member':'Income From Exited Members'

  
};
  // Function to calculate total debit or credit for a specific account
  const calculateTotal = (account, type) => {
    return journalEntries.reduce((total, entry) => {
      const amount = type === 'debit' ? entry.debit_amount : entry.credit_amount;
      return entry.account === account ? total + parseFloat(amount) : total;
    }, 0);
  };
  // Get unique accounts from all journal entries
  const uniqueAccounts = Array.from(new Set(journalEntries.map(entry => entry.account)));
  const uniqueAccountNames = uniqueAccounts.map(accountCode => accountCodeToName[accountCode]);
 const totalDebit= uniqueAccounts.reduce((total, account) => total + calculateTotal( account, 'debit'), 0)
 const totalCredit=uniqueAccounts.reduce( (total, account)=> total + calculateTotal( account, 'credit'), 0)
 




    return (
         <Container>


        <h1>Trial Balance</h1>

       <DateInputForm setIsDateFormOpen={setIsDateFormOpen} setDate={setDate} />
     
        
        <div>
        {isLoading && <Spinner/>
      }
      </div>
     
      <Table>
        <thead>
          <tr>
            <TableHeader>Account</TableHeader>
            <TableHeader>Debit</TableHeader>
            <TableHeader>Credit</TableHeader>
          </tr>
        </thead>
        <tbody>
        {uniqueAccounts.map(account => (
            <tr key={account}>
              <TableCell>{accountCodeToName[account]}</TableCell>
              <TableCell>{calculateTotal(account, 'debit').toLocaleString()}</TableCell>
              <TableCell>{calculateTotal(account, 'credit').toLocaleString()}</TableCell>
            </tr>
          ))}
          <tr>
            <TableCell>Total</TableCell>
            <TableCell>{totalDebit.toLocaleString()}</TableCell>
            <TableCell>{totalCredit.toLocaleString()}</TableCell>
          </tr>
        </tbody>
      </Table>
      </Container>
    )
}

const Table = styled.table`
border-collapse:collapse;
width:80%;

margin-bottom:80px;
margin-top:80px;
`
const TableHeader = styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;

`
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
  width:12px;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}
`

const Container=styled.div`
width:100%;
height:100%;
overflow-y:scroll;
display: flex;
flex-direction:column;
align-items:flex-start;
 /* Hide the vertical scrollbar for webkit-based browsers */
 ::-webkit-scrollbar {
    width: 0.5em;
  }

  /* Track (the area where the scrollbar is) for webkit-based browsers */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Handle (the draggable part of the scrollbar) for webkit-based browsers */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover for webkit-based browsers */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Hide the vertical scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Optional: Add padding to the content area to prevent overlap with scrollbar */
  padding-right: 0.5em;
  @media (max-width:768px) {
  overflow-x:scroll;
  
}
`

const Button = styled.button`
cursor:pointer;
align-items:center;
margin-left:20px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
`



export default TrialBalance