import { createSlice } from "@reduxjs/toolkit";
import { fetchContributionsToApprove } from "../api/api";


const initialState={
    loading:false,
    error:{},
    contributionsToApprove: null
}

const getContributionsToapprove=createSlice({
    name:'getContributionsToApprove',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(fetchContributionsToApprove.pending, (state)=>{
            state.loading=true
            state.error={}
            state.contributionsToApprove=null
        })
        builder.addCase(fetchContributionsToApprove.rejected, (state, action)=>{
            state.loading=false
            state.error=action.payload
            state.contributionsToApprove=null
        })
        builder.addCase(fetchContributionsToApprove.fulfilled, (state,action)=>{
            state.loading=false
            state.error={}
            state.contributionsToApprove=action.payload
        })
    }
})
export default getContributionsToapprove.reducer