import styled from "styled-components"

import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { fetchMonthlyContributions, fetchBalanceSheet, getActiveUserCount } from "../Features/api/api"


import MonthlyContributions from "./ContributionsPayments"
import { numberWithCommas } from "../SharedFns/Functions"
import Spinner from "../SharedFns/SpinnerElement"
import UserFinancialDocument from "./documents/UserFinancialSummary"
import Modal from "../SharedFns/Modal"
import { useState } from "react"



const Home=(props)=>{
    
    const dispatch=useDispatch()
    const currentDate= new Date()
    const formattedDate= `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    useEffect( ()=>{
      dispatch( getActiveUserCount())
        dispatch( fetchMonthlyContributions())
        dispatch(fetchBalanceSheet(formattedDate))
        
     },[dispatch])
     const[isModalOpen, setIsModalOpen]=useState(false)

    const username=useSelector(state=> state.userData.user?.user.username)
    const user=useSelector(state=> state.userData.user?.user)
    const isLoading=useSelector(state=>state.getbalanceSheet?.loading)
    
    const balanceSheetData = useSelector(state => state.getbalanceSheet?.balanceSheetData.balance_sheet??[])
    const users_shared_interest_data=useSelector(state => state.getbalanceSheet?.balanceSheetData?.users_shared_interest_data??[])
    // const balanceSheetData = useSelector(state => state.getbalanceSheet?.balanceSheetData.balance_sheet??[]);
    const interestReceivables=balanceSheetData.assets?.interest_receivable.balance
    const retained_earnings=(-1*balanceSheetData.equities?.retained_earnings.balance).toFixed(2)
    const userCount=useSelector(state => state.activeUsersCount?.users?.active_users_count)
    
    const shared_earning= (parseFloat(retained_earnings/userCount)|| 0.00)*0.63
    const bankBalancerAccount=useSelector(state => state.getbalanceSheet?.balanceSheetData?.exited_Member_income?.balance);
    console.log(bankBalancerAccount)
    const bankBalance= (balanceSheetData.assets?.bank.balance)  + (interestReceivables) 
    const ismenutoggled = useSelector( state=> state.userData.isMenuToggled)
    
    const contributions=useSelector( (state)=> state.getContributions.contributions?.monthly_contributions)??[]
    const total_paid_contributions = parseFloat(useSelector(state => state.getContributions.contributions?.total_amount_paid)) || 0.00;
    const cleared_users_balance= (total_paid_contributions + 0.00).toFixed(2)||0.00
    const total_savings_and_profit = (total_paid_contributions + shared_earning).toFixed(2) || 0.00;
    const filteredContributions=(Array.isArray(contributions))? contributions.filter( contribution=>{
        const  contributionMonth=new Date(contribution.contribution_month)
        return contributionMonth ; 
        }).sort((a,b)=>{
          return new Date(b.contribution_month)- new Date(a.contribution_month) }):[]
   const latestContributions=filteredContributions[0]     
   
 const latestAmount=  latestContributions?.amount_paid
 const groupYear=latestContributions?.group_year
 const handleSummary=(userId)=>{
     
  setIsModalOpen(true)
 
    // dispatch( adminActivateUser({user_id}))

  }
  
    return(
      
        <Container>
         
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {<UserFinancialDocument isLoading={isLoading} setIsModalOpen={setIsModalOpen} user={user} totalUserContributions={total_paid_contributions} balanceSheetData={balanceSheetData} users_shared_interest_data={users_shared_interest_data} userCount={userCount}  />}
            </Modal> 
        
           <Introduction ismenutoggled={ismenutoggled}>
           <h1 > {username && `Welcome, ${username}`} </h1> 
             <p> Below is your accounts information </p>
           </Introduction>
            
           

            <Accounts ismenutoggled={ismenutoggled}>
                <Individual ismenutoggled={ismenutoggled} onClick={() => handleSummary(user)}>
                    <Balances>
                    <p style={{marginLeft:'10px'}}>{username}'s Account</p> 
                     <h1 style={{marginTop:'1px', marginLeft: '10px'}}>{(user.has_cleared || !user.is_active) ? numberWithCommas(cleared_users_balance) : numberWithCommas(total_savings_and_profit)} KES</h1>
                    </Balances>
                    <Narration ismenutoggled={ismenutoggled}>
                        <Particulars>
                          <div>
                            {isLoading && <Spinner/>}
                          </div>
                        {latestContributions && (
                             <h3>Upto: {latestContributions.group_year} <p> Amount: {numberWithCommas(latestAmount)}</p></h3>
                            
                           
                          
                          
                          
                                       )}
                        </Particulars>
                        <p> Monthly.Savings</p>
                    </Narration>
                    
                </Individual>
                {(!user.has_cleared && user.is_active) &&<Group>
                 <Balances>                  
                 
                
                 <p>Eye20 Group Account</p>
                 
                 <h1> {bankBalance && numberWithCommas(bankBalance.toFixed(2))} KES </h1>
                 </Balances>
                 <Narration>
                        <p> Eye20 Group.Savings</p> 
                 </Narration> 
                 
                </Group>}
            </Accounts>
           
        </Container>
            
     

    )
}


const Container=styled.div`

display:flex;

flex-direction:column;
align-items:flex-start;
overflow:hidden;
margin-left: 70px;
margin-right:0px;
height:100%;
width:100%;
padding-right:0px;
@media (max-width: 768px) {
  margin-left:5px;
  
}
`
const Introduction= styled.div`
align-items:center;
margin-left:20px;
border-bottom: solid 1px #E2725B ;

margin-bottom:30px;
transition: width 0.3s ease-out;
width:${props => props.ismenutoggled? '100%': '100%'};

h1{
    margin-top: 10px;
    margin-bottom: 2px;
}
p{
    margin-top: 2px;
    align-items:center;
    margin-left:auto;
  
}
@media (max-width:768px) {
    margin-left: 10px;
    padding-right:10px;
    align-items:center;
    }
    h1{
        font-size:14px;
    }
    p{
        font-size:12px;
        
}
`




// const Accounts=styled.div`
// display:flex;
// flex-direction: row;
// align-items:flex-start;
// justify-content: space-between;
// margin-top: 15px;
// margin-left:5px;
// padding-left:10px;
// height:40%;
// transition: width 0.3s ease-out;
// width:${props => props.ismenutoggled? '100%': '80%'};
// border-bottom: solid 1px #E2725B ; 

// @media (max-width: 768px) {
//     display: flex;
//     flex-direction:column;
//     align-items:flex-start;
//     border-bottom:none;
//     transition: width 0.3s ease-out;
//     width:${props => props.ismenutoggled? '110%': '110%'};
//     margin-left: 0px;
//     padding-left: 2px;
   
    
// }
// `
// const Individual=styled.div`
// margin-right: 2px;
// display:flex;
// flex-direction:column;
// align-items:flex-start;
// padding: 5px;
// opacity:1;
// box-shadow:rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;
// transition: opacity  border 3s ease;
// margin-left:0px;
// background-color:#034694;
// height: 60%;
// width: 25%;
// color:white;
// cursor:pointer;
// font-size:15px;
// font-weight:bold;
// border:none;
// border-radius:5%;
// width:${props => props.ismenutoggled? '30%': '30%'};
// box-shadow:rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;


// &:hover{
// opacity:0.8;

// border: solid 1px #f9f9f9;}

// @media (max-width: 768px) {
//     width: 50%;
    
  
//     margin-top: 20px auto;
//     margin-left:20px;
//     margin-right:0px;
//     transition: width 0.3s  ease-out;
//     &:hover{
//         width:70%;
//     }
  
    
// }


// `

// const Group=styled.div`
// margin-right:auto;
// margin-left: 2%;
// display:flex;
// flex-direction:column;
// align-items:flex-start;
// padding: 5px;
// opacity:1;
// box-shadow:rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;
// transition: opacity  border 3s ease;
// margin-left:50px;
// background-color:#034694;
// height: 60%;
// width: 25%;
// color:white;
// cursor:pointer;
// font-size:15px;
// font-weight:bold;
// border:none;
// border-radius:5%;

// width:${props => props.ismenutoggled? '30%': '30%'};

// &:hover{
// opacity:0.8;
// border: solid 1px #f9f9f9;
// }
// @media (max-width: 768px) {
//     width: 50%;
//     margin: 20px auto;
//     margin-left:20px;
//     transition: width 0.3s  ease-out;
//     &:hover{
//         width:70%;}
    
// }
// `
// const Balances=styled.div`
// display: flex;
// margin-left: 5px;
// width: 70%;
// height: 10%;
// flex-direction:column;
// flex-wrap: nowrap;
// align-items:flex-start;
// position:relative;
// h1{
//     margin-top: 1px;
//     margin-left:5px;
// }
// p{
//     margin-top: 2px;
//     margin-left:7px;
// }
// @media (max-width: 768px) {
//     width: 50%;
//    h1 { font-size: 12px;

//     }
//     p{ font-size:10px;
//        width: 100%; 
//     }
// }
// `
// const Narration=styled.div`
// height:4%;
// display:flex;
// flex-direction:row; 
// width: 100% ;
// align-items:flex-start;
// justify-content:space-between;

// margin-bottom:2px;
// padding-top: 100px;
// /* padding-left: 18px; */

// p{
//     font-size: 10px;
//     color:#f9f9f9;
//     padding-bottom: 4px;
// }
// h3{
//     font-size:9px;
//     padding-left: 70px;
//     padding-bottom: 2px;
//     padding-top:4px;
//     line-height:1px;
//     margin-left: 5px;
// }
// @media (max-width: 768px) {
//     padding-bottom:2px;
//     margin-bottom: 20px;
//     width:100%;
//     height:4%;
//     align-items:space-between ;
//     margin-left:0px;
//     p{ font-size:8px;}
//     h3{
//         margin-left: 2px;
//         padding-left: 20px;
//         flex-wrap:nowrap;
//     }
//     transition: width 0.3s ease-out;
//     width:${props => props.ismenutoggled? '100%': '100%'};
// }
// `
// const DateUpto=styled.span`
// width:80%;
// height: 80%;
// display:flex;
// position:relative;
// flex-direction:row; 
// margin-left: 50px;
// padding-left:20px;
// margin-bottom:5px;
// align-items:flex-end;
// h3{
//     font-size:10px;
//     padding-left: 70px;
//     padding-bottom: 2px;
//     padding-top:0px;
//     line-height:1px;
// }

// `
// const Particulars=styled.div`
// display:flex;
// flex-direction: column;
// justify-content:flex-start;
// align-items:center;
// flex-wrap: nowrap;
// margin-left: 2px;


// .p{
//     font-size: 10px;
//     color:#f9f9f9;
//     padding-bottom: 4px;
    
// }
// .h3{
//     font-size:10px;
//     padding-left: 70px;
//     padding-bottom: 70px;
  
//     line-height:px;
//     margin-left: 1px;
// }

// `
// export default Home
const Accounts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 5px;
  padding-left: 2px;
  height: 40%;
  transition: width 0.3s ease-out;
  width: ${(props) => (props.ismenutoggled ? '100%' : '100%')};
  border-bottom: solid 1px #e2725b;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 35%;
    border-bottom: none;
    transition: width 0.3s ease-out;
    width: ${(props) => (props.ismenutoggled ? '100%' : '100%')};
    margin-left: 0px;
    padding-left: 0px;
  }
`;

const Individual = styled.div`
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  padding: 2px;
  opacity: 1;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  transition: opacity border 3s ease;
  margin-left: 0px;
  background-color: #034694;
  height: 60%;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 5%;
  width: ${(props) => (props.ismenutoggled ? '25%' : '25%')};
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;

  &:hover {
    opacity: 0.8;
    border: solid 1px #f9f9f9;
  }

  @media (max-width: 768px) {
    width: 65%;
    height:43%;
    margin-top: 20px auto;
    margin-left: 20px;
    margin-right: 0px;
    transition: width 0.3s ease-out;
    &:hover {
      width: 70%;
    }
  }
`;

const Balances = styled.div`
  display: flex;
  
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  margin-left: 2px;
  height: 10%;

  h1{
    margin-top: 2px;
    margin-left: 2px;
  }
  p {
    margin-top: 2px;
    margin-left: 2px;
    
  }

  @media (max-width: 768px) {
    width: 50%;
    margin-bottom: 5px;
    margin-left:0px;
    h1 {
      font-size: 8px;
    }
    p {
      font-size: 6px;
    }
  }
`;




const Narration = styled.div`
  height: 4%;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3px;
  padding-top: 100px;

  p {
    font-size: 10px;
    color: #f9f9f9;
    padding-bottom: 4px;
  }

  h3 {
    font-size: 9px;
    padding-left: 2px;  // Adjusted padding
    padding-bottom: 2px;
    padding-top: 4px;
    line-height: 1px;
    margin-left: 2px;
    white-space: nowrap;  // Added property to prevent text from wrapping
    overflow: hidden;  // Added property to hide any overflowing text
    text-overflow: ellipsis;  // Added property for ellipsis when text overflows
  }

  @media (max-width: 768px) {
    padding-bottom: 12px;
    margin-bottom: 20px;
    width: 100%;
    height: 4%;
    align-items: space-between;
    margin-left: 0px;
    padding-top: 50px;
    p {
      font-size: 8px;
      padding-left:5px;
    }
    h3 {
      margin-left: 2px;
      padding-left: 2px;
      flex-wrap: nowrap;
    }
    transition: width 0.3s ease-out;
    width: ${props => (props.ismenutoggled ? '100%' : '100%')};
  }
`;


const DateUpto = styled.span`
  width: 80%;
  height: 80%;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-left: 50px;
  padding-left: 20px;
  margin-bottom: 5px;
  align-items: flex-end;

  h3 {
    font-size: 10px;
    padding-left: 70px;
    padding-bottom: 2px;
    padding-top: 0px;
    line-height: 1px;
  }
`;

const Particulars = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-left: 2px;
  width:100%;

  .p {
    font-size: 10px;
    color: #f9f9f9;
    padding-bottom: 4px;
  }

  .h3 {
    font-size: 10px;
    padding-left: 70px;
    padding-bottom: 70px;
    line-height: px;
    margin-left: 1px;
  }
`
const Group=styled.div`
margin-right:auto;

margin-left: 2%;
display:flex;
flex-direction:column;
align-items:flex-start;
padding: 5px;
opacity:1;
box-shadow:rgb(0 0 0 /69%) 0px 26px 30px -10px, rgb(0 0 0 /73%) 0px 16px 10px -10px ;
transition: opacity  border 3s ease;
margin-left:50px;
background-color:#034694;
height: 60%;
width: 25%;
color:white;
cursor:pointer;
font-size:15px;
font-weight:bold;
border:none;
border-radius:5%;

width:${props => props.ismenutoggled? '25%': '25%'};

&:hover{
opacity:0.8;
border: solid 1px #f9f9f9;
}
@media (max-width: 768px) {
    width: 65%;
    height:42%;
    margin: 20px auto;
    margin-left:20px;
    transition: width 0.3s  ease-out;
    &:hover{
        width:70%;}
    
}
`

export default Home;
