import { createSlice } from "@reduxjs/toolkit";

import { fetchAllContributions} from "../api/api";



const initialState={
    loading: false,
    allContributions: null,
    error:{}
}

const getAllContributions=createSlice({

    name:'getAllContributions',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase( fetchAllContributions.pending, (state)=>{
            state.loading=true
            state.allContributions=null
            state.error={}
        }

        )
        builder.addCase(  fetchAllContributions.rejected, (state, action)=>{
            state.loading=false
            state.allContributions=null
            state.error=action.payload
        }

        )
        builder.addCase( fetchAllContributions.fulfilled, (state, action)=>{
            state.loading=false
            state.allContributions=action.payload
            state.error={}        }

        )
    }

}
   
)
export default getAllContributions.reducer