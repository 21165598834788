import { createSlice } from "@reduxjs/toolkit";

import { fetchLoanApplications } from "../api/api"; 


const initialState={
    loading: false,
    error:{},
    loanApplications:[]
}

const getLoanApplicationSlice=createSlice({
    name:'getLoanApplications',

    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase(
            fetchLoanApplications.pending, (state)=>{
                state.loading=true
                state.error={}
                state.loanApplications=[]
            }
        )
        builder.addCase(
            fetchLoanApplications.rejected, (state, action)=>{
                state.loading=false
                state.error=action.payload
                state.loanApplications=[]
            }

        )
        builder.addCase(
            fetchLoanApplications.fulfilled, (state, action)=>{
                state.loading=false
                state.error={}
                state.loanApplications=action.payload
            }
        )
    }

}
   

)
export default getLoanApplicationSlice.reducer