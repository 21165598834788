import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import "react-datepicker/dist/react-datepicker.css"; 
import Landing from './pages/Landing';
import SignUpForm from './pages/SignUpForm';
import SignIn from './components/SignIn';
import ActivateAccount from './pages/ActivateAccount';
import Contributions from './components/Contributions';
import DashboardLayout from './components/DashboardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate, Outlet } from "react-router-dom";
import { fetchLoans, getUser, fetchLoanPayments,fetchMonthlyContributions, fetchLoanPaymentsToApprove, fetchBalanceSheet, fetchProfitAndLossReport, getActiveUserCount } from './Features/api/api';
import { useEffect } from 'react';
import { setUserIsAuthenticated } from './Features/User/userDataSlice';

import Home from './components/Home';
import News from './components/News';
import LoanList from './components/LoanList';
import LoanForm from './components/LoanForm';
import LoanPaymentForm from './components/LoanPaymentForm';
import Transactions from './components/LoanPaymentTransactions';
import AllApprovals from './components/AllApprovals';
import MonthlyContribApprovals from './components/MonthlyContribPayApprovals';
import LoanPaymentsApprovals from './components/LoanPaymentApprovals';
import LoanApplicationApprovals from './components/LoanApplicationApprovals';
import MonthlyContributions from './components/ContributionsPayments';
import OverAllTransactions from './components/OverallTransactions';
import LoanPaymentTransactions from './components/LoanPaymentTransactions';
import TrialBalanceSlice from './Features/Accounting/trialBalanceSlice';
import TrialBalance from './components/accounting/TrialBalance';
import BalanceSheet from './components/accounting/BalanceSheet';
import Reports from './components/accounting/Reports';
import ProfitAndLoss from './components/accounting/Profit&Loss';
import LedgerEntryForm from './components/accounting/LedgerEntryForm';
import LoanApplications from './components/LoanApplications';
import ResetPasswordFom from './pages/ResetPasswordForm';
import Admin from './components/admin';
import Directors from './components/Directors';
import Commoners from './components/commoners';
import FinancialSummary from './components/FinancialSummary';
import LoanSummary from './components/LoanSummary';

function App() {
  const dispatch=useDispatch()
  const userLoading=useSelector(state=> state.userData.loading)
  const session_cookie = localStorage.getItem("session_cookie");
  const currentDate=new Date()
  const user_group=useSelector(state=>state.userData?.user?.user?.user_groups)??[]
  const isAuthorizedAdmin = user_group.includes('Chairperson');
  const formattedDate= `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
  useEffect(() => {
    

    // Check if the session cookie exists in local storage
    if (session_cookie) {
      // Perform asynchronous verification
      async function verifyUser() {
        try {
          // Verify the user's authentication state
          await dispatch(getUser());
          await dispatch(getActiveUserCount())
          await dispatch(setUserIsAuthenticated(true)); // Set user as authenticated
          await  dispatch( fetchMonthlyContributions())
           await dispatch(fetchLoanPayments())
           await dispatch(fetchLoanPaymentsToApprove())
           await dispatch(fetchProfitAndLossReport({'start_date':formattedDate, 'end_date':formattedDate}));
           await dispatch(fetchBalanceSheet(formattedDate))
         
          

          
        } catch (error) {
          // Handle errors, e.g., expired session
          console.error("Verification failed:", error);
        }
      }

      // Call the verification function
      verifyUser();
    }
  }, [dispatch, session_cookie]);

  const userIsAuthenticated = useSelector(state => state.userData.userIsAuthenticated)
  const isAuthorizedApprover = user_group.includes('Sec-Gen') || user_group.includes('Treasurer');

  const ContributionRoutes =()=>{
    return(
      <Routes>
        <Route path='/' element={<Contributions/>}/>
      </Routes>
    )
  }

  const LoanRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<LoanList />} />
        <Route path="apply-loan" element={<LoanForm />} />
        <Route path="repay-loan/:LoanId" element={<LoanPaymentForm />} />
      </Routes>
    );
  };
  const FinacialSummaryRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<FinancialSummary />} />
        <Route path="loan-summary" element={<LoanSummary />} />
        {/* <Route path="repay-loan/:LoanId" element={<LoanPaymentForm />} /> */}
      </Routes>
    );
  };
  const AllApprovalsRoutes=()=>{
    return(
      <Routes>
         <Route path="/" element={<AllApprovals/>}/>
         <Route path='approve-monthly-contributions' element={<MonthlyContribApprovals/>} />
         <Route path='approve-loan-payments' element={<LoanPaymentsApprovals/>}/>
         <Route path='approve-loan-applications' element={<LoanApplicationApprovals/>}/>
      </Routes>   
    )
  }
  const TransactionRoutes=()=>{
    return(
      <Routes>
        <Route path='/' element={<OverAllTransactions/>}>
        <Route path='/loan-payments' element={<LoanPaymentTransactions/>}/>
        <Route path='/contrib-payments' element={<MonthlyContributions/>}/>
        <Route path='/loan-applications' element={<LoanApplications/>} />
        </Route>
         
      </Routes>
    )
  }
  const AdminRoutes=()=>{
    return(
      <Routes>
        <Route path ='/' element={<Admin/>}/>
        <Route path ='/directors' element={<Directors/>}/>
        <Route path ='/commoners' element={<Commoners/>}/>
      </Routes>
    )
  }
  const AccountingRoutes=()=>{
    return(
      <Routes>
        <Route path= '/' element={<Reports/>}>
        <Route path= '/trial-balance' element={<TrialBalance/>}/> 
        <Route path='/balance-sheet' element={<BalanceSheet/>}/>
        <Route  path='/profit-loss' element={<ProfitAndLoss/>}/>
        <Route  path='/post-ledger'  element={<LedgerEntryForm/>} />
        </Route>
         
      </Routes>
    )
  }
// const DashboardLayoutRoutes=()=>{
//   return(
//     <Routes>
//       <Route   index element={<Home />} />
//               <Route path='news' element={<News />} />
//               <Route   path= 'loans/*' element={<LoanRoutes/>}/>
//       <Outlet/>
//     </Routes>
//   )
// }

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route path='/sign-up' element={<SignUpForm />} />
          <Route path='/user/activate/:activation_token/' element={<ActivateAccount />} />
          <Route path='/user/forgot-password/:password_reset_token/' element={<ResetPasswordFom />} />

          {/* Authenticated Routes */}


          {userIsAuthenticated ? (
            <Route path='/dashboard/*' element={<DashboardLayout />}>
            
              <Route   index element={<Home />} />
              <Route path='news' element={<News />} />
              <Route   path= 'loans/*' element={<LoanRoutes/>}/>
               <Route  path ='transactions/*' element={<TransactionRoutes/>} />
               {isAuthorizedApprover && <Route path='approvals/*'  element={<AllApprovalsRoutes/>}/>}
               {isAuthorizedApprover && <Route path='financial-summary/*'  element={<FinacialSummaryRoutes/>}/>}
               <Route path='contributions/*' element={<ContributionRoutes/>}/>
               <Route  path = 'accounting/*' element={<AccountingRoutes/>}/>
              { isAuthorizedAdmin && <Route  path = 'admin/*' element={<AdminRoutes/>}/>}
              {/* <Route index element={<LoanList />} />
               
                <Route path='apply-loan' element={<LoanForm/>}/>
                <Route path='repay-loan' element={<LoanPaymentForm/>}/> */}
               
              </Route>
              
             
             ) : (!userLoading && <Route
            path='/dashboard/*'
            element={ <Navigate to= "/"/>}/>
            ) }








        </Routes>

       








      </Router>
    </div>
  );
}

export default App;
