import styled from "styled-components"
import { useSelector } from "react-redux"
import { useState } from "react"
import LoanPaymentApprovalForm from "./LoanPaymentApprovalForm"
import Modal from "../SharedFns/Modal"
import { getRequiredData, getRequiredUserData } from "../SharedFns/Functions"
import Spinner from "../SharedFns/SpinnerElement"
import ContributionApprovalForm from "./ContributionApprovalForm"

const ContributionApprovals = () => {
    const pendingContributions = useSelector(state=>state.getContributionsToApprove.contributionsToApprove)??[]
    const isLoading =useSelector(state=>state.approveContribution?.loading)
    const user_group=useSelector(state=>state.userData.user.user.user_groups)??[]
    const requiredUserFields = ["username","first_name", "last_name"]
    const [isModalOpen, setIsModalOpen]=useState(false)
    const [selectedTransactionId, setSelectedTransactionId]=useState(null)
    const contributionsToApprove = (pendingContributions ?? []).filter(payment => {
        const approvedOfficial = payment.approval?.approved_official;
        return approvedOfficial === null || approvedOfficial !== user_group[0];
      }) ?? [];

      const isLaterPaymentPending = (contribution) => {
        const laterContributions = contributionsToApprove.filter(contrib => (contrib.user === contribution.user) && (contrib.payment_date > contribution.payment_date));
        return laterContributions.length > 0;
    };

    const HandlePayLoanClick = (transactionID) => {

        setSelectedTransactionId(transactionID)
         setIsModalOpen(true)
        
    }
    

    const paymentFinder=(desiredId)=>(payment)=> payment.contribution_id===desiredId


    return (

        <Container>
            <div>
                {isLoading && <Spinner/>}
            </div>
            <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}>
               {<ContributionApprovalForm transactionId={selectedTransactionId} setIsModalOpen={setIsModalOpen}/>}
            </Modal>
            <h2> Pending COntribution Payment Transactions</h2>
            <p>Below is transactions waiting your actions</p> {
                Array.isArray(contributionsToApprove) && contributionsToApprove.length > 0 ?

                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>
                                    Member's Names
                                </TableHeader>
                                <TableHeader>
                                    Transaction ID
                                </TableHeader>
                                <TableHeader>
                                    Transaction Initiation Date
                                </TableHeader>
                                <TableHeader>Amount Paid</TableHeader>
                                <TableHeader>
                                    MPesa/Bank Ref code
                                </TableHeader>
                                <TableHeader>
                                    Action
                                </TableHeader>


                            </tr>
                        </thead>
                        <tbody>
                            {contributionsToApprove.map(
                                contribution => (
                                    <tr key={contribution.contribution_id}>

                                        {/* <TableCell>{payment.approval && payment.approval.approved_official?payment.approval.approved_official:'--'}</TableCell> */}
                                        <TableCell>{getRequiredUserData(contribution.contribution_id, pendingContributions, paymentFinder,requiredUserFields)}</TableCell>
                                        <TableCell>{contribution.contribution_id}</TableCell>
                                        <TableCell>{getRequiredData(contribution.contribution_id, pendingContributions, paymentFinder, 'payment_date')}</TableCell>
                                        <TableCell>{getRequiredData(contribution.contribution_id, pendingContributions, paymentFinder,'amount_paid')}</TableCell>
                                        <TableCell>{getRequiredData(contribution.contribution_id, pendingContributions, paymentFinder, 'payment_Reference_Code')}</TableCell>


                                        <TableCell>
                                            <Button disabled={isLaterPaymentPending(contribution)} onClick={() => HandlePayLoanClick(contribution.contribution_id)}>
                                                Approve/Reject
                                            </Button>
                                        </TableCell>

                                    </tr>
                                )
                            )

                            }

                        </tbody>
                    </Table> : <p>No transactions available in the table</p>}
        </Container>

    )


}
const Container = styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
overflow-y:scroll;
`
const Table = styled.table`
border-collapse:collapse;
width:100%;

margin-bottom:100px;
`
const TableHeader = styled.th`
border: solid 1px #000;
background-color:#f2f2f2;
padding:10px;

`
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
  width:12px;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}
`
const Button = styled.button`
cursor:pointer;
align-items:center;
margin-left:20px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
`

export default ContributionApprovals