import styled from "styled-components"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Outlet, useNavigate, useLocation, Navigate } from "react-router-dom"
import { fetchLoanPayments } from "../Features/api/api"
import { removeTimeFromDateString } from "../SharedFns/Functions"
import { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import Modal from "../SharedFns/Modal"
import OutletModal from "../SharedFns/OutletModal"
import { selectUserGroup } from "../Features/Finances/getLoanPaymentsSlice"
const LoanPaymentTransactions = () => {
    const dispatch = useDispatch()
    useEffect(() => { dispatch(fetchLoanPayments()) }, [dispatch]


    )
    const loanPayments = useSelector((state) => state.getLoanPayments.loanPayments)
    const user_groups = useSelector(state => state.userData.user.user.user_groups)
    console.log(user_groups)
    const isSecGen = user_groups?.includes('Sec-Gen')
    const isTreasurer = user_groups?.includes('Treasurer')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showApprovalDetails, setShowApprovalDetails] = useState(false)
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const handleStartDateChange = (date) => {
        setStartDate(date)
    }
    const aprovalCodeToName={
       'pending':'PENDING',
      'pending_approval': 'PENDING APPROVAL',
      'rejected':'REJECTED',
      'approved':'APPROVED',
      'PENDING_APPROVAL':'PENDING APPROVAL',
      'emergency_loan':'Emergency Loan',
      'normal_loan':'Normal Loan'
  }
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const handleEndDateChange = (date) => {
        setEndDate(date)
    }
    const getApprovalStatus = (official, payment) => {
        const { status, approval } = payment
        if (approval) {
            if (approval.approved_official === official) {
                return approval.second_official_group_action === "pending_approval" ? approval.first_official_group_action : approval.second_official_group_action;
            }
            else if (approval.approved_official !== official) {
                return approval.second_official_group_action !== "pending_approval" ? approval.first_official_group_action : approval.second_official_group_action
            }


        }

        else {
            return 'PENDING_APPROVAL'
        }



    }
    const handlePaymentClick = (payment) => {
        setSelectedPayment(payment)
        setShowApprovalDetails(true)
    }
    const getRejectReason = (official, payment) => {
        const { approval } = payment;
        if (approval) {
            if (approval.approved_official === official) {
                return approval.second_official_group_reject_reason? approval.second_official_group_reject_reason: approval.first_official_group_reject_reason
            } else {
                return approval.first_official_group_reject_reason;
            }
        }
    };
    
    const handleRejectionReasonClick = (reject_reason) => {
        // Check if the contribution has a rejection reason
        if (reject_reason) {
            // Show a modal or alert with the rejection reason
            alert(reject_reason);
        } else {
            // If there's no rejection reason, display a message
            alert("No rejection reason available");
        }
    }
    const handleFilterButtonClick = () => {
        try {
            if (!Array.isArray(loanPayments)) {
                throw new Error(' You have not made any loan payments yet.')
            }
            if (startDate > endDate) {
                throw new Error('End Date must be a date later than Start Date');
            }

            const filtered = loanPayments?.filter((payment) => {
                const transactionDate = new Date(payment.payment_date);

                // Check if the transaction date is within the selected range
                return transactionDate >= startDate && transactionDate <= endDate;
            }).sort((a, b) => {
                return new Date(b.payment_date) - new Date(a.payment_date)
            });

            setFilteredTransactions(filtered);
        } catch (error) {
            alert(error.message);
            setEndDate(null);
        }
    };


    return (
        <Container>
            <h3> Filter your Loan Payment Transactions </h3>
            <p>Select start date and end date to filter desired transaction history and status</p>

            {/* <TransactionFilter>



                <div>
                    <label htmlFor="startDate"> Start Date:</label>
                    <DatePicker id='startDate' dateFormat='dd/MM/yyyy'  showYearDropdown
                      scrollableYearDropdown className="custom-datepicker" placeholderText='DD/MM/YYYY' selected={startDate} onChange={handleStartDateChange} />
                </div>
                <div>
                    <label htmlFor="endDate"> End Date:</label>
                    <DatePicker id='endDate' dateFormat='dd/MM/yyyy'  showYearDropdown
          scrollableYearDropdown placeholderText='DD/MM/YYYY' className= "custom-datepicker" selected={endDate} onChange={handleEndDateChange} />
                </div>

                <Button onClick={handleFilterButtonClick}> Filter Transactions</Button>





            </TransactionFilter> */}
            <TransactionFilter>
        <Pickers>
          <DatePickerContainer>
            <label htmlFor='StartDate'>Select Start Date:</label>
            <DatePicker
              
              id='StartDate'
              selected={startDate} 
              onChange={handleStartDateChange}
              dateFormat='yyyy-MM-dd'
              className='custom-datepicker'
              showYearDropdown
              scrollableYearDropdown
              required
            />
          </DatePickerContainer>

          <DatePickerContainer>
            <label htmlFor='EndDate'>Select End Date:</label>
            <DatePicker
              id='EndDate'
             
              selected={endDate} 
              onChange={handleEndDateChange}
              dateFormat='yyyy-MM-dd'
              className='custom-datepicker'
              showYearDropdown
              scrollableYearDropdown
              required
            />
          </DatePickerContainer>

          <Button onClick={handleFilterButtonClick}>Filter Transactions</Button>
        </Pickers>
      </TransactionFilter>
            {
                Array.isArray(filteredTransactions) && filteredTransactions.length > 0 ?

                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>
                                    Loan Name
                                </TableHeader>
                                <TableHeader>
                                    Transaction Initiation Date
                                </TableHeader>
                                <TableHeader>
                                    Amount Paid
                                </TableHeader>{
                                      !isSmallScreen && 
                                    <>
                                    <TableHeader>
                                     Treasurer's Approval Status
                                 </TableHeader>
                                  <TableHeader>
                                     Sec-Gen's Approval Status
                                  </TableHeader>
                                    </>
                                   
                                }
                                
                                <TableHeader>

                                    Approval Status
                                </TableHeader>
                                <TableHeader>
                                    Approval Date
                                </TableHeader>

                            </tr>
                        </thead>
                        <tbody>
                            {filteredTransactions.map(
                                payment => (
                                    <tr key={payment.payment_id}>

                                        {/* <TableCell>{payment.approval && payment.approval.approved_official?payment.approval.approved_official:'--'}</TableCell> */}
                                        <TableCell>{payment.loan.loan_name}</TableCell>
                                        <TableCell>{removeTimeFromDateString(payment.payment_date)}</TableCell>
                                        <TableCell>{payment.amount_paid}</TableCell>
                                        { !isSmallScreen && 
                                        <>
                                            <TableCell> {getApprovalStatus('Treasurer', payment) === 'rejected' && payment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>payment && payment.approval && handleRejectionReasonClick(getRejectReason('Treasurer', payment))}>
                                              {aprovalCodeToName[getApprovalStatus('Treasurer', payment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Treasurer', payment)]
                                      )}</TableCell>
                                              <TableCell> {getApprovalStatus('Sec-Gen', payment) === 'rejected' && payment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>payment && payment.approval && handleRejectionReasonClick(getRejectReason('Sec-Gen',payment))}>
                                              {aprovalCodeToName[getApprovalStatus('Sec-Gen', payment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Sec-Gen', payment)]
                                      )}</TableCell>
                                       
                                        </>                    }
                                        <TableCell>

                                            {isSmallScreen?<button style={{color:"#f9f9f9", backgroundColor:"#E2725B"}} onClick={() => handlePaymentClick(payment)}>view</button> : aprovalCodeToName[payment.status]}
                                            
                                           






                                        </TableCell>
                                        <TableCell>{(payment.approval ? removeTimeFromDateString(payment.approval.approval_date) : "Approval not complete")}</TableCell>


                                    </tr>
                                )
                            )

                            }

                        </tbody>
                    </Table> : <p>No transactions available for the selected period</p>}{

   isSmallScreen && (
                <OutletModal isOpen={showApprovalDetails} onClose={() => setShowApprovalDetails(false)}>
                    {selectedPayment &&
                        <PopTable>
                            <Table>
                                <thead>
                                    <tr> <TableHeader>
                                        Sec-Gen's Approval Status
                                    </TableHeader>
                                        <TableHeader>
                                            Treasurer's Approval Status
                                        </TableHeader>
                                        <TableHeader>
                                            Overall Approval Status
                                        </TableHeader>

                                    </tr>
                                </thead>
                                < tbody>
                                    <tr>
                                    <TableCell> {getApprovalStatus('Sec-Gen', selectedPayment) === 'rejected' && selectedPayment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>selectedPayment && selectedPayment.approval && handleRejectionReasonClick(getRejectReason('Sec-Gen',selectedPayment))}>
                                              {aprovalCodeToName[getApprovalStatus('Sec-Gen', selectedPayment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Sec-Gen', selectedPayment)]
                                      )}</TableCell>
                                    <TableCell> {getApprovalStatus('Treasurer', selectedPayment) === 'rejected' && selectedPayment.approval ? (
                                          <button style={{ color: "#f9f9f9", backgroundColor: "#E2725B" }} onClick={() =>selectedPayment && selectedPayment.approval && handleRejectionReasonClick(getRejectReason('Treasurer',selectedPayment))}>
                                              {aprovalCodeToName[getApprovalStatus('Treasurer', selectedPayment)]}
                                          </button>
                                      ) : (
                                          aprovalCodeToName[getApprovalStatus('Treasurer', selectedPayment)]
                                      )}</TableCell>
                                          
                                       
                                        <TableCell>
                                            {aprovalCodeToName[selectedPayment.status]}
                                        </TableCell>

                                    </tr>

                                </tbody>

                            </Table>
                        </PopTable>
                    }

                </OutletModal>)
            }

        </Container>
    )

}

const Container = styled.div`
height: auto;
padding: 20px;
width:100%;
@media (max-width: 768px) {
  padding:  10px 20px;
  
}
`;

// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   overflow-y: scroll;

//   @media (max-width: 768px) {
//     width: 100vw;
//     margin-bottom: 10px;
//     padding: 20px; /* Add padding to give some space on small screens */
//   }
// `;

const Table = styled.table`
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 100px;
  overflow-x:scroll;

  @media (max-width: 768px) {
    width: 5vw;
    overflow-x: scroll;
    padding-right:50px;
  }
`;

const TableHeader = styled.th`
  border: solid 1px #000;
  background-color: #f2f2f2;
  padding: 10px;
  font-size: 12px;

  @media (max-width: 768px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:9px;
  }
`;

const Pickers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start of the cross axis */
  justify-content: space-between; /* Distribute items along the main axis */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

const DatePickerContainer = styled.div`
  width: 30%; /* Set width for the date picker container */
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%; /* Make date picker full-width on small screens */
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
cursor: pointer;
align-items: center;
text-decoration: none;
display: flex;
justify-content: center;
cursor: pointer;
background-color: #e2725b;
color: #f9f9f9;
border: none;
border-color: #e2725b;
margin-top: 17px;
border-radius:10px;

@media (max-width: 768px) {
  width: calc(50% - 5px); /* Set width based on the date pickers' width */
  margin-top: 10px; /* Adjust spacing on small screens */
  border-radius:10px;
}
`;

const TransactionFilter = styled.div`
 display: flex;
 width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px; /* Adjust the vertical gap between pickers and table on larger screens */

  @media (max-width: 768px) {
    margin-bottom: 5px; /* Adjust the vertical gap between pickers and table on small screens */
    flex-direction: column;
    width:100%;
  }
`;

const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width: 768px) {
    width: 8px;
    height: 10%;
    font-size: 10px;
    padding:5px; 
    &.hide-on-small-screen {
      display: none; // hide this column on small screens
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  height: 70px;
  margin-left: 20px;
  border-bottom: solid 1px gray;
`;

const Linkto = styled(Link)`
  cursor: pointer;
  align-items: center;
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #e2725b;
  color: #f9f9f9;
  border: none;
  border-color: #e2725b;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height: 70px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px gray;
  margin-top: 20px;
  .h1 {
    margin-left: 0px;
    align-items: flex-start;
    display: flex;
    padding: 10px;
  }
`;

// const Button = styled.button`
//   cursor: pointer;
//   align-items: center;
//   margin-left: 20px;
//   text-decoration: none;
//   display: flex;
//   justify-content: flex-start;
//   cursor: pointer;
//   background-color: #e2725b;
//   color: #f9f9f9;
//   border: none;
//   border-color: #e2725b;
//   margin-right: 20px;
//   margin-bottom: 20px;
//   @media (max-width: 768px) {
//     height: 30px;
//     width: 100%; /* Make the button full-width on small screens */
//     font-size: 7px;
//     margin-left: 0;
//     margin-right: 0;
//   }
// `;

const PopTable = styled.div`
  margin-left: 10px;
  overflow-x: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    margin-top:10px;
  }
`;
export default LoanPaymentTransactions