import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useState } from "react";
import { adminManageUserStatus } from "../Features/api/api";

const MemberShipStatusForm = ({ userId, setIsMembershipStatusFormOpen, member }) => {
    const isMemberPending = member.status === 'pending_approval';
    const user_id=userId
    const isMemberActive = member.is_active;
    const isMemberInactive = !member.is_active && member.status !== 'pending_approval';
    const isMemberDormant = member.status === 'dormant'; // New condition for dormant status
    const dispatch = useDispatch();
    const [approvalData, setApprovalData] = useState({
        action: "",
        reject_reason: "",
    });

    const handleOnchange = (e) => {
        const { name, value } = e.target;
        setApprovalData({
            ...approvalData, [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(adminManageUserStatus({ user_id, approvalData }));
        setIsMembershipStatusFormOpen(false);
    };

    const isRejectActionSelected = approvalData.action === 'rejected';

    return (
        <Container>
            <h2>Approve/Reject the Membership Status</h2>
            <p style={{fontWeight:'bold'}}>{member.first_name} {member.last_name}</p>
            <p>Remember to include a rejection reason in case you reject the membership.</p>
            <PaymentForm onSubmit={handleSubmit}>
                <FormOuterRow>
                    <FormRow>
                        <label htmlFor="action">Action:</label>
                        <select 
                            name="action" 
                            id="action" 
                            onChange={handleOnchange} 
                            required
                        >
                            <option value="">Select</option>
                            {isMemberPending && (
                                <>
                                    <option value="approve">Approve New Member</option>
                                    <option value="reject">Reject New Member</option>
                                </>
                            )}
                            {isMemberInactive && (
                                <option value="activate">Activate Member</option>
                            )}
                            {isMemberActive && (
                                <>
                                    <option value="dormant">Make Dormant</option>
                                    <option value="deactivate">Deactivate</option>
                                </>
                            )}
                            {isMemberDormant && (
                                <option value="lift_dormancy">Lift Dormancy</option> // New option for dormant members
                            )}
                        </select>
                    </FormRow>

                    <FormRow>
                        {isRejectActionSelected && (
                            <RejectReason>
                                <label htmlFor="reason">Rejection Reason:</label>
                                <textarea 
                                    name="reject_reason" 
                                    id="reject_reason" 
                                    placeholder="Provide a reason for rejection"
                                    onChange={handleOnchange} 
                                    value={approvalData.reject_reason} 
                                    required={isRejectActionSelected}
                                />
                            </RejectReason>
                        )}
                    </FormRow>
                </FormOuterRow>

                <AuthButtons>
                    <SignUpBtn type="submit">Submit</SignUpBtn>
                </AuthButtons>
            </PaymentForm>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 80%;
    h2 {
        display: flex;
        justify-content: flex-start;
        margin-left: 0px;
    }
    p {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
`;

const PaymentForm = styled.form`
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`;

const FormOuterRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 80%;
`;

const FormRow = styled.div`
    margin-bottom: 10px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    input, select, textarea {
        border: none;
        border-bottom: 1px solid #353935;
        outline: none;
        width: 100%; 
    }
    label {
        font-size: 12px;
    }
    @media (max-width: 768px) {
        label {
            font-size: 8px;
        }
    }
`;

const AuthButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
`;

const SignUpBtn = styled.button`
    margin-left: 80px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #E2725B;
    color: #f9f9f9;
    border: none;
    width: 50%;
    @media (max-width: 768px) {
        font-size: 10px;
        padding: 5px 15px;
    }
`;

const RejectReason = styled.div`
    width: 100%;
    outline: none;
`;

export default MemberShipStatusForm;
