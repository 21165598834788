import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getUsers, deactivateUser, adminManageUserStatus, fetchAllContributions } from '../Features/api/api';
import Spinner from '../SharedFns/SpinnerElement';
import AssignRoleForm from './AssignRolesForm';
import Modal from '../SharedFns/Modal';
import RemoveRoleForm from './RemoveUserRoles';
import UserFinancialDocument from './documents/UserFinancialSummary';
import ExitForm from './ExitForm';
import { baseURL } from '../SharedFns/Functions';
import UserClearanceForm from './ClearanceForm';
import ExitFormModal from '../SharedFns/ExitFormModal';
// Sample data for members

const members = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    regNumber: '12345',
    title: 'Manager',
    profilePic: 'https://via.placeholder.com/50'
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    regNumber: '67890',
    title: 'Developer',
    profilePic: 'https://via.placeholder.com/50'
  }
  // Add more members as needed
];



const LoanSummary = () => {
    const dispatch=useDispatch()
    useEffect( ()=>{
        dispatch(getUsers())
        dispatch(fetchAllContributions())

      }, [dispatch])
    const [isModalOpen, setIsModalOpen]=useState(false)
    const [isRemoveModalOpen, setIsRemoveModalOpen]=useState(false)
    const [isExitModalOpen, setIsExitModalOpen]=useState(false)
      const getFullPhotoUrl = (photo) => {
          const baseUrl =baseURL // Adjust base URL as necessary
        return baseUrl + photo;
      };
    const users=useSelector(state=> state.getUsers?.users?.users)
    const isLoading=useSelector(state => state.getUsers?.loading || state.getAllContributions?.loading||state.getbalanceSheet?.loading||state.exitMember?.loading||state.clearMember?.loading)
    const contributions=useSelector(state=>state.getAllContributions?.allContributions)
    const userCount=useSelector(state => state.activeUsersCount?.users?.active_users_count)
  const balanceSheetData = useSelector(state => state.getbalanceSheet?.balanceSheetData.balance_sheet??[])
  const users_shared_interest_data=useSelector(state => state.getbalanceSheet?.balanceSheetData?.users_shared_interest_data??[])
    const is_active=useSelector(state=>state.getUsers?.users?.users.is_active )
    const [selectedUserId, setSelectedUserId]=useState(null) 
    const [selectedMember, setSelectedMember] = useState(null);
    const[selectedClearMember, setSelectedClearMember]=useState(null)
    const[isClearanceModalOpen,  setIsClearanceModalOpen] =useState(false)
    // const activeUsers = users?.filter(user => user.is_active);
   
    const statusCodeToName={
      'pending_approval':'Pending Approval',
      'active':'Active',
      'dormant':'Dormant',
      'cleared':'Cleared',
  
     }
   
    const handleDeactivate=(user_id)=>{
      dispatch( deactivateUser({user_id}))

    }
    const handleActivate=(user_id)=>{
      dispatch( adminManageUserStatus({user_id}))

    }
   const handleAssignRole=(userId)=>{
    
    setIsModalOpen(true)
    setSelectedUserId(userId)
    console.log('The initial selected ID',userId)
    console.log('The set selected ID',selectedUserId)
      // dispatch( adminActivateUser({user_id}))

    }
    const handleClearMember = (member) => {
      setSelectedMember(member);
      setIsClearanceModalOpen(true);
    };
    const handleExitClick = (member) => {
      setSelectedMember(member);
      setIsExitModalOpen(true);
    };
   
    const handleSummary=(userId)=>{
     
      setIsModalOpen(true)
      setSelectedUserId(userId)
        // dispatch( adminActivateUser({user_id}))
  
      }
      const getUserContributions = (username) => {
        return contributions?.filter(contribution => contribution.user === username) || [];
      };
      
      const getTotalContributionsForMember = (member) => {
        const userContributions = getUserContributions(member.username);
        return userContributions.reduce((acc, contribution) => acc + contribution.total_contributions, 0);
      };
    
    const nonClearedLoans = (member) => {
      return member?.loans?.filter(loan => loan.status === 'active' || loan.status === 'default');
    };

    const getTotalNonclearedLoans=(member)=>{
      const unClearedLoans=nonClearedLoans(member);

      return unClearedLoans?.reduce((acc, loan) => acc + loan.current_balance, 0)
    };
    
    const userContributions = selectedUserId?contributions?.filter(contribution => contribution.user === selectedUserId.username) : [];
    const totalUserContributions = userContributions?.reduce((acc, contribution) => acc + contribution.total_contributions, 0);


  return (
    
    <MemberListContainer>
        <div>
    { isLoading && <Spinner/>}
    </div>
   <ExitFormModal isOpen={isClearanceModalOpen} onClose={()=>setIsClearanceModalOpen(false)}>
    <UserClearanceForm setIsClearanceModalOpen={setIsClearanceModalOpen} selectedMember ={selectedMember} totalUserContributions={totalUserContributions} balanceSheetData={balanceSheetData} userCount={userCount}/>

   </ExitFormModal>
    <ExitFormModal  style={{ zIndex: 10000 }}  isOpen={isExitModalOpen} onClose={() => setIsExitModalOpen(false)}  >
        {<ExitForm setIsExitModalOpen ={setIsExitModalOpen} selectedMember ={selectedMember} totalUserContributions={totalUserContributions} balanceSheetData={balanceSheetData} userCount={userCount}/> }
      </ExitFormModal>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {<UserFinancialDocument isLoading={isLoading}  users_shared_interest_data={users_shared_interest_data} totalUserContributions={totalUserContributions} setIsModalOpen={setIsModalOpen} user={selectedUserId}  balanceSheetData={balanceSheetData} userCount={userCount}  />}
            </Modal> 
            <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)}>
                {<RemoveRoleForm setIsRemoveModalOpen={setIsRemoveModalOpen} userId={selectedUserId} />}
            </Modal>           
      {users?.map(member => (
        <MemberCard  key={member.id} onClick={() => handleSummary(member)}>
         
           {/* <ProfilePic  src="http://localhost:8000/media/user_images/mylinkedin_photo-min.jpg/> */}
          <ProfilePic src={getFullPhotoUrl( member.photo)} alt={`${member.first_name}`} />
          {/* <ProfilePic src={"http://localhost:8000/media/user_images/mylinkedin_photo-min.jpg"} alt={`${member.first_name}`} /> */}
          <MemberInfo>
            <MemberName>{`${member.first_name} ${member.last_name}`}</MemberName>
            <MemberDetails>ID: {member.id}</MemberDetails>
            <MemberDetails>Reg Number: {member.reg_number}</MemberDetails>
            <MemberDetails>Title: {member.user_groups[0]?member.user_groups[0]:'Member'}</MemberDetails>
            <StatusDetail status={member.status}>Membership Status: {statusCodeToName[member.status]}</StatusDetail>
            {/* <SignUpBtn onClick={()=>handleSummary(member.username)}> On Exit </SignUpBtn> */}
            <LoanDetails>
              <p> Loan Details:</p>
              
            
              {member.loans && member.loans.length > 0 ? (
                member.loans.map((loan, index) => (
                  <LoanItem key={index}>
                    <LoanDetail>Principal: {loan.amount}</LoanDetail>
                    <LoanDetail>Start Date: {loan.start_date}</LoanDetail>
                    <LoanDetail>Expiry Date: {loan.expiry_date}</LoanDetail>
                    <StatusDetail status={loan.status}>Status: {loan.status}</StatusDetail>
                    <LoanDetail>Remaining Balance: {loan.current_balance}</LoanDetail>
                  </LoanItem>
                ))
              ) : (
                <LoanDetail>No loans available</LoanDetail>
              )}
            </LoanDetails>
            <ContributionDetails>
              <p>Contribution Update:</p>
                            {getUserContributions(member.username)?.length > 0 ? (
                                getUserContributions(member.username).map((contribution, index) => (
                                    <ContributionItem key={index}>
                                        <ContributionDetail>Amount: {contribution.latest_contribution.amount}</ContributionDetail>
                                        <ContributionDetail>Month: {contribution.latest_contribution.month}</ContributionDetail>
                                        <ContributionDetail>Total Contributions: {contribution.total_contributions}</ContributionDetail>
                                    </ContributionItem>
                                ))
                            ) : (
                                <ContributionDetail>No contributions available</ContributionDetail>
                            )}
                        </ContributionDetails>
                        {nonClearedLoans(member).length > 0 ? (
        <SignUpBtn 
          onClick={() => handleExitClick(member)} 
          disabled={getTotalContributionsForMember(member)< getTotalNonclearedLoans(member)}
          style={{ 
            backgroundColor: getTotalContributionsForMember(member)< getTotalNonclearedLoans(member)? "grey" : "#E2725B",
            cursor: getTotalContributionsForMember(member)< getTotalNonclearedLoans(member) ? "not-allowed" : "pointer"
          }}
        >
          Exit Member
        </SignUpBtn>
      ) : (
        <SignUpBtn 
          onClick={() => handleClearMember(member)}
          disabled={getTotalContributionsForMember(member)<=0}
          style={{ 
            backgroundColor: getTotalContributionsForMember(member)<=0 ? "grey" : "blue",
            cursor: getTotalContributionsForMember(member)<=0 ? "not-allowed" : "pointer"
          }}
        >
          {member.has_cleared?"Member Cleared" :"Clear this Member"}
        </SignUpBtn>
      )}
    
  

          </MemberInfo>
          {/* {member.is_active ?
  <SignUpBtn onClick={() => handleDeactivate(member.id)}> Deactivate User</SignUpBtn> : <SignUpBtn onClick={() => handleActivate(member.id)}> Activate User</SignUpBtn>
}
          {member.user_groups[0]?<SignUpBtn onClick={()=>handleRemoverole(member.id)}>Remove Role</SignUpBtn>:<SignUpBtn onClick={()=> handleAssignRole(member.id)}>Assign Role</SignUpBtn>}
           */}
        </MemberCard>
      ))}
    </MemberListContainer>
  );
};
// Styled-components
const MemberListContainer = styled.div`
width:100%;
height:80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  overflow:scroll;
`;

const MemberCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content:flex-start;
  background-color: #f8f9fa;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  width: 80%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProfilePic = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const MemberDetails = styled.div`
  font-size: 14px;
  color: #666;
`;
const LoanDetails = styled.div`
  margin-top: 10px;
`;

const LoanItem = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  background-color: #eaeaea;
  border-radius: 5px;
`;

const LoanDetail = styled.div`
  font-size: 12px;
  color: #333;
`;
const StatusDetail = styled.div`
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'cleared':
        return 'blue';
      case 'dormant':
        return 'red';
      case 'pending_approval':
        return  'orange'
      case 'default':
        return 'red'  
      default: 
        return '#333';
    }
  }};
`;
const ContributionDetails = styled.div`
  margin-top: 10px;
`;

const ContributionItem = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  background-color: #eaeaea;
  border-radius: 5px;
`;

const ContributionDetail = styled.div`
  font-size: 12px;
  color: #333;
  `
const SignUpBtn = styled.button`
margin-left:20px;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
;
export default LoanSummary;
