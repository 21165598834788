import { createSlice } from "@reduxjs/toolkit";
import { clearMember } from "../api/api";

const initialState = {
  loading: false,
  error: {},
  Response: [],
};

const clearMemberSlice = createSlice({
  name: 'clearMember',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      clearMember.pending, // <- Correct action type
      (state) => {
        state.loading = true;
        state.error = {};
        state.Response= [];
      }
    );
    builder.addCase(
      clearMember.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.Response = [];
      }
    );
    builder.addCase(
      clearMember.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = {};
        state.Response = action.payload;
      }
    );
  },
});

export default clearMemberSlice.reducer;
