import styled from "styled-components";
import SignIn from "../components/SignIn";

import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import RegForm from "../components/RegForm";

const SignUpForm=(props)=>{
  const navigate=useNavigate()

  const [showSignUpForm, setShowSignUpForm]=useState(false)

  const handleSignUpFormChange= (state)=>{
    setShowSignUpForm(state)
  }
  const handeRegisterClick=()=>{
    navigate('/sign-up')
  }
return (
    <Container>
        <CTA>
        <Eye20Logo>
           <img src={`${process.env.PUBLIC_URL}/media/logo.svg`} alt="" />
         </Eye20Logo>
          <Eye20Info>
            <h1>Welcome to Eye20 Group online</h1>
          
           <h2>More than just friends</h2>
          </Eye20Info>
        </CTA>
        <SignUp>
         <RegForm/>
          {/* { showSignUpForm ? <SignUpForm />:(<SignIn onChange={handleSignUpFormChange} onRegisterClick={handeRegisterClick}/>)} */}
        </SignUp>
        <Outlet /> {/* Renders nested routes */}

    </Container>
    
)



}
const Container=styled.section`
height:100vh;
width:100vw;

display: flex;

flex-direction: row;
align-items:center; 
justify-content:space-around;
top:0px;
left:0px;
right:0px;

@media (max-width:768px) {
  flex-direction:column;
   overflow-y:scroll;
   height:100vh;
   padding-top:120px;
   margin-bottom:30px;
}


`
const CTA= styled.div`
display:flex;
flex-direction:column; 
align-items:flex-start; 

background-color:#034694;

height:100%;
width:70vw;
padding-left:0px;
margin-left:0px;
margin-top:0px;
@media (max-width:768px) {
  width:70vw;
  height:100vh;
  font-size:12px;
  margin-bottom: 12px;
  padding-bottom:12px; 
}

`
const SignUp =styled.div`
background-color:#f9f9f9;
height:100%;
padding-top:80px; 


padding-left:40px;
padding-right:40px;
margin-left:15px;
margin-right:0px;

width:30vw;
margin-bottom:0px;
display:flex;
flex-direction:column;

p{
  color:#353935;
  font-size:15px;
 
}
@media (max-width:768px) {
    font-size:15px;

    width:70vw;
    margin-right:0px;
    padding-right:0px;
    margin-left:0px;
    padding-left:0px;
    margin-top:10px;
    padding-top:30px;
    

  }
`
const Eye20Logo =styled.div`
width:300px;
max-height:150px;
padding-top:4px;

img{
width: 95%; 
height:95%;
min-height:1px;
max-width:600px;
border-radius:50%;
margin-bottom:12px;
margin-left:0px;
margin-top:20px;
background-color:#f9f9f9;
padding: 10px 10px;

padding-top:15px; 
display: block;
top:10px;
bottom:0px;
left:0px;
right:0px;

@media (max-width: 768px) {
  
    width: 50%;
    height: 50%;
    
   
  }

}


display:flex;
flex-direction:row;
justify-content: flex-start;
margin-left:20px;

margin-top:50px;
@media (max-width: 480px) {
  .img{
    width: 30%;
    height: 30%;
   
  }
  width:200px;
  max-height:100px;
  margin-left:20px;
  margin-right:20px;
  display:flex;
  justify-content:flex-start;
  padding-top:20px; 
}

`
const Eye20Info =styled.div`
margin-top:30px;
h1{
font-size:50px;
font-weight:bold;
width:60%;
@media (max-width:768px) {
    font-size:20px;
}
}
h2{
    font-size:20px;
    @media (max-width:768px) {
    font-size:14px;
}  
}

margin-left:20px;
color:#f9f9f9;
`
export default SignUpForm