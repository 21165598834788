import { useState } from "react"
import { useNavigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import {useSelector, useDispatch} from 'react-redux'
import { signupUser } from "../Features/api/api"
import Spinner from "../SharedFns/SpinnerElement";


const RegForm = (props) => {
const dispatch= useDispatch()
const error =useSelector(state=>state.auth.error)
const isLoading=useSelector(state=> state.auth?.loading)
const validationErrors = useSelector((state) => state.auth.validationErrors);

const [formData, setFormData]=useState({
    first_name:'',
    last_name:'',
    phone:'',
    email:'',
    RegNo:'',
    username:'',
    password:'',
    confirm_password:''

})
const handleOnchange= e=>{
    const{name, value}=e.target
    setFormData({
        ...formData, [name]:value
    })
}
 const handleSubmit= async (e) =>{
    e.preventDefault()
    

    dispatch( signupUser(formData))
    
 }  
 
    return(
        <Container>
            <h1>Create your account</h1>
            <p> Please provide the most accurate and present details reagrding you. </p>
            <div>
        {isLoading && <Spinner/>
      }
      </div>
            {validationErrors && Object.keys(validationErrors).length > 0 && (
        <div>
          <h2>Validation Errors:</h2>
          {Object.entries(validationErrors).map(([field, messages]) => (
            <div key={field}>
              <strong>{field}:</strong>
              <ul>
                {messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          ))} </div>)}
            <SignUpForm>
                <form onSubmit={handleSubmit}>
                    <FormOuterRow>
                    <Form_Row>
                        <label htmlFor="first_name" >
                            Firstname
                        </label>
                        <input name="first_name"
                            id="first_name"
                            type="text"   placeholder="Firstname" onChange={handleOnchange}required />
                        

                    </Form_Row>
                    <Form_Row  style={{marginLeft:'10px', marginRight:'0px'}}>
                    <label htmlFor="last_name" >
                            Lastname
                        </label>
                        <input name="last_name"
                            id="last_name"
                            type="text"  onChange={handleOnchange} placeholder="Lastname" required />
                        
                    </Form_Row>
                    </FormOuterRow>
                    <FormOuterRow>
                    <Form_Row>
                        <label htmlFor="username" >
                            Username
                        </label>
                        <input name="username"
                            id="username"
                            type="text"  onChange={handleOnchange} placeholder="Username" required />
                        

                    </Form_Row>
                    <Form_Row  style={{marginLeft:'10px', marginRight:'0px'}}>
                    <label htmlFor="email" >
                            Email
                        </label>
                        <input name="email"
                            id="email"
                            type="email"   onChange={handleOnchange} placeholder="Email" required />
                        
                    </Form_Row>
                    </FormOuterRow>
                    <FormOuterRow>
                    <Form_Row>
                        <label htmlFor=" Reg.No" >
                            Registration Number
                        </label>
                        <input name="RegNo"
                            id="RegNo"
                            type="text"  onChange={handleOnchange} placeholder="Eye20 Reg.No " required />
                        

                    </Form_Row>
                    <Form_Row  style={{marginLeft:'10px', marginRight:'0px'}}>
                    <label htmlFor="phone" >
                            Phone Number
                        </label>
                        <input name="phone"
                            id="phone"
                            type="number"  onChange={handleOnchange} placeholder="Phone Number" required />
                        
                    </Form_Row>
                    </FormOuterRow>
                    <FormOuterRow>
                    <Form_Row>
                        <label htmlFor=" password" >
                            Create Password
                        </label>
                        <input name="password"
                            id="password"
                            type="password"  onChange={handleOnchange} placeholder=" Password " required />
                        

                    </Form_Row>
                    <Form_Row  style={{marginLeft:'10px', marginRight:'0px'}}>
                    <label htmlFor="confirm_password" >
                            Confirm Password
                        </label>
                        <input name="confirm_password"
                            id="confirm_password"
                            type="password"  onChange={handleOnchange} placeholder="Confirm Password" required />
                        
                    </Form_Row>
                    </FormOuterRow>
                    
                    <AuthButtons>
                        
                        <SignUpBtn type="submit" onClick={handleSubmit}> Register</SignUpBtn>

                    </AuthButtons>
                    
                </form>

            </SignUpForm>
        </Container>
    )
          
        


    
}
const Container = styled.div`
display: flex;
flex-direction:column;
@media (max-width: 768px) {
    .h1{
        font-size:14px;
    }
    
}
`
const SignUpForm = styled.div`
margin-top: 30px;

display:flex;
flex-direction:column;
justify-content:flex-start;
`
const FormOuterRow=styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
margin-bottom:10px;
`
const Form_Row = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:flex-start;

input {
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
label{
    font-size:12px;
}
@media (max-width: 768px) {
    .label{
        font-size:8px;
    }
    
}
`
const Form_col = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
padding:20px;
margin-top:10px;
`
const ForgotPassword = styled.button`
    color: #E2725B;
    font-size:15px;
    padding-left:0px; 
    margin-top:20px;
    cursor: pointer;
    width:50%;
    border:none;
    background-color:#f9f9f9;
    display:flex;
    justify-content:flex-start;
    @media (max-height:768px) {
        font-size:10px;
    }
`
const AuthButtons = styled.div`
display: flex;

flex-direction:row;
justify-content:flex-start;
margin-top:20px;
width:80%;
padding-left: 0px;
margin-left:0px;
`
const SignInBtn = styled.button`
display:flex;
justify-content:flex-start;
cursor: ${props => props.disabled ? 'arrow' : 'pointer'};
padding: 10px 30px;
border-radius:10px;
background-color: ${props => props.disabled ? '#ccc' : '#3498db'};
border:none;
color:#f9f9f9;
border-color:#034694;
@media (max-width: 768px) {
    font-size:12px;
    padding: 5px 15px;
}
`
const SignUpBtn = styled.button`
margin-left:0px;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default RegForm 