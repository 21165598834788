import styled from "styled-components"
import React, { useEffect } from "react"
import { useState } from "react"
import { createLedgers, getDeclarations } from "../../Features/api/api"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from 'react-datepicker'

import Spinner from '../../SharedFns/SpinnerElement';

const LedgerEntryForm = () => {

    const dispatch = useDispatch()
    const [type, setType] = useState('')
    const [ledgerdata, setData] = useState({})
    useEffect(() => {
        dispatch(getDeclarations());
    }, []);  // Ensure that there are no other dependencies causing unintended re-renders

    const declarations = useSelector(state => state.getDeclarations?.declarations?.declarations)
    const fixedDeposits = useSelector(state => state.getDeclarations?.declarations?.fixed_deposits)
    const isLoading=useSelector(state=>state.getDeclarations?.loading )
    const handleOnchange = (e) => {
        const { name, value } = e.target

        setData({
            ...ledgerdata, [name]: value, type
        })



    }
    const handleDateChange = (date, name) => {
        setData({
            ...ledgerdata,
            [name]: date
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log(ledgerdata);
            await dispatch(createLedgers({ ledgerdata }));
            // setData({}); // Reset the local state only after a successful createLedgers
            await dispatch(getDeclarations())
            // Optionally, you can dispatch getDeclarations here if needed.
        } catch (error) {
            console.error("Error creating ledgers:", error);
            // Handle error if needed
        }
    };
    const renderFormFields = () => {
        switch (type) {
            // case 'fine':
            //     return (
            //         <div>
            //             <FormRow>

            //                 <label>
            //                     Amount:
            //                 </label>
            //                 <input
            //                     type="number"
            //                     name='amount_paid'
            //                     value={ledgerdata.amount_paid || ''}
            //                     onChange={handleOnchange}
            //                     required
            //                 />
            //             </FormRow>
            //             <FormRow>

            //                 <label>
            //                     Description:
            //                 </label>
            //                 <input
            //                     type="text"
            //                     name='description'
            //                     value={ledgerdata.description || ''}
            //                     placeholder="Provide details about the fine"
            //                     onChange={handleOnchange}
            //                     required
            //                 />
            //             </FormRow>






            //         </div>





            //     )

            //     break;
                case 'equity_fixed_deposit':
                    return (
                        <div>
                            <FormRow>
    
                                <label>
                                    Amount:
                                </label>
                                <input
                                    type="number"
                                    name='amount'
                                    value={ledgerdata.amount || ''}
                                    onChange={handleOnchange}
                                    required
                                />
                            </FormRow>
                            <FormRow>
    
                                <label>
                                    Description:
                                </label>
                                <input
                                    type="text"
                                    name='description'
                                    value={ledgerdata.description || ''}
                                    placeholder="Provide details about the fine"
                                    onChange={handleOnchange}
                                    required
                                />
                            </FormRow>
                            <FormRow>
    
    <label>
        Start Date:
    </label>
    <DatePicker
                    id='StartDate'
                    name='start_date'
                    dateFormat='yyyy-MM-dd'
                    selected={ledgerdata.start_date}
                    onChange={(date) => handleDateChange(date, 'start_date')}
                    showYearDropdown
                    scrollableYearDropdown
                    required
                />
</FormRow>
<FormRow>
    
    <label>
        Maturity Date:
    </label>
    <DatePicker
                    id='MaturityDate'
                    name='maturity_date'
                    dateFormat='yyyy-MM-dd'
                    selected={ledgerdata.maturity_date}
                    onChange={(date) => handleDateChange(date, 'maturity_date')}
                    showYearDropdown
                    scrollableYearDropdown
                    required
                />
</FormRow>
    
    
    
    
    
    
                        </div>
    
    
    
    
    
                    )
    
                    break;    
            case 'other_income':
                return (
                    <div>
                        <FormRow>

                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount_paid'
                                value={ledgerdata.amount_paid || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>
                        <FormRow>

                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder="Provide details about the fine"
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>






                    </div>





                )

            case 'expense':
                return (
                    <div>

                        <FormRow>
                            <label>
                                Category:
                            </label>
                            <select
                                name="category"
                                value={ledgerdata.category || ''}
                                onChange={handleOnchange}
                                required
                            >
                                <option value="">Select Category</option>
                                <option value="social_welfare">Social Welfare</option>
                                <option value="end_year_party">End of Year Party</option>
                                <option value='server_expense'>  Server Expense  </option>
                                <option value='certificate_renewal'>  Certificate Renewal  </option>
                                <option value='transaction_cost'>  Transaction Cost  </option>

                                <option value='other_expense'> Other Expenses</option>
                                {/* Add other options for expense categories */}

                            </select>
                        </FormRow>
                        <FormRow>

                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder="Provide more details on the expense "
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount'
                                value={ledgerdata.amount || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>





                    </div>)
                break;
            case 'investment':
                return (
                    <div>
                        <FormRow>

                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount'
                                value={ledgerdata.amount || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder=" Provide more details about the investment"
                                onChange={handleOnchange}
                                required
                            />

                        </FormRow>





                    </div>)
                break;
            case 'goodwill':
                return (
                    <div>
                        <FormRow>

                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount'
                                value={ledgerdata.amount || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder="Provide details about the goodwill"
                                onChange={handleOnchange}
                                required
                            />

                        </FormRow>





                    </div>)
            case 'dividend_declaration':
                return (
                    <div>
                        <FormRow>

                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount'
                                value={ledgerdata.amount || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder="Provide details about the declaration"
                                onChange={handleOnchange}
                                required
                            />

                        </FormRow>





                    </div>)
                break;
            case 'dividend_payment':
                return (
                    <div>
                        <FormRow>

                            <label>
                                Amount:
                            </label>
                            <input
                                type="number"
                                name='amount'
                                value={ledgerdata.amount || ''}
                                onChange={handleOnchange}
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <label>
                                Description:
                            </label>
                            <input
                                type="text"
                                name='description'
                                value={ledgerdata.description || ''}
                                placeholder="Provide details about the payment"
                                onChange={handleOnchange}
                                required
                            />

                        </FormRow>
                        <FormRow>
                            <label>
                                Declaration:
                            </label>
                            <select
                                name='declaration'
                                value={ledgerdata.declaration ? ledgerdata.declaration.id : ''}
                                onChange={handleOnchange}
                                required
                            >
                                <option value="">Select a declaration</option>
                                {declarations && declarations.map(declaration => (
                                    <option key={declaration.id} value={declaration.id}>
                                        {`${declaration.description} - Unpaid Amount: ${declaration.rem_amount}`}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="hidden"
                                name="declaration_id"
                                value={ledgerdata.declaration ? ledgerdata.declaration.id : ''}
                            />
                        </FormRow>





                    </div>)


           
                case 'redeem_fixed_deposit':
                    return (
                        <div>
                            <FormRow>
    
                                <label>
                                    Amount:
                                </label>
                                <input
                                    type="number"
                                    name='amount'
                                    value={ledgerdata.amount || ''}
                                    onChange={handleOnchange}
                                    required
                                />
                            </FormRow>
                            <FormRow>
                                <label>
                                    Description:
                                </label>
                                <input
                                    type="text"
                                    name='description'
                                    value={ledgerdata.description || ''}
                                    placeholder="Provide details about the payment"
                                    onChange={handleOnchange}
                                    required
                                />
    
                            </FormRow>
                            <FormRow>
                                <label>
                                    Fixed Deposit:
                                </label>
                                <select
                                    name='fixed_deposit'
                                    value={ledgerdata.fixed_deposit ? ledgerdata.fixed_deposit : ''}
                                    onChange={handleOnchange}
                                    required
                                >
                                    <option value="">Select a Fixed Deposit</option>
                                    {fixedDeposits && fixedDeposits.map(deposit => (
                                        <option key={deposit.id} value={deposit.id}>
                                            {`${deposit.description} -Amount: ${deposit.amount}`}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="hidden"
                                    name="deposit_id"
                                    value={ledgerdata.fixed_deposit ? ledgerdata.fixed_deposit.id : ''}
                                />
                            </FormRow>
                            <FormRow>
    
                                <label>
                                    Interest Earned:
                                </label>
                                <input
                                    type="number"
                                    name='interest_earned'
                                    value={ledgerdata.interest_earned || ''}
                                    onChange={handleOnchange}
                                    required
                                />
                            </FormRow>
    
    
    
    
    
                        </div>)
    
    
                default:
                    break;    
        }
    }
    return (
        <Container>
            <div>
                {isLoading && <Spinner/>}
            </div>
            <h2>Create a Ledger Entry</h2>

            <PaymentForm onSubmit={handleSubmit}>

                <FormOuterRow>

                    <FormRow>
                        <label htmlFor="type">
                            Type:
                        </label >
                        <select value={type} onChange={(e) => setType(e.target.value)} required>

                            <option value=''> Select Ledger Type</option>
                            {/* <option value="fine">Fine</option> */}
                            <option value="expense">Expense</option>
                            <option value="investment">Investment</option>
                            <option value="goodwill">Goodwill</option>
                            <option value="dividend_declaration">Declare Dividends</option>
                            <option value="dividend_payment">Pay Dividends</option>
                            <option value="other_income">Other Income</option>
                            <option value="equity_fixed_deposit">Fixed Deposit</option>
                            <option value="redeem_fixed_deposit">Redeem Fixed Deposit</option>
                        </select>

                    </FormRow>

                    {renderFormFields()}



                </FormOuterRow>

                <AuthButtons>
                    <SignUpBtn type="submit" > submit Ledger Entry</SignUpBtn>
                </AuthButtons>

            </PaymentForm>
        </Container>
    )



}
const Container = styled.div`
display:flex;
flex-direction: column;
justify-content:flex-start;
overflow:scroll;
width:80%;
h2{
display:flex;
justify-content:flex-start;
margin-left:0px;
}
p{
display:flex;
width:100%;
justify-content:flex-start;


}
`
const PaymentForm = styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width: 100%;

`
const FormOuterRow = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:10px;
width:80%;
`
const FormRow = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;


input {
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 

}
select {
margin-top: 10px;  
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 
}
textarea {
margin-top: 10px;  
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 

}
label{
font-size:12px;
}
@media (max-width: 768px) {
.label{
font-size:8px;
}

}
`

const AuthButtons = styled.div`
display: flex;
align-items:center;
flex-direction:row;

margin-top:20px;
width:100%;
padding-left: 0px;
margin-left:0px;
`
const SignUpBtn = styled.button`
margin-left:80px;
display:flex;
justify-content:center;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color: #E2725B;
/* background-color:red; */
color:#f9f9f9;
border:none;
width:50%;
border-color:#E2725B;
@media (max-width: 768px) {
font-size:10px;
padding: 5px 15px;
}
`
const RejectReason = styled.div`
width:100%;
outline:none;
`


export default LedgerEntryForm