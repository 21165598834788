import styled from 'styled-components'
import { useDispatch } from "react-redux"
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ResetPassword } from '../Features/api/api'


const ResetPasswordForm=()=>{
    const {password_reset_token}=useParams()
    const navigate=useNavigate()
    const [passwordResetData, setPasswordResetData]=useState(
        {new_password:'',
        confirm_new_password:''}
    )
    const dispatch=useDispatch() 
    const handleOnchange= (e)=>{
        const{name, value}= e.target
        setPasswordResetData({
            ...passwordResetData, [name]: value}
        )
    }
    const handleSubmit= async (e)=>{
        e.preventDefault()
        
         dispatch(ResetPassword({ RestePasswordData: { ...passwordResetData, "password_reset_token": password_reset_token }, navigate }))
         
    }
    return(
    <Container>
    {/* <h1>Apply Loan below</h1> */}
    <p> Please provide the details below to reset your password. </p>
 <StyledForm onSubmit={handleSubmit}>

 <FormOuterRow>
 <Form_Row>
                <label htmlFor="new_password" >
                    New Password
                </label>
                <input name="new_password"
                    id="new_password"
                    type="password"   placeholder="Input new password" onChange={handleOnchange}required />
                

            </Form_Row>
            <Form_Row>
                <label htmlFor="confirm_new_password" >
                    Confirm New Password:
                </label>
                <input name="confirm_new_password"
                    id="confirm_new_password"
                    type="password"   placeholder="Confirm new password" onChange={handleOnchange}required />
                

            </Form_Row>
            {/* <Form_Row>
                <label htmlFor="loan_type" >
                    Loan Type:
                </label>
                <select name="loan_type"  id="loan_type"
                    type="text"   placeholder="Loan type: e.g, emergency loan" onChange={handleOnchange}required>
                        <option value="">Select</option>
                       <option value="normal_loan">Normal Loan</option>
                         <option value="emergency_loan">Emergency Loan</option>

                    </select>
                

            </Form_Row> */}
  </FormOuterRow>      
<AuthButtons>
<SignUpBtn type="submit" > submit</SignUpBtn>
</AuthButtons>


 
 
 </StyledForm>
</Container>
)
}
const Container=styled.div`
display:flex;
flex-direction: column;
align-items:center;
overflow:hidden;
width:80%;
@media (max-width: 768px) {
        
        justify-content:flex-start;
        padding-left:10px;

    }
.h2{
    display:flex;
    justify-content:flex-start;
    margin-left:0px;
}
p{
    display:flex;
   align-items:center;
    @media (max-width: 768px) {
        
        align-items:center;
        padding-left:10px;
        margin-left:23px;

    }
       
    
    


}
`
const PaymentForm=styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width: 80%;

`
const FormOuterRow=styled.div`
display:flex;
flex-direction:column;
align-items:center;
margin-bottom:10px;
width:80%;
@media (max-width: 768px) {
        
        width:100%;
       justify-content:flex-start;

    }
`
const Form_Row = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;

input {
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:110%; 
  
}
select {
  margin-top: 10px;  
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
label{
    font-size:12px;
}
@media (max-width: 768px) {
    .label{
        font-size:8px;
    }
    
}
`
const StyledForm=styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
align-items:center;
width:80%;
padding-left:5px;
@media (max-width: 768px) {
        
       
        padding-left:0px;

    }
`
const AuthButtons = styled.div`
display: flex;
align-items:center;
flex-direction:row;

margin-top:20px;
width:80%;
padding-left: 0px;
margin-left:0px;
`
const SignUpBtn = styled.button`
margin-left:80px;
display:flex;
justify-content:center;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color: #E2725B;
/* background-color:red; */
color:#f9f9f9;
border:none;
width:50%;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default ResetPasswordForm