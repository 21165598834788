import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { signupUser } from '../api/api'


const initialState={
    loading: false,
    user:null,
    error:{},
    validationErrors:{}
}


const authSlice=createSlice(
    { name:'auth', 
    initialState,

    reducers:{
    
    }, 
   extraReducers: (builder)=>{
    builder.addCase( signupUser.pending, (state)=>{
        state.loading=true
        state.error={}
    })
   
    .addCase(signupUser.rejected,(state, action)=>{
        state.loading= false
        state.error = action.error.message 
        state.validationErrors=action.payload?.validationError.errors
        state.user= null
        
    })
    .addCase( signupUser.fulfilled, (state, action)=>{
        state.loading=false
        state.error={}
        state.user=action.payload
    })
   }

    }
)

export default authSlice.reducer
