import { createSlice } from "@reduxjs/toolkit"

import { getUsers } from "../api/api"


const initialState={
   loading:false,
   error:{}, 
   users: null

}

const getUsersSlice= createSlice({
    name:'activate',
     initialState,

     reducers:{

     },

     extraReducers: (builder)=>{
        builder.addCase( getUsers.pending, (state)=>{
            state.loading = true
            state.error={}
            state.users =null

        })

        builder.addCase( getUsers.rejected, (state, action)=>{
            state.loading=false
            state.error= action.payload?.errors
            state.users=null
        }

        )
        builder.addCase( getUsers.fulfilled, (state, action)=>{
            state.loading=false
            state.error={}
            state.users= action.payload
        }
            )


     }

})

export default getUsersSlice.reducer