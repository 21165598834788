import { useState } from "react"
import styled from "styled-components"
import { approveMonthlyContribution} from "../Features/api/api"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../SharedFns/SpinnerElement"

const ContributionApprovalForm = ({ transactionId, setIsModalOpen }) => {
    const transaction_id = transactionId
    
    const dispatch = useDispatch()
    const [approvalData, setApprovalData] = useState({

        action: "",

        reject_reason: "",

    }

    )
    const handleOnchange = (e) => {
        const { name, value } = e.target

        setApprovalData({
            ...approvalData, [name]: value
        })

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(approveMonthlyContribution({ approvalData, transaction_id }))
        setIsModalOpen(false)
    }
    const isRejectActionSelected = approvalData.action === 'rejected'



    return (



        <Container>
            
            <h2>Aprrove/Reject the Loan Payment</h2>
            <p> Remember to include a rejection reason in case you reject the payment</p>
            <PaymentForm onSubmit={handleSubmit}>

                <FormOuterRow>

                    <FormRow>
                        <label htmlFor="action">
                            Action:
                        </label >
                        <select name="action" id="action"
                            type="text" placeholder="Reject or approve payment" onChange={handleOnchange} required>
                            <option value="">Select</option>
                            <option value="approved">Approve Contribution Payment </option>
                            <option value="rejected">Reject Contribution Payment</option>

                        </select>





                    </FormRow>

                    <FormRow>
                        {isRejectActionSelected && <RejectReason>

                            <label htmlFor="reason">
                                Rejection Reason:
                            </label>
                            <textarea name='reject_reason' id="reject_reason" type='text' placeholder="Provide a reason for rejection"
                                onChange={handleOnchange} value={approvalData.reject_reason} required={isRejectActionSelected}
                            />
                        </RejectReason>

                        }
                    </FormRow>

                </FormOuterRow>

                <AuthButtons>
                    <SignUpBtn type="submit" > submit </SignUpBtn>
                </AuthButtons>

            </PaymentForm>
        </Container>

    )

}

const Container = styled.div`
display:flex;
flex-direction: column;
justify-content:flex-start;
overflow:hidden;
width:80%;
h2{
display:flex;
justify-content:flex-start;
margin-left:0px;
}
p{
display:flex;
width:100%;
justify-content:flex-start;


}
`
const PaymentForm = styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width: 100%;

`
const FormOuterRow = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:10px;
width:80%;
`
const FormRow = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;


input {
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 

}
select {
margin-top: 10px;  
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 
}
textarea {
margin-top: 10px;  
border: none; /* Remove default border */
border-bottom: 1px solid #353935; /* Add a single line at the bottom */
outline: none; /* Remove outline when focused */
width:100%; 

}
label{
font-size:12px;
}
@media (max-width: 768px) {
.label{
font-size:8px;
}

}
`

const AuthButtons = styled.div`
display: flex;
align-items:center;
flex-direction:row;

margin-top:20px;
width:100%;
padding-left: 0px;
margin-left:0px;
`
const SignUpBtn = styled.button`
margin-left:80px;
display:flex;
justify-content:center;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color: #E2725B;
/* background-color:red; */
color:#f9f9f9;
border:none;
width:50%;
border-color:#E2725B;
@media (max-width: 768px) {
font-size:10px;
padding: 5px 15px;
}
`
const RejectReason = styled.div`
width:100%;
outline:none;
`


export default ContributionApprovalForm